import * as yup from 'yup';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { ADMIN_FEATURE_PATHS } from '@commons/utils/featurePaths';
import { doesIngredientsHaveApplicableUnits } from '@commons/utils/units';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { sortCategoryAndSubCategoryDropdown } from '@commons/utils/format';

// PRODUCT INFORMATIONS SECTION

const KITCHEN_PRODUCT_INPUTS = {
  NAME: 'name',
  CATEGORY: 'category',
  SUB_CATEGORY: 'subCategory',
  SKU: 'sku',
  SHELF_LIFE: 'shelfLife',
};

export const ALL_KITCHEN_PRODUCTS_FIELDS_FOR_DETAILS_VIEW = [
  'name',
  'category',
  'subCategory',
  'sku',
  'shelfLife',
];

export const KITCHEN_PRODUCT_GENERAL_INFOS_INPUTS = (props) => {
  const { productsCategories, productsSubCategories, handleCategorySubcategoryCreation } = props;

  const inputs = [
    {
      type: INPUT_TYPES.TEXT,
      name: KITCHEN_PRODUCT_INPUTS.NAME,
      label: `* ${i18next.t('GENERAL.NAME')}`,
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: false,
      rule: yup.string().required(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
          field: i18next.t('GENERAL.NAME'),
        }),
      ),
    },
  ];

  return inputs.concat([
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: KITCHEN_PRODUCT_INPUTS.CATEGORY,
      label: `${i18next.t('GENERAL.CATEGORY')} :`,
      width: INPUT_WIDTH.LARGE,
      sortBy: (items) => sortCategoryAndSubCategoryDropdown(items),
      items: productsCategories,
      isRequired: true,
      isDisabled: false,
      button: {
        id: 1,
        text: i18next.t('GENERAL.LIST_CREATE_CATEGORY'),
        handleClick: () => {
          handleCategorySubcategoryCreation('category');
        },
        iconSrc: '/images/inpulse/add-black-small.svg',
      },
      rule: yup.object().required(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
          field: i18next.t('GENERAL.CATEGORY'),
        }),
      ),
    },
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: KITCHEN_PRODUCT_INPUTS.SUB_CATEGORY,
      label: `${i18next.t('GENERAL.SUB_CATEGORY')} :`,
      width: INPUT_WIDTH.LARGE,
      sortBy: (items) => sortCategoryAndSubCategoryDropdown(items),
      items: productsSubCategories,
      isRequired: true,
      isDisabled: false,
      button: {
        id: 1,
        text: i18next.t('GENERAL.LIST_CREATE_SUBCATEGORY'),
        handleClick: () => {
          handleCategorySubcategoryCreation('subCategory');
        },
        iconSrc: '/images/inpulse/add-black-small.svg',
      },
      rule: yup.object().required(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
          field: i18next.t('GENERAL.SUB_CATEGORY'),
        }),
      ),
    },
    {
      type: INPUT_TYPES.TEXT,
      name: KITCHEN_PRODUCT_INPUTS.SKU,
      width: INPUT_WIDTH.LARGE,
      label: i18next.t('GENERAL.SKU'),
      rule: yup.string().nullable(),
    },
    {
      type: INPUT_TYPES.TEXT,
      name: KITCHEN_PRODUCT_INPUTS.SHELF_LIFE,
      width: INPUT_WIDTH.LARGE,
      label: i18next.t('ADMIN.PRODUCTS.PRODUCT_SHELF_LIFE'),
      rule: yup.lazy((value) =>
        value === '' || !value
          ? yup.string().nullable()
          : yup
              .string()
              .test(
                'isShelfLifeANumber',
                i18next.t('ADMIN.CENTRAL_PRODUCTS.VALUE_MUST_BE_A_NUMBER'),
                (value) => {
                  const formattedValue = parseFloat(value);
                  return !isNaN(formattedValue);
                },
              )
              .test(
                'isShelfLifeAnInt',
                i18next.t('ADMIN.CENTRAL_PRODUCTS.VALUE_MUST_BE_AN_INTEGER'),
                (value) => {
                  const formattedValue = parseFloat(value);
                  return Number.isInteger(formattedValue);
                },
              ),
      ),
    },
  ]);
};

export const KITCHEN_PRODUCT_FORM_INPUTS = (props) => [
  ...KITCHEN_PRODUCT_GENERAL_INFOS_INPUTS(props),
];

// SUPPLIER PRODUCT SECTION

export const SUPPLIER_PRODUCT_INPUTS = {
  ASSOCIATED_INGREDIENTS: 'entity',
  LOSSES: 'loss',
  PRICE: 'price',
  CURRENCY: 'currency',
  VAT_RATE: 'vatRate',
  CONVERSIONS: 'conversionsDisplayed',
};

export const SUPPLIER_PRODUCT_INGREDIENTS_INPUTS = (props) => {
  const {
    entityUnit,
    packagingUnit,
    ingredients,
    entityId,
    handleConversionCreation,
    composition,
  } = props;

  return [
    {
      type: INPUT_TYPES.TEXT_WITH_ACTION,
      button: {
        id: 1,
        text: <img src={'/images/inpulse/gear-white-small.svg'} />,
        isDisabled: !packagingUnit,
        handleClick: () => {
          handleConversionCreation(packagingUnit);
        },
      },
      name: SUPPLIER_PRODUCT_INPUTS.CONVERSIONS,
      width: INPUT_WIDTH.LARGE,
      isUnchangeable: true, // Makes input properly "readonly"
      isReadOnly: !packagingUnit, // Makes input disabled
      label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.CONVERSION_PLURAL'),
      placeholder: i18next.t('ADMIN.SUPPLIER_PRODUCTS.CONVERSION_NONE'),
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.CONVERSION_TOOLTIP'),
      rule: yup
        .string()
        .test(
          'areSPAndIngredientSameUnit',
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_INGREDIENT_AND_SP_UNIT_ERROR', {
            spUnit: packagingUnit,
          }),
          (conversionsDisplayed) => {
            // Required to get the conversions from what is displayed in the input
            // Since it is read-only updating the conversions from the state is not reliable
            const formattedConversions = conversionsDisplayed
              ? _getConversionsFromDisplay(conversionsDisplayed, packagingUnit)
              : [];

            return packagingUnit && entityUnit
              ? doesIngredientsHaveApplicableUnits(packagingUnit, entityUnit, formattedConversions)
              : true;
          },
        )
        .nullable(),
    },
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: SUPPLIER_PRODUCT_INPUTS.ASSOCIATED_INGREDIENTS,
      defaultValue: null,
      label: `${i18next.t('GENERAL.ASSOCIATED_INGREDIENT')} :`,
      width: INPUT_WIDTH.LARGE,
      iconSrc: '/images/inpulse/search-black-small.svg',
      isRequired: true,
      isDisabled: () => composition && composition.isIngredient,
      items: ingredients,
      link: entityId
        ? {
            onClick: () => {
              if (entityId) {
                window.open(
                  `${ADMIN_FEATURE_PATHS.ADMIN_INGREDIENTS}/${entityId}/details`,
                  '_blank',
                );
              }
            },
            customStyle: { marginLeft: '8px' },
          }
        : null,
      rule: yup
        .object()
        .test(
          'isEmpty',
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
            field: i18next.t('GENERAL.INGREDIENT_PLURAL'),
          }),
          (value) => !isEmpty(value),
        )
        .shape({
          id: yup.string().required(),
          name: yup.string().required(),
        })
        .strict(),
    },
    {
      type: INPUT_TYPES.TEXT_NUMBER,
      name: SUPPLIER_PRODUCT_INPUTS.LOSSES,
      width: INPUT_WIDTH.LARGE,
      label: `${i18next.t('GENERAL.LOSSES')} (%)`,
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_RECIPE_LOSS_TOOLTIP'),
      rule: yup.lazy((value) =>
        value === ''
          ? yup.string()
          : yup
              .number()
              .nullable()
              .min(
                0,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
                  field: i18next.t('GENERAL.LOSSES'),
                  value: 0,
                }),
              )
              .max(
                100,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_LOWER_THAN_ERROR', {
                  field: i18next.t('GENERAL.LOSSES'),
                  value: 100,
                }),
              ),
      ),
    },
  ];
};

const _getConversionsFromDisplay = (conversionDisplayed, packagingUnit) =>
  conversionDisplayed.split(' = ').map((conversion) => {
    const [value, unit] = conversion.split(' ');
    const formattedUnit = unit === 'u.' ? 'unit' : unit;
    return {
      convertedQuantity: value,
      convertedUnit: formattedUnit,
      isBaseUnit: formattedUnit === packagingUnit,
    };
  });

export const SUPPLIER_PRODUCT_PRICE_INPUTS = (props) => {
  const { packagings, storeName, currency } = props;

  const orderPackaging = packagings && packagings.find(({ isUsedInOrder }) => isUsedInOrder);

  const storeNameToLowerCase = getClientStoreNameTranslation(storeName, true).toLowerCase();

  return [
    {
      type: INPUT_TYPES.TEXT_NUMBER,
      name: SUPPLIER_PRODUCT_INPUTS.PRICE,
      width: INPUT_WIDTH.LARGE,
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.PRICE_TOOLTIP', {
        storeName: storeNameToLowerCase,
      }),
      label: `* ${i18next.t('GENERAL.PRICE_EX_TAX')} ${
        orderPackaging ? `(${orderPackaging.name})` : ''
      }`,
      rule: yup
        .number()
        .typeError(
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
            field: i18next.t('GENERAL.PRICE_EX_TAX'),
            value: 0,
          }),
        )
        .min(
          0,
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
            field: i18next.t('GENERAL.PRICE_EX_TAX'),
            value: 0,
          }),
        )
        .required(
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
            field: i18next.t('GENERAL.PRICE_EX_TAX'),
          }),
        )
        .typeError(i18next.t('ADMIN.CENTRAL_PRODUCTS.VALUE_MUST_BE_A_NUMBER')),
    },
    {
      type: INPUT_TYPES.SINGLE_SELECT,
      name: SUPPLIER_PRODUCT_INPUTS.CURRENCY,
      label: `${i18next.t('GENERAL.CURRENCY')} :`,
      width: INPUT_WIDTH.LARGE,
      isDisabled: () => true,
      isRequired: true,
      selectedItem: currency,
    },
    {
      type: INPUT_TYPES.TEXT_NUMBER,
      name: SUPPLIER_PRODUCT_INPUTS.VAT_RATE,
      width: INPUT_WIDTH.LARGE,
      tooltipText: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_VAT_RATE_TOOLTIP'),
      label: `* ${i18next.t('GENERAL.VAT_RATE')} (%)`,
      rule: yup
        .number()
        .typeError(
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
            field: i18next.t('GENERAL.VAT_RATE'),
            value: 0,
          }),
        )
        .min(
          0,
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
            field: i18next.t('GENERAL.VAT_RATE'),
            value: 0,
          }),
        )
        .max(
          100,
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_LOWER_THAN_ERROR', {
            field: i18next.t('GENERAL.VAT_RATE'),
            value: 100,
          }),
        )
        .required(
          i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
            field: i18next.t('GENERAL.VAT_RATE'),
          }),
        ),
    },
  ];
};

export const SUPPLIER_PRODUCT_FORM_INPUTS = (props) => [
  ...SUPPLIER_PRODUCT_INGREDIENTS_INPUTS(props),
  ...SUPPLIER_PRODUCT_PRICE_INPUTS(props),
];

export default {
  ALL_KITCHEN_PRODUCTS_FIELDS_FOR_DETAILS_VIEW,
  KITCHEN_PRODUCT_FORM_INPUTS,
  KITCHEN_PRODUCT_GENERAL_INFOS_INPUTS,
  SUPPLIER_PRODUCT_FORM_INPUTS,
  SUPPLIER_PRODUCT_INGREDIENTS_INPUTS,
};
