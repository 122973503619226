import { get, keyBy } from 'lodash';

import { getFeaturePropsByFeaturePath } from '@commons/utils/features';

export const getBreakagePropertyName = (userRights) => {
  const featureProps = getFeaturePropsByFeaturePath(userRights, '/home');

  return get(featureProps, 'wasteRate', 'turnoverWasteRate');
};

export const getDisplaySupplierProductPackagingWeight = (userRights) => {
  const featureProps = getFeaturePropsByFeaturePath(userRights, '/order/orders');

  return get(featureProps, 'displaySupplierProductPackagingWeight', false);
};

export const getDisplayManufacturerName = (userRights) => {
  const featureProps = getFeaturePropsByFeaturePath(
    userRights,
    '/admin/suppliers/supplier-products',
  );

  return get(featureProps, 'displayManufacturerName', false);
};

export const getResetStockEveryDayForCentralKitchen = (userRights) => {
  const featureProps = getFeaturePropsByFeaturePath(userRights, '/order/orders');

  return get(featureProps, 'resetStockEveryDayForCentralKitchen', false);
};

export const getDisableCreation = (userRights) => {
  const featureProps = getFeaturePropsByFeaturePath(userRights, '/backoffice/users/team');

  return get(featureProps, 'disableCreation', false);
};

export const getPrevHorizon = (userRights) => {
  const featureProps = getFeaturePropsByFeaturePath(userRights, '/forecast/forecast/turnover');

  return get(featureProps, 'prevHorizon', {});
};

export const getDisableResetPassword = (userRights) => {
  const featureProps = getFeaturePropsByFeaturePath(userRights, '/users');

  return get(featureProps, 'disableResetPassword', false);
};

export const getDisableEdition = (userRights) => {
  const featureProps = getFeaturePropsByFeaturePath(userRights, '/users');

  return get(featureProps, 'disableEdition', false);
};

export const getGenerateInvoice = (userRights) => {
  const featureProps = getFeaturePropsByFeaturePath(
    userRights,
    '/central-kitchen-order/preparation',
  );

  return get(featureProps, 'generateInvoice', false);
};

export const getAuthorizedActions = (userRights, path) => {
  const userRightsByPath = keyBy(userRights, 'path');

  return get(userRightsByPath, `[${path}].actions`, []);
};

export const getAuthorizedActionsForPaths = (userRights, paths) => {
  const userRightsByPath = keyBy(userRights, 'path');

  return paths.map((path) => get(userRightsByPath, `[${path}].actions`, [])).flat();
};
