import _ from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Tooltip } from '@commons/utils/styledLibraryComponents';
import { translateUnit } from '@commons/utils/translateUnit';
import DisplayNumber from '@commons/DisplayNumber';
import Text from '@commons/Text';

import { LOSS_TYPE } from './constants';
import {
  NameWithThumbnail,
  Thumbnail,
  ThumbnailContainer,
  NameContainer,
  NameContent,
  SkuContainer,
} from './styledComponents';

const DEFAULT_ICON_PRODUCT = '/images/inpulse/product-photo-placeholder.svg';
const DEFAULT_ICON_SUPPLIER_PRODUCT = '/images/inpulse/supplier-product-photo-placeholder.svg';

const renderText = (item) => <Text highlighted>{item ? item : '-'}</Text>;
const renderPrice = (item) => <DisplayNumber displayCurrencyCode={true} number={item} />;
const renderUnit = (unit) => <Text highlighted>{translateUnit(unit)}</Text>;

const renderNameWithThumbnail = (
  name,
  thumbnailSrc,
  setDisplayProductOrSPPicture,
  supplierProductSku,
) => (
  <NameWithThumbnail>
    <ThumbnailContainer
      onClick={() => setDisplayProductOrSPPicture({ state: true, img: thumbnailSrc })}
    >
      <Thumbnail src={thumbnailSrc} />
    </ThumbnailContainer>
    <NameContainer>
      <Tooltip
        renderTooltipTrigger={() => <NameContent>{name}</NameContent>}
        text={name}
        displayBigger
      />
      <SkuContainer>{supplierProductSku}</SkuContainer>
    </NameContainer>
  </NameWithThumbnail>
);

const renderRecipeNameWithThumbnail = (name, item, setDisplayProductOrSPPicture) => {
  const thumbnailSrc = _.get(item, 'recipe.img') || DEFAULT_ICON_PRODUCT;

  return renderNameWithThumbnail(name, thumbnailSrc, setDisplayProductOrSPPicture);
};

const renderProductNameWithThumbnail = (name, item, setDisplayProductOrSPPicture) => {
  const thumbnailSrc = _.get(item, 'product.img') || DEFAULT_ICON_PRODUCT;

  return renderNameWithThumbnail(name, thumbnailSrc, setDisplayProductOrSPPicture);
};

const renderSupplierProductNameWithThumbnail = (name, item, setDisplayProductOrSPPicture) => {
  const thumbnailSrc = _.get(item, 'supplierProduct.img') || DEFAULT_ICON_SUPPLIER_PRODUCT;
  const supplierProductSku = _.get(item, 'supplierProduct.sku', '-');

  return renderNameWithThumbnail(
    name,
    thumbnailSrc,
    setDisplayProductOrSPPicture,
    supplierProductSku,
  );
};

const COLUMNS = () => ({
  CATEGORY: {
    id: 'category',
    propertyKey: 'category',
    name: i18next.t('GENERAL.CATEGORY'),
    render: renderText,
    filterType: 'string',
  },
  CHANNEL: {
    id: 'channel',
    propertyKey: 'channel',
    filterType: 'string',
    name: i18next.t('GENERAL.CHANNEL'),
    render: renderText,
  },
  COST: {
    id: 'cost',
    propertyKey: 'cost',
    name: i18next.t('GENERAL.COST_EX_TAX'),
    render: renderPrice,
    filterType: 'numeric',
  },
  LOSSES: {
    id: 'losses',
    propertyKey: 'losses',
    name: i18next.t('GENERAL.LOSSES'),
    render: renderText,
    filterType: 'numeric',
  },
  UNIT: {
    id: 'unit',
    propertyKey: 'recipe.unit',
    name: i18next.t('GENERAL.UNIT'),
    render: renderUnit,
    filterType: 'string',
  },
  NAME: {
    id: 'name',
    propertyKey: 'name',
    large: true,
    name: i18next.t('GENERAL.NAME'),
  },
  SUPPLIER_NAME: {
    id: 'supplierName',
    propertyKey: 'supplierName',
    name: i18next.t('GENERAL.SUPPLIER'),
  },
  PACKAGING: {
    id: 'packaging',
    propertyKey: 'packaging',
    name: i18next.t('GENERAL.PACKAGING'),
    render: renderText,
    filterType: 'string',
  },
  PRICE: {
    id: 'price',
    propertyKey: 'price',
    name: i18next.t('GENERAL.PRICE_EX_TAX'),
    render: renderPrice,
    filterType: 'numeric',
  },
  TOTAL: {
    id: 'total',
    propertyKey: 'total',
    name: i18next.t('GENERAL.TOTAL_EX_TAX'),
    render: renderPrice,
    filterType: 'numeric',
  },
});

export const getColumnsByType = (lossType, setDisplayProductOrSPPicture) => {
  const translatedColumns = COLUMNS();

  if (lossType === LOSS_TYPE.RECIPE) {
    return [
      {
        ...translatedColumns.NAME,
        render: (name, item) =>
          renderRecipeNameWithThumbnail(name, item, setDisplayProductOrSPPicture),
      },
      translatedColumns.COST,
      translatedColumns.LOSSES,
      translatedColumns.UNIT,
      translatedColumns.CATEGORY,
      translatedColumns.TOTAL,
    ];
  }

  if (lossType === LOSS_TYPE.PRODUCT) {
    return [
      {
        ...translatedColumns.NAME,
        render: (name, item) =>
          renderProductNameWithThumbnail(name, item, setDisplayProductOrSPPicture),
      },
      translatedColumns.CHANNEL,
      translatedColumns.COST,
      translatedColumns.LOSSES,
      translatedColumns.CATEGORY,
      translatedColumns.TOTAL,
    ];
  }

  if (lossType === LOSS_TYPE.SUPPLIER_PRODUCT) {
    return [
      {
        ...translatedColumns.NAME,
        render: (name, item) =>
          renderSupplierProductNameWithThumbnail(name, item, setDisplayProductOrSPPicture),
      },
      translatedColumns.SUPPLIER_NAME,
      translatedColumns.PACKAGING,
      translatedColumns.PRICE,
      translatedColumns.LOSSES,
      translatedColumns.CATEGORY,
      translatedColumns.TOTAL,
    ];
  }
};
