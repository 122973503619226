import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { formatNumberToExcelUsage } from '@commons/DisplayNumber';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getPropertyNoneValue } from '@commons/constants/categoryTypes';
import { getUserTimezone } from '@commons/utils/date';
import utilsXLS from '@commons/utils/makeXLS';

import ExportModalContent from '@lib/inpulse/ExportModal';

import { getClientInfo } from '@selectors/client';

import { supplier } from '@services/supplier';
import inventoryTransferList from '@services/inventoryTransferList';

import { getQuantityMasterUnit } from '@stocks/StocksInventories/components/StockForm/utils/getPrice';

const getMainSheetColumnsSettings = (storeName) => {
  const columns = [
    {
      name: i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_REFERENCE'),
      propertyKey: 'reference',
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_SENDER', { storeName }),
      propertyKey: 'senderStoreName',
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_RECIPIENT', { storeName }),
      propertyKey: 'recipientStoreName',
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_STATUS'),
      propertyKey: 'hasBeenReceived',
      transform: (hasBeenReceived) =>
        hasBeenReceived
          ? i18next.t('STOCKS.TRANSFERS.SENDING_STATUS_VALIDATED')
          : i18next.t('STOCKS.TRANSFERS.SENDING_STATUS_SENT'),
    },
    {
      name: i18next.t('GENERAL.DATE'),
      propertyKey: 'sentAt',
      transform: (date) => moment(date).format('DD/MM/YYYY'),
    },
    {
      name: i18next.t('GENERAL.SUPPLIER'),
      propertyKey: 'supplierName',
    },
    {
      name: i18next.t('GENERAL.CATEGORY'),
      propertyKey: 'category',
    },
    {
      name: i18next.t('GENERAL.SUB_CATEGORY'),
      propertyKey: 'subCategory',
    },
    {
      name: i18next.t('GENERAL.SKU'),
      propertyKey: 'sku',
    },
    {
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'name',
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_TOTAL_IN_UNIT'),
      propertyKey: 'totalUnit',
    },
    {
      name: i18next.t('GENERAL.UNIT'),
      propertyKey: 'unit',
    },
    {
      name: i18next.t('GENERAL.TOTAL_EX_TAX'),
      propertyKey: 'totalPrice',
      type: 'currency',
    },
    {
      name: i18next.t('ADMIN.PRODUCTS.EXPORT_COLUMN_VAT_RATE'),
      propertyKey: 'vatRate',
      transform: (vatRate) => `${formatNumberToExcelUsage(+vatRate, 2)}%`,
    },
  ];

  return columns;
};

const getContextSheetColumnsSettings = (storeName) => {
  const columns = [
    {
      name: i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_REFERENCE'),
      propertyKey: 'reference',
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_SENDER', { storeName }),
      propertyKey: 'senderStoreName',
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_RECIPIENT', { storeName }),
      propertyKey: 'recipientStoreName',
    },
    {
      name: i18next.t('GENERAL.SUPPLIER'),
      propertyKey: 'supplierName',
    },
    {
      name: i18next.t('GENERAL.CATEGORY'),
      propertyKey: 'lnkSupplierproductInventoryrel.category',
    },
    {
      name: i18next.t('GENERAL.SUB_CATEGORY'),
      propertyKey: 'lnkSupplierproductInventoryrel.subCategory',
    },
    {
      name: i18next.t('GENERAL.SKU'),
      propertyKey: 'lnkSupplierproductInventoryrel.sku',
    },
    {
      name: i18next.t('GENERAL.NAME'),
      propertyKey: 'lnkSupplierproductInventoryrel.name',
    },
    {
      name: i18next.t('GENERAL.QUANTITY'),
      propertyKey: 'tot',
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_STOCK_UNIT'),
      propertyKey: 'supplierProductPackagingName',
    },
    {
      name: i18next.t('GENERAL.PRICE_EX_TAX'),
      propertyKey: 'supplierProductPackagingPrice',
      type: 'currency',
    },
    {
      name: i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_TOTAL_IN_UNIT'),
      propertyKey: 'totalUnit',
    },
    {
      name: i18next.t('GENERAL.UNIT'),
      propertyKey: 'masterUnit',
    },
    {
      name: i18next.t('GENERAL.TOTAL_EX_TAX'),
      propertyKey: 'totalPrice',
      type: 'currency',
    },
  ];

  return columns;
};

const formatDataForMainSheet = (transfer, inventories) => {
  const inventoriesGroupedBySupplierProductId = _.groupBy(inventories, 'supplierProductId');

  const formattedData = [];

  _.keys(inventoriesGroupedBySupplierProductId).forEach((supplierProductId) => {
    const firstInventory = _.head(inventoriesGroupedBySupplierProductId[supplierProductId]);
    const totalUnit = _.sumBy(
      inventoriesGroupedBySupplierProductId[supplierProductId],
      'totalUnit',
    );
    const totalPrice = _.sumBy(
      inventoriesGroupedBySupplierProductId[supplierProductId],
      'totalPrice',
    );
    const supplierProductFormattedData = {
      reference: firstInventory.reference,
      senderStoreName: firstInventory.senderStoreName,
      recipientStoreName: firstInventory.recipientStoreName,
      hasBeenReceived: transfer.hasBeenReceived,
      sentAt: moment.tz(transfer.sentAt, firstInventory.senderStoreTimezone),
      supplierName: firstInventory.supplierName,
      category: _.get(firstInventory, 'lnkSupplierproductInventoryrel.category', ''),
      subCategory: _.get(firstInventory, 'lnkSupplierproductInventoryrel.subCategory', ''),
      sku: _.get(firstInventory, 'lnkSupplierproductInventoryrel.sku', ''),
      name: _.get(firstInventory, 'lnkSupplierproductInventoryrel.name', ''),
      vatRate: `${_.get(firstInventory, 'lnkSupplierproductInventoryrel.vatRate') || 0}`,
      totalUnit,
      unit: firstInventory.masterUnit,
      totalPrice,
    };

    formattedData.push(supplierProductFormattedData);
  });

  return formattedData;
};

const formatDataForContextSheet = (transfer, inventories, suppliers) => {
  const formattedData = inventories.map((inventory) => {
    const supplierId = _.get(inventory, 'lnkSupplierproductInventoryrel.supplierId');
    const supplierName = _.get(_.find(suppliers, ['id', supplierId]), 'name', '');
    const supplierProductPackagings = _.get(inventory, 'lnkSupplierproductInventoryrel.packagings');
    const matchingSupplierProductPackaging = _.find(supplierProductPackagings, [
      'id',
      inventory.supplierProductPackagingId,
    ]);

    const masterSupplierProductPackaging = _.find(supplierProductPackagings, [
      'masterSupplierProductPackagingId',
      null,
    ]);

    const totByPackagingId = {};

    totByPackagingId[matchingSupplierProductPackaging.id] = 1; // Set to 1 to get the unit price
    const supplierProductPackagingPrice = inventory.price;

    totByPackagingId[matchingSupplierProductPackaging.id] = inventory.tot;
    const totalUnit = getQuantityMasterUnit({
      packagings: supplierProductPackagings,
      totByPackagingId,
    });

    const senderStore = _.get(transfer, 'senderStore', null);

    const formattedInventory = {
      ...inventory,
      reference: transfer.reference,
      senderStoreName: _.get(senderStore, 'name', ''),
      senderStoreTimezone: _.get(senderStore, 'timezone', 'Europe/Paris'),
      recipientStoreName: _.get(transfer.recipientStore, 'name', ''),
      supplierName,
      supplierProductPackagingName: matchingSupplierProductPackaging.name,
      supplierProductPackagingPrice,
      totalUnit,
      masterUnit: masterSupplierProductPackaging.unit,
      totalPrice: supplierProductPackagingPrice * inventory.tot,
    };

    return formattedInventory;
  });
  return formattedData;
};

const generateFileName = (transfers) => {
  const date = moment.tz(getUserTimezone()).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
  if (transfers.length > 1) {
    return i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_FILE_NAME', { date });
  }
  const transfer = _.head(transfers);
  return transfer.reference;
};

const updateProgress = (index, totalLength, setProgress) => {
  const updatedProgress = (index / totalLength) * 100;

  setProgress(updatedProgress);
};

export const exportTransferContentInFullScreenModal = async (
  transfer,
  inventories,
  clientId,
  storeName,
  showErrorMessage,
  currency,
) => {
  if (!transfer) {
    return;
  }

  try {
    const suppliers = await supplier.getSuppliersOfClient(clientId);

    const contextSheetFormattedData = formatDataForContextSheet(transfer, inventories, suppliers);
    const mainSheetFormattedData = formatDataForMainSheet(transfer, contextSheetFormattedData);
    const fileName = generateFileName([transfer]);

    const mainSheet = utilsXLS.generateDefaultSheet(
      i18next.t('FEATURE.OPERATIONS.TRANSFER'),
      getMainSheetColumnsSettings(storeName),
      mainSheetFormattedData,
      currency,
    );

    const contextSheet = utilsXLS.generateDefaultSheet(
      i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_PACKAGING_SHEET_NAME'),
      getContextSheetColumnsSettings(storeName),
      contextSheetFormattedData,
      currency,
    );

    utilsXLS.makeXLS(fileName, [mainSheet, contextSheet]);
  } catch (err) {
    showErrorMessage(i18next.t('STOCKS.TRANSFERS.FORM_EXPORT_FETCH_TRANSFER_ERROR'));
  }
};

const exportTransfersContents = async (
  clientId,
  transfers,
  setLoading,
  setProgress,
  setTitle,
  storeName,
  currency,
) => {
  if (!transfers) {
    return;
  }

  try {
    const suppliers = await supplier.getSuppliersOfClient(clientId);
    const mainSheetData = [];
    const contextSheetData = [];
    let index = 0;

    for (const transfer of transfers) {
      const { data } = await inventoryTransferList.getInventoryTransferListWithInventoriesById(
        transfer.id,
      );
      const inventories = _.get(data, 'inventories', []);

      const formattedInventories = inventories.map((inventory) => ({
        ...inventory,
        lnkSupplierproductInventoryrel: {
          ...inventory.lnkSupplierproductInventoryrel,
          category:
            _.get(inventory, 'lnkSupplierproductInventoryrel.category') || getPropertyNoneValue(),
          subCategory:
            _.get(inventory, 'lnkSupplierproductInventoryrel.subCategory') ||
            getPropertyNoneValue(),
        },
      }));

      const contextSheetFormattedData = formatDataForContextSheet(
        transfer,
        formattedInventories,
        suppliers,
      );
      const mainSheetFormattedData = formatDataForMainSheet(transfer, contextSheetFormattedData);

      contextSheetData.push(...contextSheetFormattedData);
      mainSheetData.push(...mainSheetFormattedData);
      index += 1;
      updateProgress(index, transfers.length, setProgress);
    }

    const fileName = generateFileName(transfers);

    const mainSheet = utilsXLS.generateDefaultSheet(
      i18next.t('FEATURE.OPERATIONS.TRANSFER'),
      getMainSheetColumnsSettings(storeName),
      mainSheetData,
      currency,
    );

    const contextSheet = utilsXLS.generateDefaultSheet(
      i18next.t('STOCKS.TRANSFERS.CONTENT_EXPORT_PACKAGING_SHEET_NAME'),
      getContextSheetColumnsSettings(storeName),
      contextSheetData,
      currency,
    );

    setProgress(100);

    utilsXLS.makeXLS(fileName, [mainSheet, contextSheet]);

    exportReady(setLoading, setTitle);
  } catch (err) {
    exportFailure(setLoading, setTitle);
  }
};

/**
 * Close the modal
 *
 * @returns {void}
 */
const exitModal = (setLoading, closeModal) => {
  setLoading(false);

  closeModal();
};

/**
 * Set component with export success state
 *
 * @returns {void}
 */
const exportReady = (setLoading, setTitle) => {
  setTitle(i18next.t('GENERAL.EXPORT_SUCCESS'));
  setLoading(false);
};

/**
 * Set component with export failure state
 *
 * @returns {void}
 */
const exportFailure = (setLoading, setTitle) => {
  setTitle(i18next.t('GENERAL.EXPORT_FAILURE'));
  setLoading(false);
};

const TransferExportContentModal = (props) => {
  const {
    currency,
    params: { title, transfers },
    closeModal,
    client: { clientId, storeName },
  } = props;

  const [progress, setProgress] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [titleModal, setTitle] = useState(title || '');

  const storeNameTranslation = getClientStoreNameTranslation(storeName);

  useEffect(() => {
    (async function loadData() {
      exportTransfersContents(
        clientId,
        transfers,
        setLoading,
        setProgress,
        setTitle,
        storeNameTranslation,
        currency,
      );
    })();
  }, [transfers]);

  return (
    <ExportModalContent
      {...props}
      closeModal={closeModal}
      exitModal={exitModal}
      isLoading={isLoading}
      progress={progress}
      setLoading={setLoading}
      titleModal={titleModal}
    />
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps)(TransferExportContentModal);
