import { get } from 'lodash';
import i18next from 'i18next';
import Radio from '@mui/material/Radio';
import React from 'react';

import { Dropdown, ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getTheme } from '@commons/utils/theme';
import Text, { ENUM_FONTS, ENUM_COLORS } from '@commons/Text';

/**
 * @typedef {Object} SspmAvailabilities
 * @property {boolean} available
 * @property {boolean} hasStock
 * @property {boolean} hasDlc
 */
import { AVAILABILITIES_TYPES } from '../constants';

import {
  Container,
  DropdownContainer,
  ToggleContainer,
  ContentContainer,
  ChoicesContainer,
  RadioButtonContainer,
  RadioChoice,
} from './styledComponents';

export const SelectAvailabilitiesStepContent = ({
  selectedAvailabilities,
  userStoresGroups,
  storesChoices,
  selectedGroups,
  setSelectedGroups,
  selectedStores,
  setSelectedStores,
  handleAvailabilitiesUpdates,
}) => {
  const theme = getTheme();

  const getValueOfKey = (key) => get(selectedAvailabilities, key, null);

  const getRadioSxStyle = (theme) => ({
    color: theme.colors.blacks.ipBlack1,
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      'border-width': '1px',
    },
    '&.Mui-checked': {
      color: theme.colors.blacks.ipBlack1,
    },
  });

  const renderToggleSwitch = (key) => (
    <ToggleSwitch
      checked={getValueOfKey(key) !== null}
      handleClick={() => handleAvailabilitiesUpdates('toggle', key, null)}
      id={`update-${key}`}
    />
  );

  const renderTruthyRadio = (key) => (
    <Radio
      checked={getValueOfKey(key)}
      disableColor={theme.colors.greys.lighty}
      disabled={getValueOfKey(key) === null}
      sx={getRadioSxStyle(theme)}
      value={getValueOfKey(key)}
      onChange={() => handleAvailabilitiesUpdates('radio', key, true)}
    />
  );

  const renderFalsyRadio = (key) => (
    <Radio
      checked={getValueOfKey(key) !== null && getValueOfKey(key) === false}
      disableColor={theme.colors.greys.lighty}
      disabled={getValueOfKey(key) === null}
      sx={getRadioSxStyle(theme)}
      value={getValueOfKey(key) === false}
      onChange={() => handleAvailabilitiesUpdates('radio', key, false)}
    />
  );

  const renderRadioLabel = (key, label) => (
    <Text color={getValueOfKey(key) === null ? ENUM_COLORS.LIGHTY : ENUM_COLORS.DARKEST}>
      {label}
    </Text>
  );

  return (
    <Container>
      <ContentContainer>
        <Text color={ENUM_COLORS.DARKER} font={ENUM_FONTS.TEXT_BIG_HEIGHT_24}>
          {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_AVAILABILITIES_INFO')}
        </Text>
        <DropdownContainer>
          <Dropdown
            isUniqueSelection={false}
            items={userStoresGroups}
            label={`${i18next.t('GENERAL.GROUPS')} :`}
            placeholder={i18next.t('GENERAL.SELECT_NONE_MASCULINE')}
            selectedItems={selectedGroups}
            isRequired
            onSelectionChange={setSelectedGroups}
          />
          <Dropdown
            customListStyle={{ position: 'fixed' }}
            isUniqueSelection={false}
            items={storesChoices}
            label={`${i18next.t('GENERAL.STORES')} :`}
            placeholder={i18next.t('GENERAL.SELECT_NONE_MASCULINE')}
            selectedItems={selectedStores}
            isRequired
            onSelectionChange={setSelectedStores}
          />
        </DropdownContainer>

        <ChoicesContainer>
          <ToggleContainer>
            {renderToggleSwitch(AVAILABILITIES_TYPES.AVAILABLE)}
            <Text>
              {i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_AVAILABILITIES_AVAILABLE')}
            </Text>
          </ToggleContainer>

          <RadioButtonContainer>
            <RadioChoice>
              {renderTruthyRadio(AVAILABILITIES_TYPES.AVAILABLE)}
              {renderRadioLabel(
                AVAILABILITIES_TYPES.AVAILABLE,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_AVAILABILITIES_MAKE_AVAILABLE'),
              )}
            </RadioChoice>
            <RadioChoice>
              {renderFalsyRadio(AVAILABILITIES_TYPES.AVAILABLE)}
              {renderRadioLabel(
                AVAILABILITIES_TYPES.AVAILABLE,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_AVAILABILITIES_MAKE_UNAVAILABLE'),
              )}
            </RadioChoice>
          </RadioButtonContainer>
        </ChoicesContainer>

        <ChoicesContainer>
          <ToggleContainer>
            {renderToggleSwitch(AVAILABILITIES_TYPES.STOCK)}
            <Text>{i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_AVAILABILITIES_STOCK')}</Text>
          </ToggleContainer>

          <RadioButtonContainer>
            <RadioChoice>
              {renderTruthyRadio(AVAILABILITIES_TYPES.STOCK)}
              {renderRadioLabel(
                AVAILABILITIES_TYPES.STOCK,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_AVAILABILITIES_MAKE_VISIBLE'),
              )}
            </RadioChoice>
            <RadioChoice>
              {renderFalsyRadio(AVAILABILITIES_TYPES.STOCK)}
              {renderRadioLabel(
                AVAILABILITIES_TYPES.STOCK,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_AVAILABILITIES_MAKE_INVISIBLE'),
              )}
            </RadioChoice>
          </RadioButtonContainer>
        </ChoicesContainer>

        <ChoicesContainer>
          <ToggleContainer>
            {renderToggleSwitch(AVAILABILITIES_TYPES.DLC)}
            <Text>{i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_AVAILABILITIES_LOSS')}</Text>
          </ToggleContainer>

          <RadioButtonContainer marginBottom={'0px'}>
            <RadioChoice>
              {renderTruthyRadio(AVAILABILITIES_TYPES.DLC)}
              {renderRadioLabel(
                AVAILABILITIES_TYPES.DLC,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_AVAILABILITIES_MAKE_VISIBLE'),
              )}
            </RadioChoice>
            <RadioChoice>
              {renderFalsyRadio(AVAILABILITIES_TYPES.DLC)}
              {renderRadioLabel(
                AVAILABILITIES_TYPES.DLC,
                i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_UPDATE_AVAILABILITIES_MAKE_INVISIBLE'),
              )}
            </RadioChoice>
          </RadioButtonContainer>
        </ChoicesContainer>
      </ContentContainer>
    </Container>
  );
};

/**
 * Display the modal to update SSPM availabilities,
 *
 * @param {SspmAvailabilities[]} selectedAvailabilities
 * @param {Function} setSelectedAvailabilities
 * @param {Group[]} userStoresGroups,
 * @param {Store[]} storesChoices,
 * @param {Group[]} selectedGroups,
 * @param {Group[]} setSelectedGroups,
 * @param {Store[]} selectedStores,
 * @param {Store[]} setSelectedStores,
 * @param {Function} handleAvailabilitiesUpdates
 * @param {Function} handleSspmUpdate
 * @param {Function} handleSspmUpdateModalCloseCleanUp
 */
export const getSspmStepModalConfig = ({
  selectedAvailabilities,
  userStoresGroups,
  storesChoices,
  selectedGroups,
  setSelectedGroups,
  selectedStores,
  setSelectedStores,
  handleAvailabilitiesUpdates,
  handleSspmUpdate,
  handleSspmUpdateModalCloseCleanUp,
}) => ({
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: handleSspmUpdate,
      preventClosing: true,
    },
  ],
  title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_TITLE_UPDATE_AVAILABILITIES'),
  icon: '/images/inpulse/pen-black-small.svg',
  type: 'action',
  handleCloseCleanUp: handleSspmUpdateModalCloseCleanUp,
  component: SelectAvailabilitiesStepContent,
  data: {
    selectedAvailabilities,
    userStoresGroups,
    storesChoices,
    selectedGroups,
    setSelectedGroups,
    selectedStores,
    setSelectedStores,
    handleAvailabilitiesUpdates,
  },
  height: 'auto',
});
