import Dropzone from 'react-dropzone';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

// Commons
import { addDateSuffixToFileName } from '@commons/utils/fileUtils';
import { Button } from '@commons/utils/styledLibraryComponents';
import { DeepsightComponentLoader } from '@commons/DeepsightComponents';
import { FILES_STATUS_CODES } from '../../utils/fileStatus';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

// Design
import {
  Container,
  EmptyStateContainer,
  EmptyStateTextContainer,
  ImportEndedContainer,
  ImportInfosContainer,
  ImportInProgressContainer,
  ImportSuccessTextContainer,
  LoaderContainer,
} from './styledComponents';

const MAX_FILE_SIZE = 5242880; // 5MB

const DragAndDropUploader = ({
  selectedFiles,
  selectFiles,
  isImporting,
  hasEndedImport,
  filesWithErrors,
  clientStoreName,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [hasIncompatibleFiles, setHasIncompatibleFiles] = useState(false);
  const [hasInsufficientCredit, setHasInsufficientCredit] = useState(false);

  useEffect(() => {
    const hasInsufficientCreditForOneStore = selectedFiles.some(
      ({ fileStatus }) => fileStatus === FILES_STATUS_CODES.INSUFFICIENT_CREDITS,
    );

    setHasInsufficientCredit(hasInsufficientCreditForOneStore);
  }, [selectedFiles]);

  const handleFilesDrop = (files) => {
    setIsDragging(false);
    setHasIncompatibleFiles(false);

    if (!files.length) {
      return setHasIncompatibleFiles(true);
    }

    let hasErrors = false;

    const renamedFiles = files.reduce((acc, file) => {
      if (file.size > MAX_FILE_SIZE) {
        hasErrors = true;
        setHasIncompatibleFiles(true);
        return acc;
      }
      const newFileName = addDateSuffixToFileName(file.name, new Date());
      acc.push(new File([file], newFileName, { type: file.type }));

      return acc;
    }, []);

    if (hasErrors) {
      return;
    }

    selectFiles(renamedFiles);
    setHasIncompatibleFiles(false);
  };

  // Import success
  if (hasEndedImport && !filesWithErrors.length) {
    return (
      <ImportEndedContainer type={'success'}>
        <EmptyStateContainer>
          <img src="/images/inpulse/drop-file.svg" />
          <ImportSuccessTextContainer>
            <Text color={ENUM_COLORS.BLACK} font={ENUM_FONTS.TEXT_BIG_BOLD_HEIGHT_16}>
              {i18next.t('INVOICE.INVOICE_CONTROLS.DRAG_IMPORTS_SUCCESS_TITLE')}
            </Text>
            <Text color={ENUM_COLORS.IP_BLACK_4} font={ENUM_FONTS.TEXT_MIDDLE}>
              {i18next.t('INVOICE.INVOICE_CONTROLS.DRAG_IMPORTS_SUCCESS_SUBTITLE')}
            </Text>
          </ImportSuccessTextContainer>
        </EmptyStateContainer>
      </ImportEndedContainer>
    );
  }

  // Import error
  if (hasEndedImport && !!filesWithErrors.length) {
    return (
      <ImportEndedContainer type={'error'}>
        <EmptyStateContainer>
          <img src="/images/inpulse/pdf-error.svg" />
          <ImportSuccessTextContainer>
            <Text color={ENUM_COLORS.BLACK} font={ENUM_FONTS.TEXT_BIG_BOLD_HEIGHT_16}>
              {i18next.t('INVOICE.INVOICE_CONTROLS.DRAG_IMPORTS_ERROR_TITLE', {
                count: filesWithErrors.length,
              })}
            </Text>
            <Text color={ENUM_COLORS.IP_BLACK_4} font={ENUM_FONTS.TEXT_MIDDLE}>
              {i18next.t('INVOICE.INVOICE_CONTROLS.DRAG_IMPORTS_ERROR_SUBTITLE')}
            </Text>
          </ImportSuccessTextContainer>
        </EmptyStateContainer>
      </ImportEndedContainer>
    );
  }

  if (isImporting) {
    return (
      <ImportInProgressContainer>
        <ImportInfosContainer>
          <LoaderContainer>
            <DeepsightComponentLoader height={32} width={32} />
          </LoaderContainer>
          <Text color={ENUM_COLORS.IP_BLACK_4} font={ENUM_FONTS.TEXT_MIDDLE}>
            {i18next.t('INVOICE.INVOICE_CONTROLS.IMPORT_IN_PROGRESS_SUBTITLE')}
          </Text>
        </ImportInfosContainer>
      </ImportInProgressContainer>
    );
  }

  return (
    <Dropzone
      accept={['application/pdf', 'text/csv']}
      onDragEnter={() => setIsDragging(true)}
      onDragLeave={() => setIsDragging(false)}
      onDrop={handleFilesDrop}
    >
      {({ getRootProps, getInputProps }) => (
        <Container
          {...getRootProps()}
          hasError={hasIncompatibleFiles || hasInsufficientCredit}
          isDragging={isDragging}
        >
          <input {...getInputProps()} />
          {!isDragging && (
            <EmptyStateContainer>
              <img src="/images/inpulse/drag-and-drop-empty.svg" />
              <EmptyStateTextContainer>
                <Text color={ENUM_COLORS.BLACK} font={ENUM_FONTS.TEXT_BIG_BOLD_HEIGHT_16}>
                  {i18next.t('INVOICE.INVOICE_CONTROLS.DRAG_EMPTY_STATE_TITLE')}
                </Text>
                <Text
                  color={
                    hasIncompatibleFiles || hasInsufficientCredit
                      ? ENUM_COLORS.INFO_RED
                      : ENUM_COLORS.IP_BLACK_4
                  }
                  font={ENUM_FONTS.TEXT_MIDDLE}
                >
                  {hasInsufficientCredit
                    ? i18next.t('INVOICE.INVOICE_CONTROLS.NOT_ENOUGH_REMAINING_CONTROLS', {
                        storeName: clientStoreName.toLowerCase(),
                      })
                    : i18next.t('INVOICE.INVOICE_CONTROLS.DRAG_EMPTY_STATE_SUBTITLE')}
                </Text>
              </EmptyStateTextContainer>
              <Button
                color={'inpulse-default'}
                handleClick={() => {}}
                label={i18next.t('INVOICE.INVOICE_CONTROLS.DRAG_SELECT_FILES')}
              />
            </EmptyStateContainer>
          )}
          {isDragging && (
            <EmptyStateContainer>
              <img src="/images/inpulse/drop-file.svg" />
              <Text color={ENUM_COLORS.BLACK} font={ENUM_FONTS.TEXT_BIG_BOLD_HEIGHT_16}>
                {i18next.t('INVOICE.INVOICE_CONTROLS.DROP_FILE_TEXT')}
              </Text>
            </EmptyStateContainer>
          )}
        </Container>
      )}
    </Dropzone>
  );
};

export default DragAndDropUploader;
