import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState, useEffect, useMemo } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import {
  CATEGORY_TYPES_OBJECT,
  getPropertyNoneValue,
  SUB_CATEGORY_TYPES_OBJECT,
} from '@commons/constants/categoryTypes';
import normalizeStringValue from '@commons/utils/normalizeStringValue';
import WhiteCardForm from '@commons/WhiteCardForm';

import { getClientInfo } from '@selectors/client';

import clientService from '@services/client';

import { DYNAMIC_PROPERTIES } from '@admin/commons/dynamicModalProperties';
import HeaderPicture from '@admin/components/HeaderPicture';
import ProductComposition from '@admin/products/products/detail/components/ProductRecipeAssociation';

import { KITCHEN_PRODUCT_GENERAL_INFOS_INPUTS } from '../../utils/formInputsConfigurations';

import { Container, FormContainer } from './styledComponents';
import { getModalParams } from './utils/getModalParams';
import KitchenProductDelivery from './KitchenProductDelivery';

const DEFAULT_KITCHEN_PRODUCT_PICTURE = '/images/inpulse/supplier-product-photo-placeholder.svg';

const KitchenProductInformationsSection = (props) => {
  const {
    formFields,
    product,
    setProduct,
    productForm,
    brands,
    hasMultipleBrands,
    productsCategories,
    setProductsCategories,
    productsSubCategories,
    setProductsSubCategories,
    composition,
    compositionError,
    setComposition,
    selectedCompoType,
    setSelectedCompoType,
    supplierProduct,
    setSupplierProduct,
    supplierProducts,
    suppliers,
    selectedSupplier,
    setSelectedSupplier,
    picture,
    setPicture,
    selectedPictureFile,
    setSelectedPictureFile,
    isSaveAlreadyTriggered,
    formError,
    // Reducer
    client: { clientId },
    openGenericModal,
    refreshGenericModal,
    pageLoading,
    pageLoaded,
    showErrorMessage,
    showSuccessMessage,
  } = props;

  // Categories modal states
  const [inputValue, setInputValue] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const categoryNone = useMemo(
    () => productsCategories.find(({ name }) => name === getPropertyNoneValue()),
    [productsCategories],
  );
  const subCategoryNone = useMemo(
    () => productsSubCategories.find(({ name }) => name === getPropertyNoneValue()),
    [productsSubCategories],
  );

  useEffect(() => {
    if (!!selectedPictureFile && !!picture) {
      formFields.selectedPictureFile = selectedPictureFile;
      formFields.picture = picture;
    }
  }, [selectedPictureFile]);

  // Handle category and sub category creation modal refresh
  useEffect(() => {
    const modalParams = getModalParams({
      propertyName,
      closeCleanUp,
      errorMessage,
      handleInputChange,
      inputValue,
      handleSaveNewItemDropdown,
    });

    refreshGenericModal(modalParams);
  }, [inputValue]);

  const handleCategorySubcategoryCreation = (propertyName) => {
    setPropertyName(propertyName);

    const modalParams = getModalParams({
      propertyName,
      closeCleanUp,
      errorMessage,
      handleInputChange,
      inputValue,
      handleSaveNewItemDropdown,
    });

    openGenericModal(modalParams);
  };

  const handleNewCategoryCreation = async () => {
    try {
      pageLoading();

      const createdCategory = await clientService.createCategory(
        clientId,
        inputValue.trim(),
        CATEGORY_TYPES_OBJECT.CENTRAL_KITCHEN_PRODUCT,
      );

      const updatedCategories = [...productsCategories, createdCategory];

      setProductsCategories(updatedCategories);

      productForm.setValue('category', createdCategory, { shouldDirty: true });

      showSuccessMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_SUCCESS'));
    } catch {
      productForm.setValue('category', categoryNone, { shouldDirty: true });

      showErrorMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_ERROR'));
    } finally {
      setInputValue('');
      pageLoaded();
    }
  };

  const handleNewSubCategoryCreation = async () => {
    try {
      pageLoading();

      const createdSubCategory = await clientService.createSubCategory(
        clientId,
        inputValue.trim(),
        SUB_CATEGORY_TYPES_OBJECT.CENTRAL_KITCHEN_PRODUCT,
      );

      const updatedSubCategories = [...productsSubCategories, createdSubCategory];

      setProductsSubCategories(updatedSubCategories);

      productForm.setValue('subCategory', createdSubCategory, { shouldDirty: true });

      showSuccessMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_SUCCESS'));
    } catch {
      productForm.setValue('subCategory', subCategoryNone, { shouldDirty: true });

      showErrorMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_ERROR'));
    } finally {
      setInputValue('');
      pageLoaded();
    }
  };

  const handleSaveNewItemDropdown = async () => {
    switch (propertyName) {
      case DYNAMIC_PROPERTIES.CATEGORY:
        await handleNewCategoryCreation();
        return;
      case DYNAMIC_PROPERTIES.SUB_CATEGORY:
        await handleNewSubCategoryCreation();
        return;
      default:
        return;
    }
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);

    if (!newValue) {
      setErrorMessage(i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE'));

      return;
    }

    const selectedDropdownItems =
      propertyName === 'category' ? productsCategories : productsSubCategories;

    const alreadyExists = selectedDropdownItems.some(
      ({ name }) => normalizeStringValue(name) === normalizeStringValue(newValue),
    );

    if (alreadyExists) {
      setErrorMessage(
        i18next.t(
          propertyName === 'category'
            ? 'GENERAL.MODAL_CATEGORY_NAME_ALREADY_USED'
            : 'GENERAL.MODAL_SUBCATEGORY_NAME_ALREADY_USED',
        ),
      );

      return;
    }

    setErrorMessage('');
  };

  const closeCleanUp = () => {
    setErrorMessage('');
    setInputValue('');
  };

  return (
    <Container>
      <FormContainer>
        <HeaderPicture
          isDefaultThumbnail={!picture}
          picture={picture || DEFAULT_KITCHEN_PRODUCT_PICTURE}
          setSelectedFile={setSelectedPictureFile}
          title={formFields.name}
          onPictureChange={setPicture}
        />
        <WhiteCardForm
          form={productForm}
          inputs={KITCHEN_PRODUCT_GENERAL_INFOS_INPUTS({
            productsCategories,
            productsSubCategories,
            handleCategorySubcategoryCreation,
            brands,
            hasMultipleBrands,
          })}
          shouldDisplayError={isSaveAlreadyTriggered}
          title={i18next.t('ADMIN.SUPPLIERS.SUPPLIER_DETAIL_GENERAL_INFORMATION')}
        />
        <ProductComposition
          composition={composition}
          compositionError={compositionError}
          isReadOnly={false}
          pageLoaded={pageLoaded}
          pageLoading={pageLoading}
          product={product}
          selectedCompoType={selectedCompoType}
          setComposition={setComposition}
          setSelectedCompoType={setSelectedCompoType}
          showErrorMessage={showErrorMessage}
          supplierProduct={supplierProduct}
          isKitchenProduct
          onProductChange={setProduct}
        />
        <KitchenProductDelivery
          formError={formError}
          product={product}
          selectedSupplier={selectedSupplier}
          setProduct={setProduct}
          setSelectedSupplier={setSelectedSupplier}
          setSupplierProduct={setSupplierProduct}
          supplierProduct={supplierProduct}
          supplierProducts={supplierProducts}
          suppliers={suppliers}
        />
      </FormContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KitchenProductInformationsSection);
