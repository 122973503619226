import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { spUnitByIngredientUnit } from '@commons/utils/units';

import utilsMethods from '../../utils';

import SupplierProductsCompositionList from './components/SupplierProductsCompositionList';
import SupplierProductsCompositionSearchBar from './components/SupplierProductsCompositionSearchBar';

import {
  Container,
  TitleHeader,
  ContainerHeader,
  ContainerContent,
  NumberHeader,
  ErrorContainer,
} from './styledComponents';

export const renderSupplierProductsAssociation = (props) => (
  <>
    <ContainerHeader>
      <TitleHeader>
        {i18next.t('ADMIN.INGREDIENTS.SUB_PAGES_SUPPLIER_ASSOCIATION_TITLE')}
      </TitleHeader>
      <NumberHeader>{props.ingredient.supplierProducts.length}</NumberHeader>
    </ContainerHeader>
    <ContainerContent>
      <SupplierProductsCompositionList
        history={props.history}
        ingredient={props.ingredient}
        suppliers={props.suppliers}
        onIngredientChange={props.onIngredientChange}
      />
      {!props.unitError && (
        <SupplierProductsCompositionSearchBar
          clientId={props.clientId}
          displayShowMoreResult={props.displayShowMoreResult}
          filterByUserCatalog={props.filterByUserCatalog}
          ingredient={props.ingredient}
          isDisabled={props.isLoadingSuppliers}
          isDropdownLoading={props.isDropdownLoading}
          supplierIds={props.supplierIds}
          onIngredientChange={props.onIngredientChange}
        />
      )}
      {props.unitError && (
        <ErrorContainer>
          {i18next.t('ADMIN.INGREDIENTS.SUB_PAGES_SUPPLIER_ASSOCIATION_ERROR')}
        </ErrorContainer>
      )}
    </ContainerContent>
  </>
);

const IngredientSupplierProductAssociation = (props) => {
  const { ingredient } = props;

  const [unitError, setUnitError] = useState(false);

  useEffect(() => {
    let unitErrorFound = false;
    ingredient.supplierProducts.map((item) => {
      if (
        !utilsMethods.areUnitsSame(item.packagingUnit, ingredient.unit) &&
        !item.conversions.some(
          ({ convertedUnit }) => convertedUnit === spUnitByIngredientUnit.get(ingredient.unit),
        )
      ) {
        unitErrorFound = true;
      }
    });
    setUnitError(unitErrorFound);
  }, [ingredient.supplierProducts.length, ingredient.unit]);

  return (
    <Container createStyle={!props.ingredient.id}>
      {renderSupplierProductsAssociation({
        ...props,
        unitError,
      })}
    </Container>
  );
};

export default IngredientSupplierProductAssociation;
