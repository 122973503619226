import i18next from 'i18next';
import React from 'react';

import { DeepsightLabelSpot } from '@commons/DeepsightComponents';
import {
  Dropdown,
  INPUT_WIDTH,
  ToggleSwitch,
  Tooltip,
} from '@commons/utils/styledLibraryComponents';
import { sortCategoryAndSubCategoryDropdown } from '@commons/utils/format';

import { Input } from '@lib/inpulse/Input';

import theme from '@theme';

import { isSameUnit } from '@admin/products/ingredients/utils/assertSupplierProductAreSameUnit';
import { SMALL_UNITS_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';
import { tooltipTexts } from '@admin/products/ingredients/constants/tooltipText';

import { IngredientAllergenDropdown, InlineContainer, Text } from './inputs.style';

export class AllergensInput extends React.PureComponent {
  render() {
    const { ingredientAllergens, availableAllergens, onChange, isReadOnly } = this.props;

    return (
      <IngredientAllergenDropdown>
        <Dropdown
          isDisabled={isReadOnly}
          isLabelTextSmall={true}
          isUniqueSelection={false}
          items={availableAllergens}
          label={
            <>
              {i18next.t('ADMIN.INGREDIENTS.LABEL_ALLERGENS')} :
              {ingredientAllergens && ingredientAllergens.length > 0 && (
                <DeepsightLabelSpot
                  isTooltip={true}
                  number={ingredientAllergens.length}
                  tooltipText={`${
                    ingredientAllergens.length > 1 ? 'Allergènes' : 'Allergène'
                  } : ${ingredientAllergens.map((allergen) => allergen.name).join(', ')}.`}
                />
              )}
            </>
          }
          selectedItems={ingredientAllergens}
          width={INPUT_WIDTH.FULL}
          onSelectionChange={onChange}
        />
      </IngredientAllergenDropdown>
    );
  }
}

export class IsStrategicInput extends React.PureComponent {
  render() {
    const { id, checked, handleClick, isReadOnly } = this.props;

    return (
      <InlineContainer>
        <ToggleSwitch checked={checked} disabled={isReadOnly} handleClick={handleClick} id={id} />
        <Text>{`${i18next.t('ADMIN.INGREDIENTS.STRATEGIC_TOGGLE')}`}</Text>
        <Tooltip text={`${i18next.t('ADMIN.INGREDIENTS.STRATEGIC_TOGGLE_TOOLTIP')}`} />
      </InlineContainer>
    );
  }
}

/*************************************/
/* Inputs Ingredient Form Configuration */
/*************************************/

export const IngredientNameInput = {
  keyProperty: 'name',
  component: Input,
  props: ({ ingredient, onIngredientChange, isReadOnly }) => ({
    label: `* ${i18next.t('ADMIN.INGREDIENTS.LABEL_NAME')}`,
    type: 'text',
    readOnly: isReadOnly,
    onChange: (event) => handleOnChangeIngredient(ingredient, onIngredientChange, event, 'name'),
  }),
};

export const IngredientCategoryInput = {
  keyProperty: 'categoryId',
  component: Dropdown,
  props: ({
    ingredient,
    onIngredientChange,
    ingredientsCategories,
    isReadOnly,
    isChildCreation,
    ...props
  }) => {
    const noneCategory = ingredientsCategories.find(({ name }) => !name);

    const selectedCategory =
      ingredientsCategories.find(({ id }) => id === ingredient.categoryId) || noneCategory;

    return {
      label: `${i18next.t('ADMIN.INGREDIENTS.LABEL_CATEGORY')} :`,
      items: ingredientsCategories,
      selectedItem: selectedCategory,
      onSelectionChange: (selectedItem, _, isUnselected) => {
        handleOnChangeIngredient(
          ingredient,
          onIngredientChange,
          { target: { value: isUnselected ? null : selectedItem.name } },
          'categoryId',
          !!selectedItem ? selectedItem.id : null,
        );
      },
      sortBy: (items) => sortCategoryAndSubCategoryDropdown(items),
      labelStyle: { font: theme.fonts.textSmall },
      width: INPUT_WIDTH.FULL,
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      isDisabled: isReadOnly,
      button: !isChildCreation
        ? {
            id: 1,
            text: i18next.t('GENERAL.LIST_CREATE_CATEGORY'),
            handleClick: () => {
              handleCategoryCreation('category', props);
            },
            iconSrc: '/images/inpulse/add-black-small.svg',
          }
        : null,
    };
  },
};

export const IngredientUnitSelector = (props) => {
  const { ingredient, onIngredientChange } = props;

  const selectedUnit = SMALL_UNITS_DROPDOWN_ITEMS().find((unit) => unit.id === ingredient.unit);

  return (
    <Dropdown
      customStyle={{ marginBottom: '28px' }} // not fitting the 8px rule to match legacy page style
      isDisabled={!!ingredient.id}
      isLabelTextSmall={true}
      isRequired={true}
      items={SMALL_UNITS_DROPDOWN_ITEMS()}
      label={`${i18next.t('GENERAL.UNIT')} :`}
      selectedItem={selectedUnit}
      width={INPUT_WIDTH.FULL}
      onSelectionChange={(selectedItem) => {
        handleOnChangeIngredient(ingredient, onIngredientChange, selectedItem.id, 'unit');
      }}
    />
  );
};

export const IngredientUnityInput = {
  keyProperty: 'unit',
  component: IngredientUnitSelector,
  props: ({ ingredient, onIngredientChange }) => ({
    ingredient,
    onIngredientChange,
  }),
};

export const IngredientCostInput = {
  keyProperty: 'cost',
  component: Input,
  props: ({
    ingredient,
    onIngredientChange,
    isReadOnly,
    isCostInputDisabled = false,
    currency,
  }) => {
    const validSupplierProducts = ingredient.supplierProducts.filter((supplierProduct) => {
      if (!+supplierProduct.price || !supplierProduct.isUsedForCost) {
        return false;
      }

      const ingredientUnitHasConversions = supplierProduct.conversions.some(
        ({ convertedUnit }) => convertedUnit === getIngredientUnit(ingredient.unit),
      );

      return isSameUnit(supplierProduct, ingredient.unit) || ingredientUnitHasConversions;
    });

    return {
      type: 'number',
      label: `${i18next.t('ADMIN.INGREDIENTS.LABEL_COST')} ${
        ingredient.unit ? `(${currency.alphabeticCode}/${getIngredientUnit(ingredient.unit)})` : ''
      } `,
      readOnly: !!validSupplierProducts.length || isReadOnly || isCostInputDisabled,
      tooltipText: validSupplierProducts.length
        ? tooltipTexts.INGREDIENT_COST_AUTOMATIC_CALCULATION_SUCCESS
        : tooltipTexts.INGREDIENT_COST_AUTOMATIC_CALCULATION_FAILURE,
      onChange: (event) => handleOnChangeIngredient(ingredient, onIngredientChange, event, 'cost'),
    };
  },
};

export const IngredientAllergensInput = {
  keyProperty: 'allergens',
  component: AllergensInput,
  props: ({ ingredient, onIngredientChange, availableAllergens, isReadOnly }) => ({
    availableAllergens,
    ingredientAllergens: ingredient.allergens,
    isReadOnly,
    onChange: (event) =>
      handleOnChangeIngredient(ingredient, onIngredientChange, event, 'allergens'),
  }),
};

export const IngredientIsStrategicInput = {
  keyProperty: 'isStrategic',
  component: IsStrategicInput,
  props: ({ ingredient, onIngredientChange, isReadOnly }) => ({
    id: 'is-strategic',
    checked: ingredient.isStrategic,
    isReadOnly,
    handleClick: () =>
      handleOnChangeIngredient(
        ingredient,
        onIngredientChange,
        !ingredient.isStrategic,
        'isStrategic',
      ),
  }),
};

export const getIngredientUnit = (unit) => {
  if (unit === 'unit') {
    return 'unité';
  } else if (unit === 'g') {
    return 'kg';
  } else if (unit === 'mL') {
    return 'L';
  }
  return unit;
};

/*************************/
/* Input Change Handlers */
/*************************/

/**
 * Generic method that is in charge of updating the ingredient from the inputs that the user changes
 *
 * @param {Ingredient} ingredient           - The original ingredient when the user performs the change in an input
 * @param {Function} onIngredientChange     - Method used to set the local state of the ingredient variable
 * @param {Event} event                     - The event emitted by the input OnChange method
 * @param {String} fieldName                - The field name on which apply the change
 */
export function handleOnChangeIngredient(
  ingredient,
  onIngredientChange,
  event,
  fieldName,
  selectedItemId = null,
) {
  let updatedFieldValue =
    fieldName === 'unit' || fieldName === 'allergens' || fieldName === 'isStrategic'
      ? event
      : event.target.value;

  const updatedIngredient = { ...ingredient, [fieldName]: updatedFieldValue };

  if (fieldName === 'categoryId' && !!selectedItemId) {
    updatedIngredient.category = event.target.value;
    updatedIngredient.categoryId = selectedItemId;
  }

  onIngredientChange({ ...updatedIngredient });
}

export const handleCategoryCreation = (propertyName, props) => {
  const { setItemDropdownPropertyName, setOpenModalAddNewItemDropdown } = props;
  setItemDropdownPropertyName(propertyName);

  setOpenModalAddNewItemDropdown(true);
};

export default {
  // Inputs
  IngredientNameInput,
  IngredientUnityInput,
  // Handlers methods
  handleOnChangeIngredient,
};
