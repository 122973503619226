import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { ingredient as ingredientService } from '@services/ingredient';
import { stock as stockService } from '@services/stock';
import centralService from '@services/central';

import {
  CentralKitchenIngredientGraphTooltip,
  CentralKitchenRecipeGraphTooltip,
} from '@stocks/components/GraphStock/CentralKitchenGraphTooltip';
import { DeepsightTooltip } from '@stocks/components/GraphStock/GraphTooltip';
import { PAST_STOCKS_TYPE } from '@stocks/utils/constants';

import { formatDataForStockGraph } from '../utils/commons';
import { GRAPH_TYPE } from '../utils/getGraphBasicConfiguration';

const NUMBER_OF_DAYS_TO_DISPLAY = 15;

const TOOLTIP_FOR_EACH_USE_CASE = {
  [PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS]: CentralKitchenIngredientGraphTooltip,
  [PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES]: CentralKitchenRecipeGraphTooltip,
  [PAST_STOCKS_TYPE.INGREDIENTS]: DeepsightTooltip,
};

const getSelectedDateWithStockConvention = (selectedStore, selectedDate) => {
  const selectedDateWithStockConvention =
    selectedStore.stockConvention === 'end'
      ? moment(selectedDate)
      : moment(selectedDate).add(1, 'day');

  return selectedDateWithStockConvention.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);
};

const getPastStocksOfEntity = async (
  entity,
  selectedDate,
  selectedStore,
  selectedMetric,
  currency,
  pastStockType,
  showErrorMessage,
) => {
  const formattedSelectedDate = moment(selectedDate).format(
    DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY,
  );

  /*
      so we fetch all the infos for 15 days in the past, we need the - 1 because if we have stocks for all
      the shown dates we will have 16 days of data instead of 15 but we still have 15 days of data if we don't
      have data for everyday
    */
  const selectedDateMinusFifteenDays = moment(formattedSelectedDate)
    .subtract(NUMBER_OF_DAYS_TO_DISPLAY - 1, 'd')
    .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  const formattedSelectedDateWithStockConvention = getSelectedDateWithStockConvention(
    selectedStore,
    selectedDate,
  );

  try {
    const pastStocks =
      pastStockType !== PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES
        ? await stockService.getStockGraph(
            selectedStore.id,
            entity.entityId,
            formattedSelectedDateWithStockConvention,
          )
        : await centralService.getRecipePastAnalyticsGraph(
            selectedStore.id,
            entity.entityId,
            formattedSelectedDateWithStockConvention,
          );

    const shouldOnlyDisplayRealStocks =
      pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES &&
      !get(pastStocks, 'entity.isProduced', false);

    const graph = formatDataForStockGraph(
      selectedMetric.key,
      formattedSelectedDateWithStockConvention,
      [
        PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS,
        PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES,
      ].includes(pastStockType)
        ? GRAPH_TYPE.PAST_CC
        : GRAPH_TYPE.PAST,
      pastStocks,
      entity.name,
      selectedDateMinusFifteenDays,
      selectedStore.stockConvention,
      currency,
      shouldOnlyDisplayRealStocks,
      pastStockType,
    );

    return { ...graph, shouldOnlyDisplayRealStocks };
  } catch {
    showErrorMessage(i18next.t('STOCKS.PAST_STOCKS.FETCH_ERROR'));
    return {
      data: [],
      configuration: {},
    };
  }
};

export const getPastStockData = async (
  entity,
  selectedDate,
  selectedStore,
  selectedMetric,
  currency,
  pastStockType,
  showErrorMessage,
) => {
  const pastStocksOfEntity = await getPastStocksOfEntity(
    entity,
    selectedDate,
    selectedStore,
    selectedMetric,
    currency,
    pastStockType,
    showErrorMessage,
  );

  let displayProductionLines;

  if (pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS) {
    const parents = await ingredientService.getIngredientParents(entity.entityId);

    const parentRecipes = parents.filter(({ isProduct }) => !isProduct);
    const parentRecipesIds = parentRecipes.map(({ id }) => id);

    const parentRecipesProductions =
      await centralService.getCentralKitchenRecipeProductionMappingsByEntityIds(parentRecipesIds);

    displayProductionLines = !!parentRecipesProductions.length;
  }

  if (pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_RECIPES) {
    const recipeProductions =
      await centralService.getCentralKitchenRecipeProductionMappingsByEntityId(entity.entityId);
    displayProductionLines = !!recipeProductions.length;
  }

  const pastStocksData = get(pastStocksOfEntity, 'data', []);

  const formattedPastStocksData = pastStocksData.map((pastStock) => {
    const isDateToday = moment(pastStock.x).isSame(moment(), 'day');

    let realStock =
      get(pastStock, 'y.stock[1].value', null) == null
        ? null
        : Math.round(pastStock.y.stock[1].value * 100) / 100; // pastStock.y.stock[1] matches real stock

    if (pastStocksOfEntity.shouldOnlyDisplayRealStocks) {
      realStock =
        get(pastStock, 'y.stock[0].value', null) == null
          ? null
          : Math.round(pastStock.y.stock[0].value * 100) / 100; // if shouldOnlyDisplayRealStocks then realstock is the first element
    }

    if (realStock < 0) {
      realStock = 0;
    }

    let theoricalStock =
      get(pastStock, 'y.stock[0].value', null) == null
        ? null
        : Math.round(pastStock.y.stock[0].value * 100) / 100; // pastStock.y.stock[0] matches theoretical stock

    if (theoricalStock < 0) {
      theoricalStock = 0;
    }

    const gapStock =
      theoricalStock !== null && realStock !== null
        ? Math.round((realStock - theoricalStock) * 100) / 100
        : null;

    const isPastDate = moment(pastStock.x).isBefore(moment(), 'day');

    const isCentralKitchenRecipePastStockType =
      pastStockType !== PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS;

    const isCentralKitchenIngredientPastStockType =
      pastStockType === PAST_STOCKS_TYPE.CENTRAL_KITCHEN_INGREDIENTS;

    const tooltip = TOOLTIP_FOR_EACH_USE_CASE[pastStockType]({
      graph: pastStock,
      isPastDate,
      theoricalStock,
      isDateToday,
      metric: selectedMetric.key,
      unit: pastStocksOfEntity.unit,
      gapStock,
      realStock,
      isCentralKitchenIngredientPastStockType,
      displayProductionLines,
      isCentralKitchenRecipePastStockType,
      currency,
      shouldOnlyDisplayRealStocks: pastStocksOfEntity.shouldOnlyDisplayRealStocks,
      displayRealStock: true,
      displayTheoricalStock: true,
      displayRealStockGap: true,
      displayRecipeStock: true,
    });

    return { ...pastStock, tooltip };
  });

  return { ...pastStocksOfEntity, data: formattedPastStocksData };
};
