import { find, get, groupBy, last, reduce, round, sortBy } from 'lodash';
import i18next from 'i18next';

import { REGEX_UUID } from './regex';
import { ZERO_AFTER_COMMA } from './regexps';

const DEFAULT_NONE = 'Aucune';
const REGEX_MATCH_DECIMAL_0 = /([.,]([0]{0,}))$/;

export const capitalizeString = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

export const flattenArray = (array) => array.reduce((acc, val) => acc.concat(val), []);

export const removeDuplicatesInArray = (array) => [...new Set(array)];

export function numberToFixed(value, nbDecimals = 2, defaultValue = '0', unit) {
  if (value == null) {
    return defaultValue;
  }

  const valueToProcess = typeof value === 'string' ? Number(value) : value;

  let formattedValue = valueToProcess.toFixed(nbDecimals).replace(REGEX_MATCH_DECIMAL_0, '');

  if (!unit) {
    return parseFloat(formattedValue).toLocaleString('fr');
  }

  return `${parseFloat(formattedValue).toLocaleString('fr')}${unit}`;
}

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const convertToSpreadsheetCurrencyFormat = (number) => {
  if (number === null || number === undefined) {
    return '';
  }

  const value = number || 0;

  const formattedCurrency = value.toLocaleString('fr-FR', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formattedCurrency.replace('€', '').trim();
};

export const formatSirenValue = (string) => {
  if (!string) {
    return '';
  }

  return string
    .replace(/[^\dA-Z]/g, '')
    .replace(/(.{3})/g, '$1 ')
    .trim();
};

export const groupArrayByPropertyAndEmptyValue = (array, property) => {
  const arrayGroupedByProperty = groupBy(array, property);

  return Object.keys(arrayGroupedByProperty).reduce(
    (acc, key) => {
      const values = arrayGroupedByProperty[key];

      if (!key || key === '' || key === DEFAULT_NONE || key == null || key === 'null') {
        if (!acc[DEFAULT_NONE]) {
          acc[DEFAULT_NONE] = {
            associatedItems: values,
          };

          return acc;
        }

        acc[DEFAULT_NONE].associatedItems = acc[DEFAULT_NONE].associatedItems.concat(values);

        return acc;
      }

      if (!acc[key]) {
        acc[key] = {
          associatedItems: values,
        };
      }

      return acc;
    },
    { [DEFAULT_NONE]: { associatedItems: [] } },
  );
};

export const stringValueReplacer = (string, charToReplace, replacer) => {
  if (typeof string !== 'string') {
    return string;
  }

  return string.replace(charToReplace, replacer);
};

export const formatTextNumberInputValue = (value, defaultValue = '') => {
  if (value == null) {
    return null;
  }

  const formattedValue = value.toString().replace(',', '.');

  // Since this is used in a text typed input, it is required to early return when some characters are typed
  // because parseFloat would remove them
  if (['.', '0'].includes(last(formattedValue)) || ZERO_AFTER_COMMA.test(formattedValue)) {
    return formattedValue;
  }

  const parsedValue = parseFloat(value, 10);

  const updatedValue = !isNaN(parsedValue) ? parsedValue : defaultValue;

  return updatedValue;
};

export const sortPackagings = (packagings) => {
  const masterSupplierProductPackaging = find(
    packagings,
    (supplierProductPackaging) =>
      supplierProductPackaging.parentSupplierProductPackagingId === null &&
      supplierProductPackaging.masterSupplierProductPackagingId === null,
  );

  if (!masterSupplierProductPackaging) {
    return packagings;
  }

  const initialValue = [masterSupplierProductPackaging];

  let lastSPPs = initialValue;

  const sortedPackagingsByParents = reduce(
    packagings,
    (result) => {
      const nextChilds = [];

      packagings.forEach((packaging) => {
        lastSPPs.forEach((lastSPP) => {
          if (packaging.parentSupplierProductPackagingId === lastSPP.id) {
            nextChilds.push(packaging);
          }
        });
      });

      if (!nextChilds) {
        return result;
      }

      const sortedNextChilds = sortBy(nextChilds, 'quantity');
      lastSPPs = sortedNextChilds;

      result.push(...sortedNextChilds);

      return result;
    },
    initialValue,
  );

  return sortedPackagingsByParents;
};

const MATH_POW_ABBREVIATION = {
  [0]: '',
  [1]: 'K',
  [2]: 'M',
  [3]: 'B',
};

export const reduceCurrencyNumber = (number, nbDecimalNumber, iteration = 0) => {
  // We stop at 3 because people don't express values in units larger than a billion.
  if (Math.abs(number) < 1000 || iteration === 3) {
    return { value: round(number, nbDecimalNumber), iteration };
  }

  return reduceCurrencyNumber(number / 1000, nbDecimalNumber, iteration + 1);
};

export const formatShortenCurrencyNumber = (number, nbDecimalNumber = 2) => {
  const reducedNumber = reduceCurrencyNumber(number, nbDecimalNumber);

  return `${
    Number.isInteger(reducedNumber.value)
      ? reducedNumber.value
      : reducedNumber.value.toFixed(nbDecimalNumber)
  } ${MATH_POW_ABBREVIATION[reducedNumber.iteration]}`;
};

/**
 * When a dropdown contains both values from existing data and new values created on the fly,
 * differentiating between them is necessary.
 * This is done by only keeping { name } in the case of the latter
 *
 * @param {Array} array - The array of items to be formatted.
 * @return {Array} The formatted array of items.
 */
export const formatNewItemsOfDropdownSelection = (array) =>
  array.map((item) => {
    if (!REGEX_UUID.test(item.id)) {
      return {
        name: item.name,
      };
    }
    return item;
  });

/**
 * Map data to column settings using the keyName and apply transform function
 *
 * @param {{keyName: string; transform: Function?}[]} columnSettings - Column settings
 * @param {object[]} data - Data to format
 * @param {string} keyString - The string value representing the key in column settings
 *
 * @returns {object[]} Formatted data ready for export
 */
export const mapDataForExport = (columnSettings, data, keyString = 'keyName') =>
  data.map((item) =>
    columnSettings.map((setting) => {
      const key = setting[keyString];
      const transformFn = setting.transform || ((value) => value);
      return transformFn(get(item, key, ''));
    }),
  );

export const sortCategoryAndSubCategoryDropdown = (categories) => {
  const { noneCategories, otherCategories } = categories.reduce(
    (acc, category) => {
      if (!category.name || category.name === i18next.t('GENERAL.NONE_VALUE')) {
        acc.noneCategories.push(category);
        return acc;
      }

      acc.otherCategories.push(category);
      return acc;
    },
    { noneCategories: [], otherCategories: [] },
  );

  return [...noneCategories, ...sortBy(otherCategories, 'name')];
};
