import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';

import { activityAnalytics as activityAnalyticsService } from '../../../services/activityAnalytics';

import Breadcrumb from '../../../commons/Breadcrumb';

import { InpulseFlopSales } from './ActivitySummaryModules/FlopSales';
import { InpulseStoreComparison } from './ActivitySummaryModules/StoresComparison';
import { InpulseTopSales } from './ActivitySummaryModules/TopSales';
import { InpulseTurnoverEvolutionContainer } from './ActivitySummaryModules/TurnoverEvolutionContainer';

import { getBreakagePropertyName } from '../../../selectors/featureProps';
import { getSalesPointStores } from '../../../selectors/stores';
import FranceFlag from '../../../assets/icons/icon-flag-France.png';
import i18next from 'i18next';
import InpulseCountry from './ActivitySummaryModules/Inpulse/Country';
import InpulseHello from './ActivitySummaryModules/Inpulse/Hello';
import InpulseSalesComparison from './ActivitySummaryModules/Inpulse/SalesComparison';
import styled from 'styled-components';

const PATHS_AND_PAGES = {
  '/home': 'summary',
};

export class ActivitySummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentWeekNumber: moment().week(),
      currentYear: moment().format('YYYY'),
      lastYear: moment().subtract(1, 'year').format('YYYY'),
      lastWeekNumber: moment().subtract(1, 'week').week(),
      lastWeekYear: moment().subtract(1, 'week').year(),
      topSalesData: [],
      flopSalesData: [],
    };
  }

  getSelectedPageFromPath(path) {
    if (!path || typeof path !== 'string') {
      return null;
    }

    const orderPaths = Object.keys(PATHS_AND_PAGES);

    const matchingPath = orderPaths.find((item) => path.startsWith(item));

    if (!matchingPath) {
      return null;
    }

    return PATHS_AND_PAGES[matchingPath];
  }

  getStoreIds = () => this.props.stores.map((store) => store.id);

  componentDidMount() {
    const { breakagePropertyName } = this.props;

    if (!this.props.disableTopFlop && this.props.stores.length > 0) {
      this.setState({
        isLoadingTopSalesData: true,
        isLoadingFlopSalesData: true,
      });

      activityAnalyticsService
        .getTopSalesOfStores(this.getStoreIds(), moment().startOf('week').format('YYYY-MM-DD'))
        .then((topSalesData) =>
          this.setState({ isLoadingTopSalesData: false, topSalesData: topSalesData || [] }),
        )
        .catch(() => {
          this.setState({ isLoadingTopSalesData: false, topSalesData: [] });
        });

      activityAnalyticsService
        .getFlopSalesOfStores(
          this.getStoreIds(),
          moment().startOf('week').format('YYYY-MM-DD'),
          breakagePropertyName,
        )
        .then((flopSalesData) =>
          this.setState({ isLoadingFlopSalesData: false, flopSalesData: flopSalesData || [] }),
        )
        .catch(() => {
          this.setState({ isLoadingFlopSalesData: false, flopSalesData: [] });
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { breakagePropertyName } = this.props;

    if (
      prevProps.stores !== this.props.stores &&
      !this.props.disableTopFlop &&
      this.props.stores.length > 0
    ) {
      this.setState({
        isLoadingTopSalesData: true,
        isLoadingFlopSalesData: true,
      });

      activityAnalyticsService
        .getTopSalesOfStores(this.getStoreIds(), moment().startOf('week').format('YYYY-MM-DD'))
        .then((topSalesData) =>
          this.setState({ isLoadingTopSalesData: false, topSalesData: topSalesData || [] }),
        )
        .catch(() => {
          this.setState({ isLoadingTopSalesData: false, topSalesData: [] });
        });
      activityAnalyticsService
        .getFlopSalesOfStores(
          this.getStoreIds(),
          moment().startOf('week').format('YYYY-MM-DD'),
          breakagePropertyName,
        )
        .then((flopSalesData) =>
          this.setState({ isLoadingFlopSalesData: false, flopSalesData: flopSalesData || [] }),
        )
        .catch(() => {
          this.setState({ isLoadingFlopSalesData: false, flopSalesData: [] });
        });
    }

    if (prevProps.match !== this.props.match) {
      const path = _.get(this.props, 'match.path');

      this.setState({
        selectedPage: this.getSelectedPageFromPath(path),
      });
    }
  }

  /**********************
   ***** RENDERING ******
   **********************/

  render() {
    const {
      currentWeekNumber,
      currentYear,
      lastWeekNumber,
      lastWeekYear,
      topSalesData,
      flopSalesData,
      isLoadingTopSalesData,
      isLoadingFlopSalesData,
    } = this.state;

    const { user, disableTopFlop } = this.props;

    return (
      <>
        <Breadcrumb paths={[i18next.t('FEATURE.DASHBOARD.DASHBOARD')]} />
        <div className={`${this.props.className}`}>
          {/* {pageSelected === 'analytics' && (
          <ActivityReport stores={this.props.stores} activityColumns={this.props.activityColumns} />
        )} */}
          {
            <div className="activity-summary-container">
              {/* HEADERS */}
              <div className="activity-summary-headers">
                <InpulseHello user={user} />

                <InpulseCountry
                  countryFlag={FranceFlag}
                  countryLabel={'HOME.COUNTRY_FRANCE_LABEL'}
                />

                <InpulseSalesComparison currentYear={currentYear} stores={this.props.stores} />
              </div>
              {/* STORES & CA GRAPH */}
              <div className="activity-summary-middle-body">
                <>
                  <InpulseStoreComparison
                    lastWeekNumber={lastWeekNumber}
                    lastWeekYear={lastWeekYear}
                    stores={this.props.stores}
                  />
                  <InpulseTurnoverEvolutionContainer />
                </>
              </div>
              {/* TOP & FLOP SALES */}
              {!disableTopFlop ? (
                <div className="activity-summary-sales">
                  <InpulseTopSales
                    currentWeekNumber={currentWeekNumber}
                    isLoading={isLoadingTopSalesData}
                    lastWeekNumber={lastWeekNumber}
                    lastWeekYear={lastWeekYear}
                    topSalesData={topSalesData}
                  />
                  <InpulseFlopSales
                    currentWeekNumber={currentWeekNumber}
                    flopSalesData={flopSalesData}
                    isLoading={isLoadingFlopSalesData}
                    lastWeekNumber={lastWeekNumber}
                    lastWeekYear={lastWeekYear}
                  />
                </div>
              ) : undefined}
            </div>
          }
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  breakagePropertyName: getBreakagePropertyName(state.baseReducer.userRights),
});

const InpulseActivitySummary = styled(ActivitySummary)`
  position: relative;
  width: 100%;
  min-width: 1200px;
  height: 100%;
  background: ${(props) => props.theme.colors.greys.lighter};
  overflow: scroll;
  &.analytics {
    overflow: hidden;
  }

  .activity-summary-container {
    background: ${(props) => props.theme.colors.greys.lighter};
    height: 100%;
  }

  .activity-summary-headers {
    width: 100%;
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    height: 120px;
  }

  .activity-summary-middle-body,
  .activity-summary-sales {
    height: 356px;
    margin-top: 30px;
    display: flex;
  }

  .activity-summary-sales {
    height: 589px;
    justify-content: space-between;
  }
`;

export default connect(mapStateToProps, null)(withTranslation()(InpulseActivitySummary));
