import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { getTheme } from '@commons/utils/theme';
import { SimpleTableView, Tooltip } from '@commons/utils/styledLibraryComponents';
import { TooltipIcon } from '@commons/Metrics/styledComponents';
import DisplayNumber from '@commons/DisplayNumber';
import EmptyState from '@commons/EmptyState';
import ScrollableWhiteCard from '@commons/WhiteCard/ScrollableWhiteCard';

import { HeaderContainer, Title, TitleContainer } from './styledComponents';

const TABLE_COLUMNS = [
  { propertyKey: 'name', name: i18next.t('GENERAL.NAME') },
  { propertyKey: 'sku', name: i18next.t('GENERAL.SKU') },
  { propertyKey: 'quantity', name: i18next.t('GENERAL.QUANTITY'), type: 'numeric' },
  {
    propertyKey: 'turnoverExcludingTaxes',
    name: (
      <TitleContainer>
        {i18next.t('GENERAL.TURNOVER_EX_TAX')}{' '}
        <Tooltip
          renderTooltipTrigger={() => <TooltipIcon src={'/images/inpulse/info-dmgrey-small.svg'} />}
          text={i18next.t('ADMIN.MAPPING_MONITORING.TABLE_TURNOVER_COLUMN_TOOLTIP')}
          displayBigger
        />
      </TitleContainer>
    ),
    type: 'currency',
    render: (item) => <DisplayNumber number={item} displayCurrencyCode />,
  },
  { propertyKey: 'hidden', name: i18next.t('GENERAL.EXCLUDED') },
];

const TopCashierProducts = (props) => {
  const { isLoading, data } = props;

  const theme = getTheme();

  return (
    <ScrollableWhiteCard
      isLoading={isLoading}
      renderContent={
        !isEmpty(data) ? (
          <SimpleTableView columns={TABLE_COLUMNS} data={data} />
        ) : (
          <EmptyState
            backgroundColor={theme.colors.white}
            label={i18next.t('ADMIN.MAPPING_MONITORING.EMPTY_STATE_LABEL')}
          />
        )
      }
      renderHeader={
        <HeaderContainer>
          <TitleContainer>
            <Title>{i18next.t('ADMIN.MAPPING_MONITORING.TABLE_TITLE')}</Title>
            <Tooltip
              renderTooltipTrigger={() => (
                <TooltipIcon src={'/images/inpulse/info-dmgrey-small.svg'} />
              )}
              text={i18next.t('ADMIN.MAPPING_MONITORING.TABLE_TOOLTIP')}
              displayBigger
            />
          </TitleContainer>
        </HeaderContainer>
      }
    ></ScrollableWhiteCard>
  );
};

export default TopCashierProducts;
