import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { useState } from 'react';

// MUI
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

// Actions
import { loading, loadingSuccess } from '@actions/loading';
import { openSmallModal } from '@actions/modal';
import { showErrorMessage } from '@actions/messageconfirmation';

// Services
import productionPlanningService from '@services/productionPlanning';

// Common
import { Asterisk } from '@commons/Asterisk';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { GenericCancelButton, GenericDownloadButton } from '@commons/Buttons';
import { getUserTimezone } from '@commons/utils/date';
import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

// Design
import {
  Container,
  ContentContainer,
  FooterContainer,
  HeaderContainer,
  Section,
  Title,
} from './styledComponents';

import { downloadFileFromBase64 } from '@admin/products/recipes/utils/downloadRecipe';

const USER_TIMEZONE = getUserTimezone();
const TODAY = moment.tz(USER_TIMEZONE);

/**
 * @param {{
 *  categories: string[]
 *  onClose: () => void
 *  startDate: Date
 *  endDate: Date
 *  storeId: string
 * }} props
 *
 * @return {JSX.Element}
 */
const PdfConfig = (props) => {
  const {
    // From redux
    user,
    showErrorMessage,
    pageLoading,
    pageLoaded,

    categories,
    endDate,
    startDate,
    storeId,
    onClose,
  } = props;

  const userLanguageCode = get(user, 'lnkLanguageAccountrel.code', 'fr');

  const [selectedDate, setSelectedDate] = useState(TODAY);

  // Checkboxes states
  const [includeProducts, setIncludeProducts] = useState(false);
  const [includeProductsComposition, setIncludeProductsComposition] = useState(false);
  const [includeRecipes, setIncludeRecipes] = useState(false);
  const [includeRecipesComposition, setIncludeRecipesComposition] = useState(false);
  const [includeIngredients, setIncludeIngredients] = useState(false);

  // Checkboxes handlers
  const handleProductsCheck = (event) => {
    setIncludeProducts(event.target.checked);
    if (!event.target.checked) {
      setIncludeProductsComposition(false);
    }
  };

  const handleProductsCompositionCheck = (event) => {
    setIncludeProductsComposition(event.target.checked);
  };

  const handleRecipesCheck = (event) => {
    setIncludeRecipes(event.target.checked);
    if (!event.target.checked) {
      setIncludeRecipesComposition(false);
    }
  };

  const handleRecipesCompositionCheck = (event) => {
    setIncludeRecipesComposition(event.target.checked);
  };

  const handleIngredientsCheck = (event) => {
    setIncludeIngredients(event.target.checked);
  };

  const generateProductionPlanningPdf = async () => {
    const payload = {
      startDate: moment(startDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      endDate: moment(endDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      categoryIds: categories.map(({ id }) => id),
      productionDate: moment(selectedDate).format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      planningConfiguration: {
        includeProducts,
        includeRecipes,
        includeIngredients,
        includeProductsComposition,
        includeRecipesComposition,
      },
    };

    pageLoading();

    try {
      const { fileName, rawBase64File } =
        await productionPlanningService.downloadProductionPlanningPdf(storeId, payload);

      downloadFileFromBase64(rawBase64File, fileName);
    } catch {
      showErrorMessage(i18next.t('FORECAST.POS_PRODUCTION_PLANNING.PDF_GENERATION_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const resetStateAndClose = () => {
    setIncludeProducts(false);
    setIncludeProductsComposition(false);
    setIncludeRecipes(false);
    setIncludeRecipesComposition(false);
    setIncludeIngredients(false);
    onClose();
  };

  return (
    <Container>
      <HeaderContainer>
        <Title>{i18next.t('FORECAST.POS_PRODUCTION_PLANNING.PDF_CONFIG_TITLE')}</Title>
      </HeaderContainer>

      <ContentContainer>
        <Section>
          <Text color={ENUM_COLORS.IP_BLACK_4}>
            {i18next.t('FORECAST.POS_PRODUCTION_PLANNING.PDF_PLANNED_SALES')} :
          </Text>
          <Text color={ENUM_COLORS.IP_BLACK_1} font={ENUM_FONTS.TEXT_MIDDLE_BOLD}>
            {i18next.t('GENERAL.FROM_TO', {
              from: moment(startDate).locale(userLanguageCode).format('L'),
              to: moment(endDate).locale(userLanguageCode).format('L'),
            })}
          </Text>
        </Section>
        <Section>
          <Text color={ENUM_COLORS.IP_BLACK_1}>
            <Asterisk />
            {i18next.t('FORECAST.POS_PRODUCTION_PLANNING.PDF_PRODUCTION_DATE')} :
          </Text>
          <SingleDatePicker
            customStyle={{ position: 'inherit' }}
            date={selectedDate}
            maxPastDate={TODAY}
            onDateChange={setSelectedDate}
          />
        </Section>
        <Section>
          <Text color={ENUM_COLORS.IP_BLACK_1}>
            <Asterisk />
            {i18next.t('FORECAST.POS_PRODUCTION_PLANNING.PDF_TYPE')} :
          </Text>
          <FormGroup sx={{ paddingLeft: '8px' }}>
            <FormControlLabel
              control={
                <Checkbox checked={includeProducts} size="large" onChange={handleProductsCheck} />
              }
              label={i18next.t('FORECAST.POS_PRODUCTION_PLANNING.PDF_PRODUCTS')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeProductsComposition}
                  disabled={!includeProducts}
                  size="large"
                  onChange={handleProductsCompositionCheck}
                />
              }
              label={i18next.t('FORECAST.POS_PRODUCTION_PLANNING.PDF_INCLUDE_COMPOSITION')}
              style={{ marginLeft: '24px' }}
            />
          </FormGroup>

          <FormGroup sx={{ paddingLeft: '8px' }}>
            <FormControlLabel
              control={
                <Checkbox checked={includeRecipes} size="large" onChange={handleRecipesCheck} />
              }
              label={i18next.t('FORECAST.POS_PRODUCTION_PLANNING.PDF_RECIPES')}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeRecipesComposition}
                  disabled={!includeRecipes}
                  size="large"
                  onChange={handleRecipesCompositionCheck}
                />
              }
              label={i18next.t('FORECAST.POS_PRODUCTION_PLANNING.PDF_INCLUDE_COMPOSITION')}
              style={{ marginLeft: '24px' }}
            />
          </FormGroup>

          <FormGroup sx={{ paddingLeft: '8px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeIngredients}
                  size="large"
                  onChange={handleIngredientsCheck}
                />
              }
              label={i18next.t('FORECAST.POS_PRODUCTION_PLANNING.PDF_INGREDIENTS')}
            />
          </FormGroup>
        </Section>
      </ContentContainer>

      <FooterContainer>
        <GenericCancelButton handleClick={resetStateAndClose} />
        <GenericDownloadButton
          handleClick={generateProductionPlanningPdf}
          isDisabled={!includeProducts && !includeRecipes && !includeIngredients}
        />
      </FooterContainer>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openSmallModal(params));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PdfConfig);
