import { cloneDeep, get, reduce, uniq, slice, keyBy } from 'lodash';
import i18next from 'i18next';

import orderService from '@services/order';

const MAX_ENTITIES_PER_API_CALL = 50;

const getEntitiesRecommandations = async (props) => {
  const {
    categorizedProducts,
    storeId,
    endDate,
    startOrderDate,
    endOrderDate,
    setProductsRecommendations,
    showMessage,
    supplierInfos,
    resetStockEveryDayForCentralKitchen,
  } = props;

  const productRecommendations = cloneDeep(categorizedProducts);
  const formattedProducts = Object.values(categorizedProducts);

  // For some customers (Krispy Kreme), when ordering from a Central, they don't want to use the order recos but the theoretical consumption
  // (as the DLC is 1 day, they never have any stock).
  const shouldUseConsumption = resetStockEveryDayForCentralKitchen && supplierInfos.isKitchen;

  const entityIds = uniq(
    reduce(
      formattedProducts,
      (acc, category) => {
        category.products.map((product) => {
          if (get(product, '[0].entityId')) {
            acc.push(product[0].entityId);
          }
        });

        return acc;
      },
      [],
    ),
  );

  try {
    const getRecommandationsPromises = [];
    const numberOfCalls = Math.ceil(entityIds.length / MAX_ENTITIES_PER_API_CALL);

    for (let index = 0; index < numberOfCalls; index++) {
      const entitiesToSend = slice(
        entityIds,
        index * MAX_ENTITIES_PER_API_CALL,
        index * MAX_ENTITIES_PER_API_CALL + MAX_ENTITIES_PER_API_CALL,
      );

      getRecommandationsPromises.push(
        orderService.getRecommandationsByBatch(
          storeId,
          entitiesToSend,
          endDate,
          startOrderDate,
          endOrderDate,
        ),
      );
    }

    const fetchedRecommandations = await Promise.all(getRecommandationsPromises);

    const entityStockEvent = fetchedRecommandations.flat();

    const entityStockEventByIds = keyBy(entityStockEvent, 'entity.id');

    for (const category in categorizedProducts) {
      categorizedProducts[category].products.map((product, index) => {
        product.forEach((sp, subIndex) => {
          if (!!get(sp, 'entityId')) {
            const forecastConsumption =
              entityStockEventByIds[product[subIndex].entityId].consumption;

            // Check if we have product sales for the entity on the start order date
            const entityProductSales =
              entityStockEventByIds[product[subIndex].entityId].productSalesOfEntityKeyByDate;

            const entityProductSaleTotUnitOfStartOrderDate = get(
              entityProductSales,
              [startOrderDate, 'totUnit'],
              null,
            );

            // If there is consumption on the start order date, we display it, otherwise we display the forecasted consumption
            const displayedConsumption =
              entityProductSaleTotUnitOfStartOrderDate !== null
                ? entityProductSaleTotUnitOfStartOrderDate
                : forecastConsumption;

            productRecommendations[category].products[index][subIndex].entityStockEvent = {
              ...entityStockEventByIds[product[subIndex].entityId],
              consumption: displayedConsumption,
              recommandation: shouldUseConsumption
                ? entityStockEventByIds[product[subIndex].entityId].consumption
                : entityStockEventByIds[product[subIndex].entityId].recommandation,
            };
            productRecommendations[category].products[index][subIndex].fillWithReco = false;
          }
        });
      });
    }

    setProductsRecommendations(productRecommendations);
  } catch (err) {
    showMessage(i18next.t('ORDERS.ORDERS.FORM_RECOMMENDATIONS_FETCH_ERROR'), 'error');
  }
};

export default getEntitiesRecommandations;
