import i18next from 'i18next';
import React from 'react';

import { DeepsightLabelSpot } from '@commons/DeepsightComponents';
import { Dropdown, INPUT_WIDTH, Label, ToggleSwitch } from '@commons/utils/styledLibraryComponents';
import { ENUM_FONTS } from '@commons/Text';
import { sortCategoryAndSubCategoryDropdown } from '@commons/utils/format';
import DisplayNumber from '@commons/DisplayNumber';

import { Input } from '@lib/inpulse/Input';

import theme from '@theme';

import { DYNAMIC_PROPERTIES } from '@admin/commons/dynamicModalProperties';
import { SMALL_UNITS_DROPDOWN_ITEMS } from '@admin/utils/DropdownItems';

import {
  CostDetail,
  CostDetailSection,
  RecipeAllergenDropdown,
  ToggleContainer,
  TogglesVerticalList,
} from './inputs.style';

export class AllergensInput extends React.PureComponent {
  render() {
    const { recipeAllergens } = this.props;

    return (
      <RecipeAllergenDropdown>
        <label className="text-input-form-label" style={{ display: 'flex' }}>
          {`${i18next.t('ADMIN.INGREDIENTS.LABEL_ALLERGENS')} :`}
          {recipeAllergens && recipeAllergens.length > 0 && (
            <DeepsightLabelSpot
              isTooltip={true}
              number={recipeAllergens.length}
              tooltipText={`${
                recipeAllergens.length > 1 ? 'Allergènes' : 'Allergène'
              } : ${recipeAllergens.map((allergen) => allergen.name).join(', ')}.`}
            />
          )}
        </label>
        <Dropdown
          isDisabled={true}
          isUniqueSelection={false}
          placeholder={i18next.t('GENERAL.SELECT_NONE_MASCULINE')}
          selectedItems={recipeAllergens}
          width={INPUT_WIDTH.FULL}
        />
      </RecipeAllergenDropdown>
    );
  }
}

export class CostDetailComponent extends React.Component {
  render() {
    const { recipeCost } = this.props;
    return (
      <CostDetailSection>
        <label className="text-input-form-label" style={{ display: 'flex' }}>
          {i18next.t('GENERAL.COST_EX_TAX')} :
        </label>
        <CostDetail>
          <DisplayNumber
            displayCurrencyCode={true}
            font={ENUM_FONTS.TEXT_SMALL}
            number={recipeCost && !isNaN(recipeCost) ? recipeCost : 0}
          />
        </CostDetail>
      </CostDetailSection>
    );
  }
}

export const RecipeTogglesList = ({
  recipe,
  onRecipeChange,
  isReadOnly,
  isKitchen,
  clientHasMultipleChannels,
}) => (
  <TogglesVerticalList>
    {/** Need to simulate empty label in order to always center toggles no matter display conditions */}
    <Label background={theme.colors.greys.lightest} color={theme.colors.greys.darkest} />
    {!isKitchen && clientHasMultipleChannels && (
      <ToggleContainer>
        <ToggleSwitch
          checked={recipe.hasMultipleChannels}
          disabled={isReadOnly}
          handleClick={() =>
            onRecipeChange({ ...recipe, hasMultipleChannels: !recipe.hasMultipleChannels })
          }
          id={'hasMultipleChannels'}
        />
        <Label background={theme.colors.greys.lightest} color={theme.colors.greys.darkest}>
          {i18next.t('ADMIN.RECIPES.HAS_MULTIPLE_CHANNEL_LABEL')}
        </Label>
      </ToggleContainer>
    )}
    <ToggleContainer>
      <ToggleSwitch
        checked={recipe.hasStock}
        disabled={isReadOnly}
        handleClick={() => onRecipeChange({ ...recipe, hasStock: !recipe.hasStock })}
        id={'hasStock'}
      />
      <Label background={theme.colors.greys.lightest} color={theme.colors.greys.darkest}>
        {i18next.t('ADMIN.RECIPES.HAS_STOCK_LABEL')}
      </Label>
    </ToggleContainer>
    {!isKitchen && (
      <ToggleContainer>
        <ToggleSwitch
          checked={recipe.batchProduction}
          disabled={isReadOnly}
          handleClick={() =>
            onRecipeChange({ ...recipe, batchProduction: !recipe.batchProduction })
          }
          id={'batchProduction'}
        />
        <Label background={theme.colors.greys.lightest} color={theme.colors.greys.darkest}>
          {i18next.t('ADMIN.RECIPES.HAS_BATCH_PRODUCTION')}
        </Label>
      </ToggleContainer>
    )}
  </TogglesVerticalList>
);

/*************************************/
/* Inputs Recipe Form Configuration */
/*************************************/

export const RecipeNameInput = {
  component: Input,
  props: ({ recipe, onRecipeChange, isReadOnly }) => ({
    value: recipe.name,
    label: `* ${i18next.t('ADMIN.PRODUCTS.RECIPE_ASSOCIATION_NAME')}`,
    type: 'text',
    onChange: (event) => handleOnChangeRecipe(recipe, onRecipeChange, event.target.value, 'name'),
    readOnly: isReadOnly,
  }),
};

export const handleEntityCategorySelection = (category, recipe, onRecipeChange) => {
  onRecipeChange({
    ...recipe,
    category: category.name,
    categoryId: category.id,
  });
};

export const RecipeCategoryInput = {
  keyProperty: 'category',
  component: Dropdown,
  props: ({ recipe, onRecipeChange, recipeCategories, isReadOnly, openAddNewItemModal }) => {
    const selectedCategory = recipeCategories.find(({ id }) => id === recipe.categoryId);

    return {
      width: INPUT_WIDTH.FULL,
      items: recipeCategories,
      selectedItem: selectedCategory,
      sortBy: (items) => sortCategoryAndSubCategoryDropdown(items),
      labelStyle: { font: theme.fonts.textSmall },
      placeholder: i18next.t('GENERAL.CHOOSE_PLACEHOLDER'),
      isRequired: true,
      isDisabled: isReadOnly,
      onSelectionChange: (selectedItem) => {
        handleEntityCategorySelection(selectedItem, recipe, onRecipeChange);
      },
      button: {
        id: 1,
        text: i18next.t('GENERAL.LIST_CREATE_CATEGORY'),
        handleClick: () => openAddNewItemModal(DYNAMIC_PROPERTIES.CATEGORY),
        iconSrc: '/images/inpulse/add-black-small.svg',
      },
      label: `${i18next.t('ADMIN.INGREDIENTS.LABEL_CATEGORY')} :`,
    };
  },
};

export const RecipeUnitSelector = (props) => {
  const { recipe, onRecipeChange, isReadOnly } = props;

  const selectedUnit = SMALL_UNITS_DROPDOWN_ITEMS().find((unit) => unit.id === recipe.unit);

  return (
    <Dropdown
      customStyle={{ marginBottom: '28px' }} // not fitting the 8px rule to match legacy page style
      isDisabled={isReadOnly}
      isLabelTextSmall={true}
      isRequired={true}
      items={SMALL_UNITS_DROPDOWN_ITEMS()}
      label={`${i18next.t('GENERAL.UNIT')} :`}
      selectedItem={selectedUnit}
      width={INPUT_WIDTH.FULL}
      onSelectionChange={(selectedItem) => {
        handleOnChangeRecipe(recipe, onRecipeChange, selectedItem.id, 'unit');
      }}
    />
  );
};

export const RecipeUnitInput = {
  keyProperty: 'unit',
  component: RecipeUnitSelector,
  props: ({ recipe, onRecipeChange, isReadOnly }) => ({
    recipe,
    onRecipeChange: onRecipeChange,
    isReadOnly: isReadOnly || recipe.id,
  }),
};

export const RecipeCostDetail = {
  keyProperty: 'cost',
  component: CostDetailComponent,
  props: ({ recipeCost }) => ({
    recipeCost,
  }),
};

export const RecipeToggles = {
  keyProperty: 'boolProperties',
  component: RecipeTogglesList,
  props: ({ recipe, onRecipeChange, isReadOnly, isKitchen, clientHasMultipleChannels }) => ({
    recipe,
    onRecipeChange,
    isReadOnly,
    isKitchen,
    clientHasMultipleChannels,
  }),
};

export const RecipeQuantityInput = {
  keyProperty: 'value',
  component: Input,
  props: ({ recipe, onRecipeChange, isReadOnly }) => ({
    type: 'number',
    label: `* ${i18next.t('ADMIN.PRODUCTS.RECIPE_ASSOCIATION_QUANTITY')}`,
    readOnly: isReadOnly,
    onChange: (event) =>
      handleOnChangeRecipe(recipe, onRecipeChange, parseInt(event.target.value), 'value'),
    value: !isNaN(recipe.value) ? recipe.value : '',
  }),
};

export const RecipeAllergensInput = {
  keyProperty: 'allergens',
  component: AllergensInput,
  props: ({ allergens }) => ({
    recipeAllergens: allergens || [],
    onChange: () => {},
  }),
};

export const RecipeBrandsSelector = (props) => {
  const { recipe, onRecipeChange, isReadOnly, brands } = props;

  return (
    <Dropdown
      customStyle={{ marginBottom: '28px' }} // not fitting the 8px rule to match legacy page style
      isDisabled={isReadOnly}
      isLabelTextSmall={true}
      isUniqueSelection={false}
      items={brands}
      label={`${i18next.t('GENERAL.BRAND_PLURAL')} :`}
      selectedItems={recipe.brands}
      width={INPUT_WIDTH.FULL}
      onSelectionChange={(selectedItems) => {
        handleOnChangeRecipe(recipe, onRecipeChange, selectedItems, 'brands');
      }}
    />
  );
};

export const RecipeBrandsInput = {
  keyProperty: 'brands',
  component: RecipeBrandsSelector,
  props: ({ recipe, onRecipeChange, isReadOnly, brands }) => ({
    recipe,
    onRecipeChange: onRecipeChange,
    isReadOnly,
    brands,
  }),
};

export const RecipeInventoryTemplatesSelector = (props) => {
  const { recipe, onRecipeChange, isReadOnly, inventoryListTemplates, openAddNewItemModal } = props;

  return (
    <Dropdown
      button={{
        id: 1,
        text: i18next.t('GENERAL.LIST_CREATE_INVENTORY_TEMPLATE'),
        handleClick: () => openAddNewItemModal(DYNAMIC_PROPERTIES.INVENTORY_LIST_TEMPLATES),
        iconSrc: '/images/inpulse/add-black-small.svg',
      }}
      customStyle={{ marginBottom: '28px' }} // not fitting the 8px rule to match legacy page style
      isDisabled={isReadOnly}
      isLabelTextSmall={true}
      isUniqueSelection={false}
      items={inventoryListTemplates}
      label={`${i18next.t('GENERAL.INVENTORY_TEMPLATES')} :`}
      selectedItems={recipe.inventoryListTemplates}
      width={INPUT_WIDTH.FULL}
      onSelectionChange={(selectedItems) => {
        handleOnChangeRecipe(recipe, onRecipeChange, selectedItems, 'inventoryListTemplates');
      }}
    />
  );
};

export const RecipeInventoryTemplatesInput = {
  keyProperty: 'inventoryListTemplates',
  component: RecipeInventoryTemplatesSelector,
  props: ({ recipe, onRecipeChange, isReadOnly, inventoryListTemplates, openAddNewItemModal }) => ({
    recipe,
    onRecipeChange: onRecipeChange,
    isReadOnly,
    inventoryListTemplates,
    openAddNewItemModal,
  }),
};

export const getRecipeUnit = (unit) => {
  if (unit === 'unit') {
    return 'unité';
  } else if (unit === 'g') {
    return 'kg';
  } else if (unit === 'mL') {
    return 'L';
  }
  return unit;
};

/*************************/
/* Input Change Handlers */
/*************************/

/**
 * Generic method that is in charge of updating the recipe from the inputs that the user changes
 *
 * @param {Recipe} recipe           - The original recipe when the user performs the change in an input
 * @param {Function} onRecipeChange - Method used to set the local state of the recipe variable
 * @param {Event} event             - The event emitted by the input OnChange method
 * @param {String} fieldName        - The field name on which apply the change
 */
export function handleOnChangeRecipe(recipe, onRecipeChange, value, fieldName) {
  const updatedRecipe = { ...recipe, [fieldName]: value };

  onRecipeChange({ ...updatedRecipe });
}

export default {
  // Inputs
  RecipeNameInput,
  RecipeUnitInput,
  // Handlers methods
  handleOnChangeRecipe,
};
