import i18next from 'i18next';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import { Checkbox } from '@commons/utils/styledLibraryComponents';
import { Tags } from '@commons/Tags';
import Text from '@commons/Text';

import {
  NextAvailalitiesContainer,
  CalendarIcon,
  TabletAndCheckboxContainer,
  TabletAndTooltipContainer,
} from './styledComponents';

const RenderCheckBox = ({ item, propertyKey, onChange, isDisabled }) => {
  if (!item) {
    return '';
  }

  const value = item[propertyKey];

  return (
    <Checkbox
      handleClick={() => onChange([item.id], { [propertyKey]: !value })}
      isChecked={value}
      isDisabled={isDisabled}
      shape="square"
    />
  );
};

const RenderTabletWithCheckBox = ({ item, propertyKey, onChange, isDisabled }) => {
  const tooltipId = `info-tooltip-${item.id}`;

  let tooltipText = '';

  if (!!item.nextAvailabilityDate && typeof item.nextAvailabilityValue === 'boolean') {
    tooltipText = item.nextAvailabilityValue
      ? i18next.t('ADMIN.SUPPLIER_PRODUCTS.NEXT_STORE_AVAILABILITY_AVAILABLE_FOR_STORE_AND_DATE', {
          storeName: item.storeName,
          date: item.nextAvailabilityDate,
        })
      : i18next.t(
          'ADMIN.SUPPLIER_PRODUCTS.NEXT_STORE_AVAILABILITY_UNAVAILABLE_FOR_STORE_AND_DATE',
          { storeName: item.storeName, date: item.nextAvailabilityDate },
        );
  }

  return (
    <TabletAndCheckboxContainer>
      <RenderCheckBox
        isDisabled={isDisabled}
        item={item}
        propertyKey={propertyKey}
        onChange={onChange}
      />
      {item.nextAvailabilityDate && item.nextAvailabilityDate !== item.available && (
        <TabletAndTooltipContainer>
          <NextAvailalitiesContainer data-for={tooltipId} data-tip={tooltipText}>
            <CalendarIcon src={'/images/inpulse/calendar-white-small.svg'} />
          </NextAvailalitiesContainer>
          <ReactTooltip
            backgroundColor={'black'}
            className={'tablet-tooltip'}
            effect={'solid'}
            id={tooltipId}
          />
        </TabletAndTooltipContainer>
      )}
    </TabletAndCheckboxContainer>
  );
};

const getColumns = ({ onChange, isCreation, canEditSupplierProductFromDetailView }) => [
  {
    id: 'store-name',
    propertyKey: 'storeName',
    name: i18next.t('GENERAL.NAME'),
    render: (it) => <Text>{it}</Text>,
  },
  {
    id: 'is-central-kitchen-store',
    propertyKey: 'isCentralKitchenStore',
    name: i18next.t('GENERAL.STORE_TYPE'),
    render: (it) => <Text>{i18next.t(it ? 'GENERAL.CENTRAL' : 'GENERAL.SALE_POINT')}</Text>,
  },
  {
    id: 'groups',
    propertyKey: 'groups',
    name: i18next.t('GENERAL.GROUPS'),
    filterType: 'string',
    disableSort: true,
    render: (groups) => {
      if (!groups) {
        return <Tags items={[]} />;
      }

      return <Tags items={groups.map(({ name }) => name)} />;
    },
    transform: (groups) => groups.map(({ name }) => name).join(', '),
  },
  {
    id: 'supplier-profile-name',
    propertyKey: 'supplierProfileName',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_COLUMN_SUPPLIER_PROFILE'),
    render: (it) => <Text>{it}</Text>,
  },
  {
    id: 'available',
    excludeFromSearch: true,
    propertyKey: 'available',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_COLUMN_AVAILABLE'),
    render: (_, item) => (
      <RenderTabletWithCheckBox
        isDisabled={!isCreation && !canEditSupplierProductFromDetailView}
        item={item}
        propertyKey={'available'}
        onChange={onChange}
      />
    ),
  },
  {
    id: 'has-stock',
    excludeFromSearch: true,
    propertyKey: 'hasStock',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_COLUMN_HAS_STOCK'),
    render: (_, item) => (
      <RenderCheckBox
        isDisabled={!isCreation && !canEditSupplierProductFromDetailView}
        item={item}
        propertyKey={'hasStock'}
        onChange={onChange}
      />
    ),
  },
  {
    id: 'has-dlc',
    excludeFromSearch: true,
    propertyKey: 'hasDlc',
    name: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_COLUMN_HAS_DLC'),
    render: (_, item) => (
      <RenderCheckBox
        isDisabled={!isCreation && !canEditSupplierProductFromDetailView}
        item={item}
        propertyKey={'hasDlc'}
        onChange={onChange}
      />
    ),
  },
];

export default {
  getColumns,
};
