import React, { createContext, useEffect, useState } from 'react';

import { getListViewState } from './utils';

export const StorageAreaProviderContext = createContext({});

export const StorageAreaProvider = ({ children }) => {
  const listViewStates = getListViewState();

  const [selectedStores, setSelectedStores] = useState([]);

  useEffect(() => {
    listViewStates.setCurrentPage(1);
  }, [selectedStores]);

  return (
    <StorageAreaProviderContext.Provider
      value={{
        ...listViewStates,
        selectedStores,
        setSelectedStores,
      }}
    >
      {children}
    </StorageAreaProviderContext.Provider>
  );
};

export default StorageAreaProviderContext;
