import { keyBy } from 'lodash';
import i18next from 'i18next';
import React from 'react';

// Commons
import { Dropdown } from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_ADD_BUTTON,
  GENERIC_MODAL_BACK_BUTTON,
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_NEXT_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

// Design
import { ModalContainer, ModalDropdownItem } from '../styledComponents';

// Modals
import AssociateSupplierProductsModal from '@admin/inventories/utils/associateSupplierProductsModal';
import RecipesMappingsModal from '@admin/inventories/utils/recipeMappings.utils';

const StorageAreaToCopySelectionModalContent = (props) => {
  const { availableStorageAreas, storageAreaToCopy, setStorageAreaToCopy, stores } = props;

  const storesKeyById = keyBy(stores, 'id');

  const formattedAvailableStorageAreas = availableStorageAreas.map((storageArea) => ({
    ...storageArea,
    renderValue: () => (
      <ModalDropdownItem>
        <Text color={ENUM_COLORS.BLACK} font={ENUM_FONTS.TEXT_MIDDLE_NORMAL}>
          {storageArea.name}
        </Text>
        <Text color={ENUM_COLORS.DARK} font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}>
          {storesKeyById[storageArea.storeId].name}
        </Text>
      </ModalDropdownItem>
    ),
  }));

  return (
    <ModalContainer>
      <Dropdown
        customStyle={{ position: 'inherit' }}
        height={'64px'}
        items={formattedAvailableStorageAreas}
        label={i18next.t('GENERAL.STORAGE_AREA_SINGLE')}
        selectedItem={storageAreaToCopy}
        isRequired
        isUniqueSelection
        onSelectionChange={setStorageAreaToCopy}
      />
    </ModalContainer>
  );
};

export const getCopyParamModalConfig = ({
  handleParamsCopy,
  availableStorageAreas,
  storageAreaToCopy,
  setStorageAreaToCopy,
  stores,
}) => ({
  type: 'action',
  width: '542px',
  height: 'auto',
  icon: '/images/inpulse/gear-ipblack-small.svg',
  title: i18next.t('ADMIN.STORAGE_AREAS.COPY_STORAGE_AREA_PARAMS_MODAL_TITLE'),
  component: StorageAreaToCopySelectionModalContent,
  data: {
    availableStorageAreas,
    storageAreaToCopy,
    setStorageAreaToCopy,
    stores,
  },
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: () => handleParamsCopy(storageAreaToCopy),
    },
  ],
});

export const ADD_REFERENCE_TO_STORAGE_AREA_MODAL_STEPS = {
  ADD_SUPPLIER_PRODUCTS: 'addSupplierProducts',
  ADD_RECIPES: 'addRecipes',
};

const getAddReferenceStepModalActions = (
  currentStep,
  setCurrentStep,
  handleAddingReferences,
  resetModal,
) => {
  if (currentStep === ADD_REFERENCE_TO_STORAGE_AREA_MODAL_STEPS.ADD_SUPPLIER_PRODUCTS) {
    return [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_NEXT_BUTTON(),
        preventClosing: true,
        handleClick: () => {
          setCurrentStep(ADD_REFERENCE_TO_STORAGE_AREA_MODAL_STEPS.ADD_RECIPES);
        },
      },
    ];
  }

  return [
    {
      ...GENERIC_MODAL_BACK_BUTTON(),
      preventClosing: true,
      handleClick: () => {
        setCurrentStep(ADD_REFERENCE_TO_STORAGE_AREA_MODAL_STEPS.ADD_SUPPLIER_PRODUCTS);
      },
    },
    {
      ...GENERIC_MODAL_ADD_BUTTON(),
      handleClick: () => {
        handleAddingReferences();
        resetModal();
        setCurrentStep(ADD_REFERENCE_TO_STORAGE_AREA_MODAL_STEPS.ADD_SUPPLIER_PRODUCTS);
      },
    },
  ];
};

export const getAddReferenceStepModalConfig = ({
  // Handle steps
  currentStep,
  setCurrentStep,
  // SupplierProduct step
  hasLocalCatalogs,
  setSpToBeAdded,
  availableSPForAssociation,
  alreadySelectedSupplierProducts,
  // Recipe step
  clientId,
  hasMultipleBrands,
  setRecipesToBeAdded,
  storeIds,
  alreadyMappedRecipes,
  // Final callbacks
  handleAddingReferences,
  resetModal,
}) => {
  const params = {
    type: 'actionWhite',
    isFullscreen: true,
    icon: '/images/inpulse/add-black-small.svg',
    title:
      currentStep === ADD_REFERENCE_TO_STORAGE_AREA_MODAL_STEPS.ADD_SUPPLIER_PRODUCTS
        ? i18next.t('ADMIN.STORAGE_AREAS.ADD_SUPPLIER_PRODUCTS_STEP_MODAL_TITLE')
        : i18next.t('ADMIN.STORAGE_AREAS.ADD_RECIPE_STEP_MODAL_TITLE'),
    handleCloseCleanUp: resetModal,
    actions: getAddReferenceStepModalActions(
      currentStep,
      setCurrentStep,
      handleAddingReferences,
      resetModal,
    ),
    component:
      currentStep === ADD_REFERENCE_TO_STORAGE_AREA_MODAL_STEPS.ADD_SUPPLIER_PRODUCTS
        ? AssociateSupplierProductsModal
        : RecipesMappingsModal,
    data: {
      // SupplierProduct step
      hasLocalCatalogs,
      setSpToBeAdded,
      availableSPForAssociation,
      alreadySelectedSupplierProducts,
      // Recipe step
      clientId,
      hasMultipleBrands,
      setRecipesToBeAdded,
      storeIds,
      alreadyMappedRecipes,
    },
    componentOverflow: 'hidden',
    step: {
      total: 2,
      current:
        currentStep === ADD_REFERENCE_TO_STORAGE_AREA_MODAL_STEPS.ADD_SUPPLIER_PRODUCTS ? 1 : 2,
    },
    customPadding: '24px 0 0 0',
  };

  return params;
};
export default {
  getCopyParamModalConfig,
  getAddReferenceStepModalConfig,
};
