import i18next from 'i18next';
import React from 'react';

// COMMONS
import { Checkbox } from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import Text from '@commons/Text';

// Styles
import { ModalContainer, ContentContainer, CheckBoxContainer } from '../styledComponents';

export const SelectAvailabilitiesStepContent = ({
  selectedAvailabilities,
  setSelectedAvailabilities,
}) => (
  <ModalContainer>
    <ContentContainer>
      <CheckBoxContainer>
        <Checkbox
          handleClick={() =>
            setSelectedAvailabilities({
              ...selectedAvailabilities,
              available: !selectedAvailabilities.available,
            })
          }
          isChecked={selectedAvailabilities.available}
          shape="square"
        />
        <Text>{i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_AVAILABLE_ORDER')}</Text>
      </CheckBoxContainer>
      <CheckBoxContainer>
        <Checkbox
          handleClick={() =>
            setSelectedAvailabilities({
              ...selectedAvailabilities,
              hasStock: !selectedAvailabilities.hasStock,
            })
          }
          isChecked={selectedAvailabilities.hasStock}
          shape="square"
        />
        <Text>{i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_AVAILABLE_FOR_STOCKS')}</Text>
      </CheckBoxContainer>
      <CheckBoxContainer>
        <Checkbox
          handleClick={() =>
            setSelectedAvailabilities({
              ...selectedAvailabilities,
              hasDlc: !selectedAvailabilities.hasDlc,
            })
          }
          isChecked={selectedAvailabilities.hasDlc}
          shape="square"
        />
        <Text>{i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_PROPERTIES_AVAILABLE_FOR_LOSSES')}</Text>
      </CheckBoxContainer>
    </ContentContainer>
  </ModalContainer>
);

export const getSspmAvailabilitiesModalConfig = ({
  selectedAvailabilities,
  setSelectedAvailabilities,
  sspmIds,
  handleSspmUpdate,
  handleSspmUpdateModalCloseCleanUp,
}) => ({
  actions: [
    GENERIC_MODAL_CANCEL_BUTTON(),
    {
      ...GENERIC_MODAL_SAVE_BUTTON(),
      handleClick: () => {
        handleSspmUpdate(sspmIds, selectedAvailabilities);
        handleSspmUpdateModalCloseCleanUp();
      },
      preventClosing: true,
    },
  ],
  title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.MODAL_TITLE_UPDATE_AVAILABILITIES'),
  icon: '/images/inpulse/pen-black-small.svg',
  type: 'action',
  handleCloseCleanUp: handleSspmUpdateModalCloseCleanUp,
  component: SelectAvailabilitiesStepContent,
  data: {
    selectedAvailabilities,
    setSelectedAvailabilities,
  },
  height: 'auto',
});
