import { cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import React from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import { showErrorMessage } from '@actions/messageconfirmation';

import PaginatedSupplierProductsDropdown from '@commons/PaginatedSupplierProductsDropdown';

import { supplierProduct as supplierProductService } from '@services/supplierProduct';

import { getDisableSupplierProductMappingModalParams } from './modalConfigurations';

import { ActionFrame } from './styledComponents';

/*************************/
/* Input Change Handlers */
/*************************/

export const handleSupplierProductSelection = async (props, selectedSupplierProduct) => {
  const {
    ingredient,
    onIngredientChange,
    openGenericModal,
    pageLoading,
    pageLoaded,
    showErrorMessage,
  } = props;

  if (!selectedSupplierProduct.entityId) {
    handleSupplierProductMapping(ingredient, onIngredientChange, selectedSupplierProduct);
    return;
  }

  pageLoading();

  try {
    const supplierProductWithIngredient = await supplierProductService.getSingleSupplierProduct(
      selectedSupplierProduct.id,
    );

    const params = getDisableSupplierProductMappingModalParams(
      selectedSupplierProduct,
      ingredient,
      supplierProductWithIngredient.entity,
      onIngredientChange,
      handleSupplierProductMapping,
    );

    openGenericModal(params);
  } catch (error) {
    showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.SINGLE_FETCH_ERROR'));
  } finally {
    pageLoaded();
  }
};

const handleSupplierProductMapping = (ingredient, onIngredientChange, selectedSupplierProduct) => {
  let supplierProductsOfIngredient = cloneDeep(ingredient.supplierProducts);

  let newSupplierProduct = Object.assign({}, selectedSupplierProduct);
  newSupplierProduct.price =
    newSupplierProduct.price == null ? '' : newSupplierProduct.price.toString();
  supplierProductsOfIngredient.push({ ...newSupplierProduct, isUsedForCost: true });

  onIngredientChange({
    ...ingredient,
    supplierProducts: supplierProductsOfIngredient,
  });
};

export function IngredientCompositionSearchBar(props) {
  const { ingredient, clientId, supplierIds, isDisabled, filterByUserCatalog } = props;

  const spIdsToExclude = ingredient.supplierProducts.map(({ id }) => id);

  return (
    <ActionFrame
      className={`search-bar ${ingredient.supplierProducts.length ? 'no-top-border' : ''}`}
    >
      <PaginatedSupplierProductsDropdown
        clientId={clientId}
        filterByUserCatalog={filterByUserCatalog}
        ingredientUnit={ingredient.unit}
        isDisabled={isDisabled}
        spIdsToExclude={spIdsToExclude}
        supplierIds={supplierIds}
        width={'350px'}
        displayCustomBackground
        displaySPConversions
        shouldDisplayCentralKitchenInfos
        onSelectionChange={async (value) => await handleSupplierProductSelection(props, value)}
      />
    </ActionFrame>
  );
}

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(null, mapDispatchToProps)(IngredientCompositionSearchBar);
