import { groupBy } from 'lodash';
import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

import {
  ALLERGENS_COLUMN,
  BRANDS_COLUMN,
  COMMON_COLUMNS,
  COMPONENT_ALLERGENS_COLUMN,
  COMPONENT_CATEGORY_COLUMN,
  COMPONENT_COST_COLUMN,
  COMPONENT_DELIVERY_COST_COLUMN,
  COMPONENT_NAME_COLUMN,
  COMPONENT_QUANTITY_COLUMN,
  COMPONENT_TYPE_COLUMN,
  COMPONENT_UNIT_COLUMN,
  COMPONENT_USED_FOR_DELIVERY_COLUMN,
  COMPONENT_USED_ON_SITE_COLUMN,
  DEFAULT_COST_COLUMN,
  DELIVERY_COST_COLUMN,
  INGREDIENT_ALLERGENS_COLUMN,
  INGREDIENT_CATEGORY_COLUMN,
  INGREDIENT_COST_COLUMN,
  INGREDIENT_DELIVERY_COST_COLUMN,
  INGREDIENT_DELIVERY_QUANTITY_COLUMN,
  INGREDIENT_NAME_COLUMN,
  INGREDIENT_ON_SITE_COST_COLUMN,
  INGREDIENT_ON_SITE_QUANTITY_COLUMN,
  INGREDIENT_QUANTITY_COLUMN,
  INGREDIENT_UNIT_COLUMN,
  ON_SITE_COST_COLUMN,
  RECIPE_DEFAULT_COST_COLUMN,
} from './exportRecipesColumns';

const aggregateIngredients = (ingredients, clientHasMultipleChannels) => {
  const groupedIngredientsByRecipeId = groupBy(
    ingredients,
    ({ componentId, recipeId }) => `${componentId}-${recipeId}`,
  );

  const aggregatedIngredients = Object.values(groupedIngredientsByRecipeId).map((ingredients) => {
    if (ingredients.length === 1) {
      return ingredients[0];
    }

    const {
      componentIsUsedOnSite,
      componentOnSiteQuantity,
      componentOnSiteCost,
      componentIsUsedOnDelivery,
      componentDeliveryQuantity,
      componentDeliveryCost,
    } = ingredients.reduce(
      (
        acc,
        {
          componentIsUsedOnSite,
          componentOnSiteCost,
          componentIsUsedOnDelivery,
          componentDeliveryCost,
          componentQuantity,
        },
      ) => {
        if (componentIsUsedOnSite) {
          acc.componentIsUsedOnSite = true;
          acc.componentOnSiteQuantity += componentQuantity;
          acc.componentOnSiteCost += componentOnSiteCost;
        }

        if (componentIsUsedOnDelivery) {
          acc.componentIsUsedOnDelivery = true;
          acc.componentDeliveryQuantity += componentQuantity;
          acc.componentDeliveryCost += componentDeliveryCost;
        }

        return acc;
      },
      {
        componentIsUsedOnSite: false,
        componentOnSiteQuantity: 0,
        componentOnSiteCost: 0,
        componentIsUsedOnDelivery: false,
        componentDeliveryQuantity: 0,
        componentDeliveryCost: 0,
      },
    );

    return {
      ...ingredients[0],
      componentIsUsedOnSite,
      componentOnSiteQuantity,
      componentOnSiteCost,
      componentIsUsedOnDelivery,
      componentDeliveryQuantity,
      componentDeliveryCost,
    };
  });

  const formattedIngredients = aggregatedIngredients.reduce((acc, ingredient) => {
    if (ingredient.isIngredient) {
      if (clientHasMultipleChannels) {
        ingredient.ingredientOnSiteQuantity = ingredient.componentIsUsedOnSite
          ? ingredient.componentOnSiteQuantity || ingredient.componentQuantity
          : 0;

        ingredient.componentOnSiteCost = ingredient.componentIsUsedOnSite
          ? ingredient.componentOnSiteCost
          : 0;

        ingredient.ingredientOnDeliveryQuantity = ingredient.componentIsUsedOnDelivery
          ? ingredient.componentDeliveryQuantity || ingredient.componentQuantity
          : 0;

        ingredient.componentDeliveryCost = ingredient.componentIsUsedOnDelivery
          ? ingredient.componentDeliveryCost
          : 0;
      }

      acc.push(ingredient);
    }

    return acc;
  }, []);

  return formattedIngredients;
};

const getRecipeCommonColumns = (isMainSheet) => [
  COMMON_COLUMNS.NAME(),
  COMMON_COLUMNS.STATUS(),
  COMMON_COLUMNS.CATEGORY(isMainSheet),
  COMMON_COLUMNS.ASSOCIATED_PRODUCT(),
  COMMON_COLUMNS.QUANTITY(isMainSheet),
  COMMON_COLUMNS.UNIT(isMainSheet),
];

const getMainSheetColumns = (
  clientHasMultipleChannels,
  clientHasMultipleBrands,
  isInCentralMode = false,
) => {
  const columns = [...getRecipeCommonColumns(true), ALLERGENS_COLUMN()];

  if (clientHasMultipleBrands) {
    columns.splice(2, 0, BRANDS_COLUMN());
  }

  const startIndexOfRecipeCost = clientHasMultipleBrands ? 7 : 6;

  if (clientHasMultipleChannels) {
    if (isInCentralMode) {
      columns.splice(startIndexOfRecipeCost, 0, ON_SITE_COST_COLUMN());
    } else {
      columns.splice(startIndexOfRecipeCost, 0, ON_SITE_COST_COLUMN(), DELIVERY_COST_COLUMN());
    }

    return columns;
  }

  columns.splice(startIndexOfRecipeCost, 0, DEFAULT_COST_COLUMN());

  return columns;
};

const getComponentsSheetColumns = (
  clientHasMultipleChannels,
  clientHasMultipleBrands,
  isInCentralMode = false,
) => {
  const columns = [
    ...getRecipeCommonColumns(false),
    COMPONENT_NAME_COLUMN(),
    COMPONENT_TYPE_COLUMN(),
    COMPONENT_CATEGORY_COLUMN(),
    COMPONENT_QUANTITY_COLUMN(),
    COMPONENT_UNIT_COLUMN(),
    COMPONENT_ALLERGENS_COLUMN(),
  ];

  if (clientHasMultipleBrands) {
    columns.splice(2, 0, BRANDS_COLUMN());
  }

  const startIndexOfRecipeCost = clientHasMultipleBrands ? 7 : 6;

  const costColumns = clientHasMultipleChannels
    ? isInCentralMode
      ? [ON_SITE_COST_COLUMN()]
      : [ON_SITE_COST_COLUMN(), DELIVERY_COST_COLUMN()]
    : [RECIPE_DEFAULT_COST_COLUMN()];

  columns.splice(startIndexOfRecipeCost, 0, ...costColumns);

  if (isInCentralMode) {
    columns.splice(
      clientHasMultipleBrands ? 13 : 12,
      0,
      COMPONENT_COST_COLUMN(isInCentralMode, clientHasMultipleChannels),
    );

    return columns;
  }

  if (!clientHasMultipleChannels) {
    columns.splice(
      clientHasMultipleBrands ? 14 : 13,
      0,
      COMPONENT_COST_COLUMN(isInCentralMode, clientHasMultipleChannels),
    );

    return columns;
  }

  if (clientHasMultipleChannels) {
    columns.splice(
      clientHasMultipleBrands ? 14 : 13,
      0,
      COMPONENT_USED_ON_SITE_COLUMN(),
      COMPONENT_USED_FOR_DELIVERY_COLUMN(),
      COMPONENT_COST_COLUMN(isInCentralMode, clientHasMultipleChannels),
      COMPONENT_DELIVERY_COST_COLUMN(),
    );
  }

  return columns;
};

const getIngredientsSheetColumns = (
  clientHasMultipleChannels,
  clientHasMultipleBrands,
  isInCentralMode = false,
) => {
  const columns = [
    ...getRecipeCommonColumns(false),
    INGREDIENT_NAME_COLUMN(),
    INGREDIENT_CATEGORY_COLUMN(),
    INGREDIENT_UNIT_COLUMN(),
    INGREDIENT_ALLERGENS_COLUMN(),
  ];

  if (clientHasMultipleChannels) {
    if (isInCentralMode) {
      columns.splice(6, 0, ON_SITE_COST_COLUMN());
      columns.splice(9, 0, INGREDIENT_QUANTITY_COLUMN());
    } else {
      columns.splice(6, 0, ON_SITE_COST_COLUMN(), DELIVERY_COST_COLUMN());
      columns.splice(
        10,
        0,
        INGREDIENT_ON_SITE_QUANTITY_COLUMN(),
        INGREDIENT_DELIVERY_QUANTITY_COLUMN(),
      );
    }
  }

  if (isInCentralMode || !clientHasMultipleChannels) {
    columns.splice(12, 0, INGREDIENT_COST_COLUMN(isInCentralMode, clientHasMultipleChannels));
  } else {
    columns.splice(13, 0, INGREDIENT_ON_SITE_COST_COLUMN(), INGREDIENT_DELIVERY_COST_COLUMN());
  }

  if (!clientHasMultipleChannels) {
    columns.splice(6, 0, RECIPE_DEFAULT_COST_COLUMN());
    columns.splice(10, 0, INGREDIENT_QUANTITY_COLUMN());
  }

  if (clientHasMultipleBrands) {
    columns.splice(2, 0, BRANDS_COLUMN());
  }

  return columns;
};

/**
 * Handle the export of the analytics reference data into a XLS file
 *
 * @param {Array} detailedRecipesList - The list of recipes to export in the XLS file
 *
 * @returns {void}
 */
export function exportRecipes(
  detailedRecipesList,
  detailedRecipesWithComponents,
  currency,
  clientHasMultipleChannels,
  clientHasMultipleBrands,
  isInCentralMode = false,
) {
  if (!detailedRecipesList) {
    return;
  }

  const mainSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.RECIPE_PLURAL'),
    getMainSheetColumns(clientHasMultipleChannels, clientHasMultipleBrands, isInCentralMode),
    detailedRecipesList,
    currency,
  );

  const componentsSheet = utilsXLS.generateDefaultSheet(
    i18next.t('ADMIN.RECIPES.COMPONENTS'),
    getComponentsSheetColumns(clientHasMultipleChannels, clientHasMultipleBrands, isInCentralMode),
    detailedRecipesWithComponents.filter(({ isPreparationIngredient }) => !isPreparationIngredient),
    currency,
  );

  const detailedRecipesWithIngredients = aggregateIngredients(
    detailedRecipesWithComponents,
    clientHasMultipleChannels,
  );

  const ingredientsSheet = utilsXLS.generateDefaultSheet(
    i18next.t('GENERAL.INGREDIENT_PLURAL'),
    getIngredientsSheetColumns(clientHasMultipleChannels, clientHasMultipleBrands, isInCentralMode),
    detailedRecipesWithIngredients,
    currency,
  );

  utilsXLS.makeXLS(
    i18next.t(
      isInCentralMode
        ? 'ADMIN.RECIPES.EXPORT_FILENAME_CENTRAL_KITCHEN'
        : 'ADMIN.RECIPES.EXPORT_FILENAME',
    ),
    [mainSheet, componentsSheet, ingredientsSheet],
  );
}
