import PropTypes from 'prop-types';
import React from 'react';

import {
  EmptyStateContainer,
  EmptyStateTitle,
  EmptyStateSubtitle,
  EmptyStateIcon,
} from './styledComponents';

/**
 * Generic EmptyState component, based on src/commons/EmptyState/index.js
 *
 * Entirely configurable through props; will display any combination of desired elements as long as they are present.
 *
 * Usage example in: TransferSupplierProductsList
 *
 * @param {EmptyState.propTypes} props
 * @returns
 */
const EmptyState = ({ title, subtitle, icon, button }) => (
  <EmptyStateContainer>
    {icon && <EmptyStateIcon src={icon} />}
    {title && <EmptyStateTitle> {title}</EmptyStateTitle>}
    {subtitle && <EmptyStateSubtitle> {subtitle}</EmptyStateSubtitle>}
    {button}
  </EmptyStateContainer>
);

EmptyState.propTypes = {
  // Main text to display (bigger font + bold)
  title: PropTypes.string,
  // Secondary text to display (lighter font)
  subtitle: PropTypes.string,
  // Icon to display
  icon: PropTypes.string,
  // If the empty state allows an action (ex: create the first inventory)
  button: PropTypes.node,
};

export default EmptyState;
