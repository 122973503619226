import { connect } from 'react-redux';
import { get, orderBy } from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openConfirmationModal } from '@actions/confirmationmodal';
import {
  openGenericModal,
  openMediumModal,
  openSmallModal,
  refreshGenericModal,
} from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';
import EmptyState from '@commons/EmptyState';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
  GENERIC_MODAL_SAVE_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { sortArrayOfObjectsAlphabetically } from '@commons/utils/sorting';
import { STANDARD_LISTVIEW_PADDING } from '@commons/constants/listViewProps';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import { ENUM_QUERY_PARAMS, useListViewQueryParams } from '@hooks/useListViewQueryParams';

import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { product as productService } from '@services/product';
import storeService from '@services/store';

import {
  getAssortmentConfirmationModal,
  getAssortmentSetupModal,
} from '@admin/stores/utils/modalUtils';
import { MODAL_STEP } from '@admin/stores/components/StoreAssortmentSetupModal';
import CreateProductModal from '@admin/products/products/components/CreateProductModal';
import DeepsightFiltersButton from '@admin/components/FilterButton';
import ENUM_MODES_MODAL from '@admin/products/products/components/CreateProductModal/constants/modesModal';
import ProgressBarModal from '@admin/stores/components/StoreAssortmentSetupModal/progressBarModal';

import { Container } from './styledComponents';

import { exportProductionAssortment } from './utils/exportUtils';
import { getActions, getRowActions } from './utils/actionsUtils';
import { getAssociateProductsModal } from './utils/associateProductsModal';
import { getColumns } from './utils/getColumns';
import StoreProductMappingUpdateModal from './utils/updateModal';

import { ELISION_CHECK_REGEX } from '../../utils/actionsUtils';

export const StoreProductMapping = (props) => {
  const {
    currency,
    pageLoading,
    pageLoaded,
    showSuccessMessage,
    showErrorMessage,
    client: { hasMultipleBrands, hasMultipleServices, storeName },
    match: { params },
    stores,
    openModal,
    openGenericModal,
    refreshGenericModal,
    authorizedActions,
    openSmallModal,
  } = props;

  const storeId = params.id;

  const storeNameSingle = getClientStoreNameTranslation(storeName).toLowerCase();
  const storeNamePlural = getClientStoreNameTranslation(storeName, true);

  const [store, setStore] = useState({});

  const [selectedStoreProductMappings, setSelectedStoreProductMappings] = useState([]);
  const [storeProductMappings, setStoreProductMappings] = useState([]);
  const [filteredStoreProductMappings, setFilteredStoreProductMappings] = useState([]);

  const [headers, setHeaders] = useState(getColumns(hasMultipleServices));
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);

  const [launchDate, setLaunchDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [hasStock, setHasStock] = useState(false);

  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [advancedFilters, setAdvancedFilters] = useState(null);
  const [columnsFilterList, setColumnsFilterList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [listViewQueryParams, setListViewQueryParams] = useListViewQueryParams();

  // Associate products modal states
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);

  const [storeIdReference, setStoreIdReference] = useState(null);
  const [modalStep, setModalStep] = useState(null);

  const fetchStoreProductMappingsList = async () => {
    try {
      const productionPlannings = await storeService.getProductionProductsOfStore(storeId);

      const formattedProductionPlanning = productionPlannings.map((productionPlanning) => ({
        id: productionPlanning.id,
        price: productionPlanning.price,
        storeId: productionPlanning.storeId,
        productId: productionPlanning.productId,
        name: get(productionPlanning, 'product.name', ''),
        category:
          get(productionPlanning, 'product.category', null) || i18next.t('GENERAL.NONE_VALUE'),
        subCategory:
          get(productionPlanning, 'product.subCategory', null) || i18next.t('GENERAL.NONE_VALUE'),
        brand: get(productionPlanning, 'product.lnkBrandProductrel.name', ''),
        hasStock: productionPlanning.hasStock,
        endDate: productionPlanning.endDate
          ? moment(productionPlanning.endDate).format('YYYY-MM-DD')
          : null,
        launchDate: productionPlanning.launchDate
          ? moment(productionPlanning.launchDate).format('YYYY-MM-DD')
          : null,
        min: productionPlanning.min,
        minNoon: productionPlanning.minNoon,
        minEvening: productionPlanning.minEvening,
      }));

      setStoreProductMappings(formattedProductionPlanning);
      setFilteredStoreProductMappings(formattedProductionPlanning);
      updateColumnsFilterList(formattedProductionPlanning);
    } catch {
      setStoreProductMappings([]);
      setFilteredStoreProductMappings([]);
      updateColumnsFilterList([]);

      showErrorMessage(i18next.t('ADMIN.STORES.PRODUCT_MAPPING_LOADING_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const handleEdition = async (item) => {
    const storesProductionPlanning = await fetchMappedStores(item.productId);

    const selectedStoreProductionPlanning = storesProductionPlanning.length
      ? storesProductionPlanning.find(
          (storeProductionPlanning) => storeProductionPlanning.storeId === item.storeId,
        )
      : [];

    const params = {
      component: CreateProductModal,
      productId: item.productId,
      mode: ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING_FROM_STORE,
      selectedStoreProductionPlanning,
      storeProductMappings,
      setStoreProductMappings,
      reloadStoreProductMappings,
    };

    openModal(params);
  };

  /**
   * Retrieve the list of stores mapped with this product
   *
   * @param {String} productId - The id of the product on which retrieve the mapped stores
   *
   * @returns {Promise<StoreProductionPlanning[]>}
   */
  const fetchMappedStores = async (productId) => {
    pageLoading();

    let orderedStoresProductionPlannings = [];

    try {
      const storesProductionPlanning = await productService.getProductionStores(productId);

      orderedStoresProductionPlannings = orderBy(storesProductionPlanning, 'storeName');
    } catch {
      showErrorMessage(
        i18next.t('ADMIN.STORES.STORES_FETCH_ERROR', { storeName: storeNamePlural }),
      );
    } finally {
      pageLoaded();

      return orderedStoresProductionPlannings;
    }
  };

  const updateColumnsFilterList = (products) => {
    const filterTypes = new Set(['date', 'string', 'numeric', 'boolean']);

    const columnsList = headers.filter((column) => filterTypes.has(column.filterType));

    const customColumns = new Set(['brand', 'category', 'subCategory']);

    const updateColumnsList = columnsList.reduce((result, column) => {
      const updatedColumn = { ...column };

      if (customColumns.has(column.propertyKey)) {
        const list = Object.values(
          products.reduce((result, product, key) => {
            if (product[column.propertyKey]) {
              result[product[column.propertyKey]] = {
                id: key,
                name: product[column.propertyKey],
                status: product[column.propertyKey],
                value: product[column.propertyKey],
                [column.propertyKey]: product[column.propertyKey],
              };
            }

            return result;
          }, {}),
        );

        updatedColumn.list = sortArrayOfObjectsAlphabetically(list, 'name');
      }

      result.push(updatedColumn);

      return result;
    }, []);

    setColumnsFilterList(updateColumnsList.length ? updateColumnsList : null);
  };

  const processAdvancedFilter = () => {
    if (!applyFilters) {
      return;
    }

    const filteredStoreProductMappingsList = storeProductMappings;

    if ((!advancedFilters || !advancedFilters.length) && applyFilters) {
      setFilteredStoreProductMappings(filteredStoreProductMappingsList);
      return;
    }

    const filteredStoreProductMappingsListWithAdvancedFilters = advancedFilters.reduce(
      (result, { doFilter, list, filterType, propertyKey, value }) => {
        if (filterType === 'string' && value && value.length === 1 && value[0].id === 'none') {
          return doFilter(result, propertyKey, list, true);
        }

        return doFilter(result, propertyKey, value);
      },
      filteredStoreProductMappingsList,
    );

    return setFilteredStoreProductMappings(filteredStoreProductMappingsListWithAdvancedFilters);
  };

  useEffect(() => {
    pageLoading();
    setIsLoading(true);

    if (hasMultipleBrands && !headers.some((header) => header.propertyKey === 'brand')) {
      let updatedHeaders = headers;

      updatedHeaders.splice(1, 0, {
        id: 'brand',
        baseName: 'brand',
        propertyKey: 'brand',
        name: i18next.t('ADMIN.STORES.LIST_COLUMN_BRAND'),
        displayName: i18next.t('ADMIN.STORES.LIST_COLUMN_BRAND'),
        filterType: 'string',
        excludeFromSearch: true,
        filterStringAllowNone: true,
        render: (item) => (
          <Text color={ENUM_COLORS.DARKEST} font={ENUM_FONTS.TEXT_BIG}>
            {item || '-'}
          </Text>
        ),
      });

      setHeaders(updatedHeaders);
    }

    (async () => {
      await fetchStoreProductMappingsList();

      const store = stores.find(({ id }) => id === storeId);

      setStore(store);

      setIsLoading(false);
      pageLoaded();
    })();
  }, []);

  const reloadStoreProductMappings = async () => {
    await fetchStoreProductMappingsList();

    setLaunchDate(null);
    setEndDate(null);
    setHasStock(false);
  };

  useEffect(() => {
    processAdvancedFilter();
  }, [applyFilters, advancedFilters, storeProductMappings]);

  useEffect(() => {
    updateListActions(filteredStoreProductMappings);
  }, [store, selectedStoreProductMappings, filteredStoreProductMappings]);

  useEffect(() => {
    const updateModalConfig = getUpdateModal(selectedStoreProductMappings, updateProductsMappings);

    refreshGenericModal(updateModalConfig);
  }, [launchDate, endDate, hasStock]);

  useEffect(() => {
    if (!modalStep) {
      return;
    }

    const isModalOpened = get(props, 'modal.GenericModalBool', false);

    let assortmentSetupModalConfig;

    switch (modalStep) {
      case MODAL_STEP.STORE_SELECTION:
        assortmentSetupModalConfig = getAssortmentSetupModal(
          stores,
          [store],
          storeNameSingle,
          hasMultipleBrands,
          storeIdReference,
          setStoreIdReference,
          resetModalStates,
          setModalStep,
        );
        break;

      case MODAL_STEP.VALIDATION:
        const referenceStore = stores.find(({ id }) => id === storeIdReference);
        const referenceStoreName = referenceStore ? referenceStore.name : '-';

        assortmentSetupModalConfig = getAssortmentConfirmationModal(
          [store],
          referenceStoreName,
          storeNameSingle,
          storeNamePlural,
          resetModalStates,
          setModalStep,
        );
        break;

      case MODAL_STEP.PROGRESS_BAR:
        handleProgressBarModal([store], storeIdReference);
        return;

      default:
        break;
    }

    if (isModalOpened) {
      refreshGenericModal(assortmentSetupModalConfig);
      return;
    }

    openGenericModal(assortmentSetupModalConfig);
  }, [stores, storeIdReference, modalStep]);

  const handleExport = (data) => {
    exportProductionAssortment(data, {
      hasMultipleBrands,
      hasMultipleServices,
      userLanguageCode,
      currency,
    });
  };

  const getUpdateModal = (selectedProducts, updateProductsMappings) => ({
    type: 'action',
    width: '560px',
    height: 'auto',
    icon: '/images/inpulse/edit-pen-black-small.svg',
    title: i18next.t('GENERAL.UPDATE_SELECTION'),
    component: StoreProductMappingUpdateModal,
    data: { launchDate, setLaunchDate, endDate, setEndDate, hasStock, setHasStock },
    actions: [
      GENERIC_MODAL_CANCEL_BUTTON(),
      {
        ...GENERIC_MODAL_SAVE_BUTTON(),
        isDisabled: !launchDate && endDate,
        handleClick: () => updateProductsMappings(selectedProducts, updateProductsMappings),
      },
    ],
  });

  const handleStoreProductMappingsUpdate = () => {
    const updateModalConfig = getUpdateModal(selectedStoreProductMappings, updateProductsMappings);

    openGenericModal(updateModalConfig);
  };

  const updateProductsMappings = async (selectedProducts) => {
    const productIds = selectedProducts.map(({ productId }) => productId);

    pageLoading();

    try {
      await storeService.updateStoreProductMappingsByProductIds(storeId, productIds, {
        launchDate,
        endDate,
        hasStock,
      });

      await reloadStoreProductMappings();

      showSuccessMessage(i18next.t('ADMIN.STORES.UPDATE_PRODUCT_MAPPINGS_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.UPDATE_PRODUCT_MAPPINGS_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const handleStoreProductMappingsDeletion = (selectedProducts) => {
    const warningModalConfig = getConfirmationModal({
      title: i18next.t('ADMIN.STORES.REMOVE_WARNING_MODAL_TITLE'),
      content: i18next.t('ADMIN.STORES.REMOVE_WARNING_MODAL_CONTENT', {
        count: selectedProducts.length,
      }),
      icon: '/images/inpulse/warning-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => removeProductsMappings(selectedProducts),
        },
      ],
    });

    openGenericModal(warningModalConfig);
  };

  const removeProductsMappings = async (selectedProducts) => {
    const productIds = selectedProducts.map(({ productId }) => productId);

    pageLoading();

    try {
      await storeService.deleteStoreProductMappingsByProductIds(storeId, productIds);

      showSuccessMessage(i18next.t('ADMIN.STORES.REMOVE_PRODUCT_MAPPINGS_SUCCESS'));

      await reloadStoreProductMappings();
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.REMOVE_PRODUCT_MAPPINGS_FAILURE'));
    } finally {
      pageLoaded();
    }
  };

  const updateListActions = (filteredStoreProductMappings) => {
    const data = selectedStoreProductMappings.length
      ? selectedStoreProductMappings
      : filteredStoreProductMappings;

    setActions(
      getActions(
        selectedStoreProductMappings,
        data,
        handleExport,
        handleStoreProductMappingsUpdate,
        handleStoreProductMappingsDeletion,
        storeNameSingle,
        openAssociateProductsModal,
        authorizedActions,
        store,
        handleAssortmentSetup,
      ),
    );

    setRowActions(getRowActions(handleStoreProductMappingsDeletion));
  };

  const resetModalStates = () => {
    setStoreIdReference(null);
    setModalStep(null);
  };

  const handleAssortmentSetup = (stores) => {
    const assortmentSetupModalConfig = getAssortmentSetupModal(stores);

    setModalStep(MODAL_STEP.STORE_SELECTION);

    openGenericModal(assortmentSetupModalConfig);
  };

  const closeProgressBarModalCleanUp = async () => {
    setStoreIdReference();

    await reloadStoreProductMappings();
  };

  const handleProgressBarModal = (stores, storeIdReference) => {
    openSmallModal({
      component: ProgressBarModal,
      customStyle: {
        overflow: 'initial',
      },
      stores,
      storeIdReference,
      setModalStep,
      closeModalCleanUp: closeProgressBarModalCleanUp,
    });
  };

  const getAvailableProducts = async () => {
    pageLoading();

    let products = [];

    try {
      const fetchedProducts = await storeService.getAvailableProducts(storeId);

      products = fetchedProducts.map((product) => ({
        ...product,
        brandName: get(product, 'lnkBrandProductrel.name', '-'),
        category: product.category || i18next.t('GENERAL.NONE_VALUE'),
        subCategory: product.subCategory || i18next.t('GENERAL.NONE_VALUE'),
      }));
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.PRODUCTS_FETCH_ERROR'));
    } finally {
      pageLoaded();

      setAvailableProducts(products);

      return products;
    }
  };

  const openAssociateProductsModal = async () => {
    const products = await getAvailableProducts();

    const formattedProducts = products.map((product) => ({
      ...product,
      category: product.category || i18next.t('GENERAL.NONE_VALUE'),
      subCategory: product.subCategory || i18next.t('GENERAL.NONE_VALUE'),
    }));

    const params = getAssociateProductsModal({
      products: formattedProducts,
      selectedProducts,
      setSelectedProducts,
      handleProductsAssociation,
      hasMultipleBrands,
    });

    openGenericModal(params);
  };

  const handleProductsAssociation = async () => {
    setIsLoading(true);
    pageLoading();

    try {
      const selectedProductsIds = selectedProducts.map(({ id }) => id);

      await storeService.createStoreProductMappings(storeId, selectedProductsIds);

      await fetchStoreProductMappingsList();

      showSuccessMessage(i18next.t('ADMIN.STORES.PRODUCTS_ASSOCIATION_SUCCESS'));
    } catch {
      showErrorMessage(i18next.t('ADMIN.STORES.PRODUCTS_ASSOCIATION_ERROR'));
    } finally {
      setSelectedProducts([]);
      setSelectedStoreProductMappings([]);
      setIsLoading(false);
      pageLoaded();
    }
  };

  useEffect(() => {
    const params = getAssociateProductsModal({
      products: availableProducts,
      selectedProducts,
      setSelectedProducts,
      handleProductsAssociation,
      hasMultipleBrands,
    });

    refreshGenericModal(params);
  }, [selectedProducts]);

  const userLanguageCode = get(props.user, 'lnkLanguageAccountrel.code', 'fr');

  if (!storeProductMappings.length && !isLoading) {
    const isStoreNameFirstLetterAnElision = ELISION_CHECK_REGEX.test(storeNameSingle[0]);

    return (
      <EmptyState
        label={i18next.t('ADMIN.STORES.STORE_ASSORTMENT_EMPTY_STATE_TITLE')}
        labelColor={ENUM_COLORS.DARKEST}
        labelFont={ENUM_FONTS.H2}
        renderActionButton={() => (
          <Button
            color={'inpulse-default'}
            handleClick={openAssociateProductsModal}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('GENERAL.ADD')}
          />
        )}
        subtitle={i18next.t(
          isStoreNameFirstLetterAnElision
            ? 'ADMIN.STORES.STORE_ASSORTMENT_EMPTY_STATE_SUB_TITLE_FIRST_LETTER_ELISION'
            : 'ADMIN.STORES.STORE_ASSORTMENT_EMPTY_STATE_SUB_TITLE_FIRST_LETTER_CONSONANT',
          { storeName: storeNameSingle.toLowerCase() },
        )}
        subtitleMargin={'8px'}
      />
    );
  }

  return (
    <Container>
      <ListView
        actionOnClick={handleEdition}
        actions={actions}
        columns={headers}
        data={filteredStoreProductMappings}
        defaultCurrentPage={listViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE]}
        defaultMaxPerPage={listViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE]}
        defaultOrderBy={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY]}
        defaultOrderType={listViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE]}
        defaultSearchInput={listViewQueryParams[ENUM_QUERY_PARAMS.SEARCH]}
        handleCurrentPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.CURRENT_PAGE](input)
        }
        handleMaxPerPageChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.MAX_PER_PAGE](input)
        }
        handleOrderByChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_BY](input)}
        handleOrderTypeChange={(input) =>
          setListViewQueryParams[ENUM_QUERY_PARAMS.ORDER_TYPE](input)
        }
        handleSearchInputChange={(input) => setListViewQueryParams[ENUM_QUERY_PARAMS.SEARCH](input)}
        isLoading={isLoading}
        languageCode={userLanguageCode}
        minActionsInActionsDropdown={1}
        padding={STANDARD_LISTVIEW_PADDING}
        placeholderShape={i18next.t('GENERAL.SEARCH')}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => {
          if (!columnsFilterList || !columnsFilterList.length) {
            return null;
          }

          return (
            <DeepsightFiltersButton
              advancedFilters={advancedFilters}
              applyFilters={applyFilters}
              columnsFilterList={columnsFilterList}
              filters={filters}
              isLoading={isLoading}
              minWidth={120}
              readOnly={isLoading}
              setAdvancedFilters={(it) => setAdvancedFilters(it)}
              setApplyFilters={(it) => setApplyFilters(it)}
              setFilters={(it) => setFilters(it)}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          );
        }}
        rowActions={rowActions}
        setSelectedItems={(items) => setSelectedStoreProductMappings(items)}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  currency: state.baseReducer.currency,
  client: getClientInfo(state.baseReducer.user),
  modal: state.modalReducer,
  stores: state.baseReducer.stores,
  authorizedActions: getAuthorizedActions(state.baseReducer.userRights, '/admin/stores'),
});

const mapDispatchToProps = (dispatch) => ({
  openModal: (params) => {
    dispatch(openMediumModal(params));
  },
  openConfirmationModal: (params) => {
    dispatch(openConfirmationModal(params));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
  openSmallModal: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreProductMapping);
