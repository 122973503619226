import { connect } from 'react-redux';
import i18next from 'i18next';
import React from 'react';

import { getPropertyNoneValue } from '@commons/constants/categoryTypes';

import { getClientInfo } from '@selectors/client';

import utilsMethods from '@admin/products/ingredients/detail/utils';

import {
  ContainerListComposition,
  LinkText,
  ListHeader,
  ListHeaderSection,
  ListRow,
  ListRowSection,
  ListRowText,
} from './styledComponents';

const getHeaderColumns = () => [
  {
    name: i18next.t('GENERAL.NAME'),
    className: 'name',
    renderValue: ({ item }) => {
      const adminProductPrefix = item.isKitchen ? 'kitchen-products/products' : 'products';
      const adminRecipePrefix = item.isKitchen ? 'kitchen-products/recipes' : 'products/recipes';

      const itemType = item.isProduct ? adminProductPrefix : adminRecipePrefix;

      const disabled = item.isKitchen && !item.isUserAssociatedToCentralKitchenStores;
      return (
        <ListRowText
          className="link"
          flex
          onClick={() => {
            if (disabled) {
              return;
            }

            window.open(`/admin/${itemType}/${item.id}/details`, '_blank');
          }}
        >
          <LinkText disabled={disabled}>{item.name}</LinkText>
        </ListRowText>
      );
    },
  },
  {
    name: i18next.t('ADMIN.INGREDIENTS.PARENT_ASSOCIATION_COLUMN_ELEMENT_TYPE'),
    renderValue: ({ item }) => {
      let productType = i18next.t(item.isProduct ? 'GENERAL.PRODUCT' : 'GENERAL.RECIPE');

      if (item.clientHasCentralKitchens && item.isKitchen) {
        productType = i18next.t(
          item.isProduct
            ? 'ADMIN.INGREDIENTS.CENTRAL_PRODUCT_LABEL'
            : 'ADMIN.INGREDIENTS.CENTRAL_RECIPE_LABEL',
        );
      }

      return <ListRowText>{productType}</ListRowText>;
    },
  },
  {
    name: i18next.t('GENERAL.CATEGORY'),
    renderValue: ({ item }) => <ListRowText>{item.category || getPropertyNoneValue()}</ListRowText>,
  },
  {
    name: i18next.t('ADMIN.INGREDIENTS.PARENT_ASSOCIATION_COLUMN_QUANTITY_USED'),
    renderValue: ({ item }, props) => (
      <ListRowText>{`${item.quantity} ${utilsMethods.getUnit(
        props.ingredient.unit,
        item.quantity,
      )}`}</ListRowText>
    ),
  },
  {
    name: i18next.t('GENERAL.STATUS'),
    renderValue: ({ item }) => (
      <ListRowText>{i18next.t(item.active ? 'GENERAL.ACTIVE' : 'GENERAL.INACTIVE')}</ListRowText>
    ),
  },
];

/*************************/
/* Input Change Handlers */
/*************************/

export function ParentsCompositionList(props) {
  const { entitiesMapped, client, stores } = props;

  const isUserAssociatedToCentralKitchenStores = stores.some(({ isKitchen }) => isKitchen);

  const headers = getHeaderColumns();

  return (
    <ContainerListComposition>
      <ListHeader>
        {headers.map(({ className, name }, indexHeader) => (
          <ListHeaderSection className={className} key={indexHeader}>
            {name}
          </ListHeaderSection>
        ))}
      </ListHeader>
      {entitiesMapped.map((item, indexComposition) => (
        <ListRow
          disabled={!item.active || (item.isKitchen && !isUserAssociatedToCentralKitchenStores)}
          key={indexComposition}
        >
          {headers.map(({ className, renderValue }, indexItem) => (
            <ListRowSection className={className} key={indexItem}>
              {renderValue(
                {
                  item: {
                    ...item,
                    clientHasCentralKitchens: client.hasCentralKitchens,
                    isUserAssociatedToCentralKitchenStores,
                  },
                  index: indexComposition,
                },
                { ...props },
              )}
            </ListRowSection>
          ))}
        </ListRow>
      ))}
    </ContainerListComposition>
  );
}

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
  client: getClientInfo(state.baseReducer.user),
  stores: state.baseReducer.stores,
});

export default connect(mapStateToProps, null)(ParentsCompositionList);
