import { connect } from 'react-redux';
import _ from 'lodash';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect, useState, useMemo } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import {
  showConfirmationMessage,
  showErrorMessage,
  showSuccessMessage,
} from '@actions/messageconfirmation';

import { Button } from '@commons/utils/styledLibraryComponents';
import {
  CATEGORY_TYPES_OBJECT,
  getPropertyNoneValue,
  SUB_CATEGORY_TYPES_OBJECT,
} from '@commons/constants/categoryTypes';
import { isUserAllowedToAccessProduction } from '@commons/utils/features';
import GenericModal from '@commons/Modals/GenericModal';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { getClientInfo } from '@selectors/client';

import { cashierProductService } from '@services/cashierProduct';
import { product as productService } from '@services/product';
import clientService from '@services/client';
import storeService from '@services/store';

import { DYNAMIC_PROPERTIES } from '@admin/commons/dynamicModalProperties';

import {
  fetchBrandsOfClient,
  fetchProductsCategoriesOfClient,
} from '../../detail/components/ProductInfo/common/services';

import {
  BottomContainer,
  Container,
  Content,
  GenericModalContainer,
  TopContainer,
  HeaderText,
  HeaderRightPartContainer,
  CloseModalIcon,
} from './styledComponents';

import { getNewCategorySubcategoryModalParams } from './utils/modalConfigurations';
import ENUM_MODES_MODAL from './constants/modesModal';
import getEnumChoicesModal from './constants/choicesModal';
import PreStepChoiceToProceed from './components/PreStepChoiceToProceed';
import StepCashierProductAssociation from './components/StepCashierProductAssociation';
import StepProductInfo from './components/StepProductInfo';
import StepProductionPlanning from './components/StepProductionPlanning';
import StepStoreAssociation from './components/StepStoreAssociation';

const EMPTY_PRODUCT = { name: '', active: true, price: 0, vatRate: 0 };

const STEP_CREATE_PRODUCT = () => ({
  id: 1,
  title: i18next.t('ADMIN.PRODUCTS.CREATE'),
  component: StepProductInfo,
  validation: ({ product }) => {
    if (
      !product ||
      (!product.price && product.price !== 0) ||
      !product.name ||
      (!product.vatRate && product.vatRate !== 0) ||
      !product.isCompositionValid
    ) {
      return false;
    }

    return true;
  },
});

const PRE_STEP_CHOICE_TO_PROCEED = (selectedChoice, setSelectedChoice, preStep) => {
  const ENUM_CHOICES_MODAL = getEnumChoicesModal();

  return {
    id:
      preStep === ENUM_CHOICES_MODAL.CASHIER_PRODUCT_ASSOCIATION_TYPE
        ? ENUM_CHOICES_MODAL.CASHIER_PRODUCT_ASSOCIATION_TYPE
        : ENUM_CHOICES_MODAL.PRODUCTION_PLANNING_CREATION_TYPE,
    title:
      preStep === ENUM_CHOICES_MODAL.CASHIER_PRODUCT_ASSOCIATION_TYPE
        ? ENUM_CHOICES_MODAL.CASHIER_PRODUCT_ASSOCIATION
        : ENUM_CHOICES_MODAL.PRODUCTION_PLANNING_CREATION,
    component: PreStepChoiceToProceed,
    selectedChoice,
    setSelectedChoice,
    text:
      preStep === ENUM_CHOICES_MODAL.CASHIER_PRODUCT_ASSOCIATION_TYPE
        ? ENUM_CHOICES_MODAL.CASHIER_PRODUCT_ASSOCIATION_INFO
        : ENUM_CHOICES_MODAL.PRODUCTION_PLANNING_CREATION_INFO,
    iconSrc:
      preStep === ENUM_CHOICES_MODAL.CASHIER_PRODUCT_ASSOCIATION_TYPE
        ? ENUM_CHOICES_MODAL.CASHIER_PRODUCT_ASSOCIATION_ICON
        : ENUM_CHOICES_MODAL.PRODUCTION_PLANNING_CREATION_ICON,
    validation: () => true,
    disableHeader: true,
  };
};

const STEP_PRODUCT_CASHIER_PRODUCT_MAPPING = () => ({
  id: 2,
  title: i18next.t('ADMIN.PRODUCTS.SELECT_CASHIER_PRODUCTS_TO_ASSOCIATE'),
  component: StepCashierProductAssociation,
  validation: ({ cashierProducts }) => !!cashierProducts.length,
});

const STEP_PRODUCT_STORE_MAPPING = () => ({
  id: 3,
  title: i18next.t('ADMIN.PRODUCTS.ADD_PRODUCT_TO_PRODUCTION_PLANNING'),
  component: StepStoreAssociation,
  validation: ({ mode, storesProduct }) => {
    if (mode === ENUM_MODES_MODAL.CREATE) {
      return true;
    }

    return !!storesProduct.length;
  },
});

const STEP_PRODUCTION_PLANNING = ({ product, mode }) => ({
  id: 4,
  title:
    mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING ||
    mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING_FROM_STORE
      ? i18next.t('ADMIN.PRODUCTS.CONFIGURE_STORE_PRODUCTION_PLANNING', {
          productName: product.name,
        })
      : i18next.t('ADMIN.PRODUCTS.CONFIGURE_PRODUCTION_PLANNING'),
  component: StepProductionPlanning,
  validation: ({ productionParameters }) => {
    if (!productionParameters || !productionParameters.price) {
      return false;
    }

    if (!productionParameters.launchDate && productionParameters.endDate) {
      return false;
    }

    return true;
  },
});

/**
 * Handle the save of the product
 *
 * @param {Object} props.product              - The product to create configured by the user
 * @param {Object[]} props.storesProduct      - The list of stores on which the produt will be mapped
 * @param {Object} props.productionParameters - The production planning configuration to apply on the product
 * @param {Function} props.pageLoading        - The method to display the loader and set the page as loading
 * @param {Function} props.pageLoaded         - The method to not display the loader and set the page as loaded
 * @param {Function} props.showMessage        - The method to display message relating to the request status
 * @param {Function} props.closeModal         - The method to close the opened modal
 * @param {Function} props.history            - The react history instance
 * @param {String} props.mode                 - The mode on which the popup is being used
 * @param {Object} props.params               - The props params associated to the modal when being first rendered
 *
 * @returns {void}
 */
export async function handleSave({
  product,
  storesProduct,
  cashierProducts,
  productionParameters,
  pageLoading,
  pageLoaded,
  showMessage,
  closeModal,
  history,
  mode,
  hasMultipleServices,
  productsCategories,
  productsSubCategories,
  params: {
    storesProductionPlanning,
    setStoresProductionPlanning,
    selectedStoreProductionPlanning,
    reloadStoreProductMappings,
  },
}) {
  pageLoading();
  try {
    const formattedProductionPlanning = {
      ...productionParameters,
      launchDate: productionParameters.launchDate
        ? moment(productionParameters.launchDate).format('YYYY-MM-DD')
        : null,
      endDate: productionParameters.endDate
        ? moment(productionParameters.endDate).format('YYYY-MM-DD')
        : null,
    };

    const categoryNone = productsCategories.find(
      ({ name }) => name === i18next.t('GENERAL.NONE_VALUE'),
    );

    const subCategoryNone = productsSubCategories.find(
      ({ name }) => name === i18next.t('GENERAL.NONE_VALUE'),
    );

    product.categoryId = product.categoryId || categoryNone.id;
    product.subCategoryId = product.subCategoryId || subCategoryNone.id;

    const { price, endDate, hasStock, launchDate, noonMinimum, dailyMinimum, eveningMinimum } =
      formattedProductionPlanning;

    const storeIds = storesProduct.map((store) => store.id);

    const result =
      mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING ||
      mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING_FROM_STORE
        ? await productService.updateStoreProductionParameters(
            product.id,
            selectedStoreProductionPlanning.storeId,
            price,
            hasStock,
            launchDate,
            endDate,
            noonMinimum,
            dailyMinimum,
            eveningMinimum,
          )
        : await productService.saveProductWithProductionParameters(
            product,
            storeIds,
            price,
            hasStock,
            launchDate,
            endDate,
            noonMinimum,
            dailyMinimum,
            eveningMinimum,
          );

    if (cashierProducts.length) {
      const cashierProductsIds = cashierProducts.map((cashierProduct) => cashierProduct.id);
      await productService.associateCashierProductsToProduct(result.product.id, cashierProductsIds);
    }

    let messageSuccess = 'Succès';

    if (mode === ENUM_MODES_MODAL.CREATE) {
      showMessage('Le produit a été enregistré');
      pageLoaded();
      closeModal();

      history.push(`/admin/products/${result.product.id}/details`);

      return;
    }

    if (mode === ENUM_MODES_MODAL.EDIT_STORES) {
      messageSuccess = 'Produit ajouté au planning de production';

      setStoresProductionPlanning(_.orderBy(result.storeProductMappings, 'storeName'));
    }

    if (
      mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING ||
      mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING_FROM_STORE
    ) {
      messageSuccess = 'Planning de production mis à jour';

      if (mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING) {
        const { price, endDate, hasStock, launchDate, noonMinimum, dailyMinimum, eveningMinimum } =
          productionParameters;

        const updatedStoresProductionPlanning = storesProductionPlanning.map(
          (storeProductionPlanning) => {
            if (storeProductionPlanning.id === selectedStoreProductionPlanning.id) {
              const updatedProductionConstraints = [];

              if (hasMultipleServices) {
                updatedProductionConstraints.push({
                  value: noonMinimum,
                  service: 'midi',
                  type: 'min',
                });

                updatedProductionConstraints.push({
                  value: eveningMinimum,
                  service: 'soir',
                  type: 'min',
                });
              } else {
                updatedProductionConstraints.push({
                  value: dailyMinimum,
                  type: 'min',
                });
              }

              return {
                ...storeProductionPlanning,
                endDate,
                hasStock,
                launchDate,
                price: Number(price),
                productionConstraints: updatedProductionConstraints,
              };
            }

            return storeProductionPlanning;
          },
        );

        setStoresProductionPlanning(_.orderBy(updatedStoresProductionPlanning, 'storeName'));
      } else {
        // Reload Store Product Mappings
        await reloadStoreProductMappings();
      }
    }

    showMessage(messageSuccess);

    pageLoaded();
    closeModal();
  } catch (error) {
    let messageError = 'Echec';

    if (mode === ENUM_MODES_MODAL.CREATE) {
      messageError = 'La sauvegarde du produit a échoué';
    }

    if (mode === ENUM_MODES_MODAL.EDIT_STORES) {
      messageError = 'Echec de l’ajout au planning de production';
    }

    if (
      mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING ||
      mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING_FROM_STORE
    ) {
      messageError = 'Echec de la modification du planning de production';
    }

    showMessage(messageError, 'error');
    pageLoaded();
  }
}

/**
 * Handle the fetch of the cashier products available for the given client id
 *
 * @param {String} clientId                     - The id of the client on which retrieve available cashier products
 * @param {Function} setCashierProductsClient   - The method to save the cashier products in the dedicated state
 *
 */
export async function fetchCashierProductsOfClient(clientId, setCashierProductsClient) {
  const cashierProductsOfClient = await cashierProductService.getCashierProductsByClientId(
    clientId,
  );

  const formattedCashierProducts = cashierProductsOfClient.map((cashierProduct) => ({
    ...cashierProduct,
    isAssociated: !!cashierProduct.associatedProduct,
  }));

  const visibleCashierProducts = formattedCashierProducts.filter(
    (cashierProduct) => !cashierProduct.hidden,
  );

  setCashierProductsClient(
    _.orderBy(visibleCashierProducts, ['name', 'createdAt'], ['asc', 'desc']),
  );
}

/**
 * Handle the fetch of the stores available for the given client id
 *
 * @param {String} clientId           - The id of the client on which retrieve available stores
 * @param {Function} setStoresClient  - The method to save the stores in the dedicated state
 * @param {String} productId          - (Optional) the product id on which retrieve potential existing mappings
 *
 * @returns {Store[]} The list of formatted stores associated to the given client id
 */
export async function fetchStoresOfClient(clientId, setStoresClient, productId) {
  const stores = await storeService.getStoresOfClient(clientId);

  const formattedStores = stores.map((store) => ({
    ..._.pick(store, ['id', 'name', 'city', 'country', 'active']),
    brand: _.get(store, 'lnkBrandStorerel.name'),
  }));

  if (!productId) {
    return setStoresClient(formattedStores);
  }

  const storesProductMappings = await productService.getProductionStores(productId);

  setStoresClient(
    formattedStores.filter(
      (store) => !storesProductMappings.some((mapping) => mapping.storeId === store.id),
    ),
  );
}

/**
 * Retrieve the information related to product associated to the given productId
 *
 * @param {String} productId          - The product id on which retrieve the datae
 * @param {Function} onProductChange  - Method to set the local state of the product
 *
 * @returns {Object} An object with all informations related to the product
 */
export async function fetchProduct(productId, onProductChange) {
  const result = await productService.getById(productId);

  result.price = result.priceWithTaxes;

  result.deliveryPrice = result.deliveryPriceWithTaxes;

  result.brand = result.lnkBrandProductrel ? result.lnkBrandProductrel.name : '';

  onProductChange(result);

  return result;
}

/**
 * Build the steps that should be render on the modal according to the mode en which the modal was opened
 * and the content of the settings
 *
 * @param {String} mode                               - The mode on which the modal was opened
 * @param {Object[]} steps                            - The list of steps that are already active on the modal
 * @param {Object[]} storesProduct                    - The store product mapping list associated to the product
 * @param {Method} onStepsChange                      - The method to allow updating the steps to display on the modal
 * @param {Object} product                            - The product associated to the stores and production planning
 * @param {Object} selectedStoreProductionPlanning    - The store product mapping associated to the edition in Edit Production Planning mode
 * @param {Boolean} hasUserAccessToProductionFeature  - Whether the user has access to the production module and should be able to param the production planning
 *
 * @returns {void}
 */
export function buildSteps(
  mode,
  steps,
  storesProduct,
  onStepsChange,
  product,
  selectedStoreProductionPlanning,
  hasUserAccessToProductionFeature,
  areProductsSync,
  selectedChoice,
  setSelectedChoice,
) {
  const updatedSteps = [];
  const ENUM_CHOICES_MODAL = getEnumChoicesModal();

  if (mode === ENUM_MODES_MODAL.CREATE) {
    updatedSteps.push(STEP_CREATE_PRODUCT());

    if (areProductsSync) {
      updatedSteps.push(
        PRE_STEP_CHOICE_TO_PROCEED(
          selectedChoice,
          setSelectedChoice,
          ENUM_CHOICES_MODAL.CASHIER_PRODUCT_ASSOCIATION_TYPE,
        ),
      );
    }
    if (
      areProductsSync &&
      selectedChoice[ENUM_CHOICES_MODAL.CASHIER_PRODUCT_ASSOCIATION_TYPE] === 'yes'
    ) {
      updatedSteps.push(STEP_PRODUCT_CASHIER_PRODUCT_MAPPING());
    }
    if (hasUserAccessToProductionFeature) {
      updatedSteps.push(
        PRE_STEP_CHOICE_TO_PROCEED(
          selectedChoice,
          setSelectedChoice,
          ENUM_CHOICES_MODAL.PRODUCTION_PLANNING_CREATION_TYPE,
        ),
      );
    }
    if (
      hasUserAccessToProductionFeature &&
      selectedChoice[ENUM_CHOICES_MODAL.PRODUCTION_PLANNING_CREATION_TYPE] === 'yes'
    ) {
      updatedSteps.push(STEP_PRODUCT_STORE_MAPPING());

      if (storesProduct.length) {
        updatedSteps.push(
          STEP_PRODUCTION_PLANNING({
            mode,
            product,
            selectedStoreProductionPlanning,
          }),
        );
      }
    }
  }

  if (mode === ENUM_MODES_MODAL.EDIT_STORES) {
    updatedSteps.push(STEP_PRODUCT_STORE_MAPPING());
    updatedSteps.push(
      STEP_PRODUCTION_PLANNING({
        mode,
        product,
      }),
    );
  }

  if (
    mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING ||
    mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING_FROM_STORE
  ) {
    updatedSteps.push(
      STEP_PRODUCTION_PLANNING({
        mode,
        product,
        editFromStore: mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING_FROM_STORE,
      }),
    );
  }

  // If the steps are unchanged, do ask for re-render
  if (
    steps.length === updatedSteps.length &&
    updatedSteps.every((updatedStep) => steps.some((step) => step.title === updatedStep.title))
  ) {
    return;
  }

  return onStepsChange(updatedSteps);
}

/**
 * Render the header part of the modal with the title relating the current step of the modal
 *
 * @param {Object[]} steps            - The list of steps to configured on the modal
 * @param {Number} selectedStepIndex  - The index of the current step on which the modal is
 *
 * @returns {Component}
 */
export function renderHeader(steps, selectedStepIndex, closeModal) {
  const ENUM_CHOICES_MODAL = getEnumChoicesModal();

  const selectedStep = steps[selectedStepIndex];
  if (
    selectedStep.id === ENUM_CHOICES_MODAL.CASHIER_PRODUCT_ASSOCIATION_TYPE ||
    selectedStep.id === ENUM_CHOICES_MODAL.PRODUCTION_PLANNING_CREATION_TYPE
  ) {
    return null;
  }
  const countTotalStep = steps.reduce((total, step) => {
    if (step.disableHeader) {
      return total;
    }
    return (total += 1);
  }, 0);
  const indexCurrentStep = steps.slice(0, selectedStepIndex).reduce((total, step) => {
    if (step.disableHeader) {
      return total;
    }
    return (total += 1);
  }, 0);

  return (
    <TopContainer>
      <HeaderText>
        {selectedStep.title}
        {countTotalStep > 1 ? ` (${indexCurrentStep + 1}/${countTotalStep})` : ''}
      </HeaderText>

      <HeaderRightPartContainer>
        <CloseModalIcon src={'/images/inpulse/close-black-small.svg'} onClick={closeModal} />
      </HeaderRightPartContainer>
    </TopContainer>
  );
}

/**
 * Render the content part of the modal
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {Component}
 */
export function renderContent(props) {
  const { currentStep } = props;

  const ComponentName = currentStep.component;

  return (
    <Content noHeader={currentStep.disableHeader}>
      <ComponentName {...props} />
    </Content>
  );
}

/**
 * Render the bottom part of the modal managing the modal's navigation and the save of the form
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {Component}
 */
export function renderBottom(props) {
  const {
    closeModal,
    steps,
    selectedStepIndex,
    onSelectedStepChange,
    selectedChoice,
    currentStep,
  } = props;
  const ENUM_CHOICES_MODAL = getEnumChoicesModal();

  const isPreStep = steps[selectedStepIndex].disableHeader;
  const isFirstStep = selectedStepIndex === 0;
  const isLastStep =
    selectedStepIndex === steps.length - 1 ||
    (isPreStep &&
      selectedChoice === false &&
      currentStep.id === ENUM_CHOICES_MODAL.PRODUCTION_PLANNING_CREATION_TYPE);

  const isFormValid = steps[selectedStepIndex].validation(props);

  return (
    <BottomContainer>
      {isFirstStep && (
        <Button
          buttonCustomStyle={{ marginRight: 18 }}
          color={'blue-outline-no-shadow'}
          handleClick={() => closeModal()}
          icon={'/images/inpulse/close-black-small.svg'}
          label={i18next.t('GENERAL.CANCEL')}
        />
      )}
      {!isFirstStep && (
        <Button
          buttonCustomStyle={{ marginRight: 18 }}
          color={'blue-outline-no-shadow'}
          handleClick={() => onSelectedStepChange(selectedStepIndex - 1)}
          icon={'/images/inpulse/arrow-left-ip-black.svg'}
          label={i18next.t('GENERAL.BACK')}
        />
      )}
      {!isLastStep && (
        <Button
          buttonCustomStyle={{ marginRight: 18 }}
          color={isFormValid ? 'blue' : 'grey'}
          handleClick={() => (isFormValid ? onSelectedStepChange(selectedStepIndex + 1) : {})}
          icon={'/images/inpulse/arrow-right-white.svg'}
          isDisabled={!isFormValid}
          label={i18next.t('GENERAL.NEXT')}
        />
      )}
      {isLastStep && (
        <Button
          buttonCustomStyle={{ marginRight: 18 }}
          color={isFormValid ? 'blue' : 'grey'}
          handleClick={() => (isFormValid ? handleSave(props) : {})}
          icon={'/images/inpulse/save-white-small.svg'}
          isDisabled={!isFormValid}
          label={i18next.t('GENERAL.SAVE')}
        />
      )}
    </BottomContainer>
  );
}

const CreateProductModal = (props) => {
  const {
    showMessage,
    showErrorMessage,
    showSuccessMessage,
    pageLoading,
    pageLoaded,
    closeModal,
    client: { areProductsSync, clientId, hasMultipleBrands, hasMultipleServices },
    hasUserAccessToProductionFeature,
    params: { history, productId, selectedStoreProductionPlanning, mode = ENUM_MODES_MODAL.CREATE },
  } = props;

  // Initial values to process to the worflow product's creation
  const [product, onProductChange] = useState(EMPTY_PRODUCT);
  const [steps, onStepsChange] = useState([]);
  const [storesProduct, onStoresProductChange] = useState([]);
  const [cashierProducts, onCashierProductsChange] = useState([]);
  const [selectedChoice, setSelectedChoice] = useState({
    cashierProductAssociation: 'yes',
    productionPlanning: 'yes',
  });
  const [selectedStepIndex, onSelectedStepChange] = useState(0);
  const [productionParameters, setProductionParameters] = useState({
    price: '',
    hasStock: true,
    noonMinimum: '',
    dailyMinimum: '',
    eveningMinimum: '',
    launchDate: moment(),
  });

  // Complementary fetches for product configuration
  const [brands, setBrands] = useState([]);
  const [storesClient, setStoresClient] = useState([]);
  const [cashierProductsClient, setCashierProductsClient] = useState([]);
  const [productsCategories, setProductsCategories] = useState([]);
  const [productsSubCategories, setProductsSubCategories] = useState([]);

  const [openModalAddNewItemDropdown, setOpenModalAddNewItemDropdown] = useState(false);
  const [itemDropdownPropertyName, setItemDropdownPropertyName] = useState('');

  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const currentStep = steps[selectedStepIndex];

  const categoryNone = useMemo(
    () => productsCategories.find(({ name }) => name === getPropertyNoneValue()),
    [productsCategories],
  );
  const subCategoryNone = useMemo(
    () => productsSubCategories.find(({ name }) => name === getPropertyNoneValue()),
    [productsSubCategories],
  );

  // Retrieve all necessary data to configure the product
  useEffect(() => {
    pageLoading();

    if (!product.id || product.id === ENUM_MODES_MODAL.CREATE) {
      onProductChange({ ...product, clientId });
    }

    (async function loadData() {
      try {
        if (mode !== ENUM_MODES_MODAL.CREATE) {
          await fetchProduct(productId, onProductChange);
        }

        // No need to load data when editing Production Planning of a store
        if (
          mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING ||
          mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING_FROM_STORE
        ) {
          return;
        }

        const promises = [
          fetchCashierProductsOfClient(clientId, setCashierProductsClient),
          fetchStoresOfClient(clientId, setStoresClient, productId),
        ];

        // No need to load any other data when editing stores production planning mappings
        if (mode === ENUM_MODES_MODAL.EDIT_STORES) {
          await Promise.all(promises);

          return;
        }

        if (hasMultipleBrands) {
          promises.push(fetchBrandsOfClient(clientId, setBrands));
        }

        promises.push(
          fetchProductsCategoriesOfClient(
            clientId,
            setProductsCategories,
            setProductsSubCategories,
          ),
        );

        await Promise.all(promises);
      } catch (err) {
        showMessage('Erreur du chargement', 'error');

        closeModal();
      } finally {
        pageLoaded();
      }
    })();
  }, []);

  // Handle the steps that should be displayed to the users
  useEffect(() => {
    buildSteps(
      mode,
      steps,
      storesProduct,
      onStepsChange,
      product,
      selectedStoreProductionPlanning,
      hasUserAccessToProductionFeature,
      areProductsSync,
      selectedChoice,
      setSelectedChoice,
    );

    if (
      (mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING ||
        mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING_FROM_STORE) &&
      !_.isEmpty(selectedStoreProductionPlanning)
    ) {
      const minimumProduction = selectedStoreProductionPlanning.productionConstraints.reduce(
        (result, constraint) => {
          if (!constraint.service) {
            result.dailyMinimum = constraint.value;
          } else if (constraint.service === 'midi') {
            result.noonMinimum = constraint.value;
          } else if (constraint.service === 'soir') {
            result.eveningMinimum = constraint.value;
          }

          return result;
        },
        {},
      );

      setProductionParameters({
        ...productionParameters,
        ...minimumProduction,
      });
    }
  }, [storesProduct, selectedStoreProductionPlanning, product, selectedChoice]);

  // Handle the pre-set of values in the production planning from the product-configuration
  useEffect(() => {
    if (
      mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING ||
      mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING_FROM_STORE
    ) {
      return;
    }

    if (product.price !== productionParameters.price) {
      setProductionParameters({
        ...productionParameters,
        price: product.price,
      });
    }
  }, [product]);

  // Handle pre-set of production planning when in Edit Production Planning mode with the selection store settings
  useEffect(() => {
    if (
      mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING ||
      mode === ENUM_MODES_MODAL.EDIT_PRODUCTION_PLANNING_FROM_STORE
    ) {
      const { price, endDate, hasStock, launchDate } = selectedStoreProductionPlanning;

      setProductionParameters({
        ...productionParameters,
        price,
        hasStock: !!hasStock,
        endDate: endDate ? moment(endDate) : null,
        launchDate: launchDate ? moment(launchDate) : null,
      });

      return;
    }
  }, [selectedStoreProductionPlanning]);

  const handleInputChange = (value) => {
    setInputValue(value);

    if (!value) {
      setErrorMessage(i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE'));

      return;
    }

    const alreadyExists = productsCategories.some(
      ({ name }) => normalizeStringValue(name) === normalizeStringValue(value),
    );

    if (alreadyExists) {
      setErrorMessage(
        itemDropdownPropertyName === 'category'
          ? i18next.t('GENERAL.MODAL_CATEGORY_NAME_ALREADY_USED')
          : i18next.t('GENERAL.MODAL_SUBCATEGORY_NAME_ALREADY_USED'),
      );

      return;
    }

    setErrorMessage(null);
  };

  const closeCleanUp = () => {
    setErrorMessage(null);
    setInputValue('');
  };

  const handleNewCategoryCreation = async () => {
    try {
      pageLoading();

      const createdCategory = await clientService.createCategory(
        clientId,
        inputValue.trim(),
        CATEGORY_TYPES_OBJECT.PRODUCT,
      );

      const updatedCategories = [...productsCategories, createdCategory];

      setProductsCategories(updatedCategories);

      onProductChange({
        ...product,
        category: createdCategory.name,
        categoryId: createdCategory.id,
      });

      showSuccessMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_SUCCESS'));
    } catch {
      onProductChange({
        ...product,
        category: categoryNone.name,
        categoryId: categoryNone.id,
      });

      showErrorMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_ERROR'));
    } finally {
      setOpenModalAddNewItemDropdown(false);
      setInputValue('');
      pageLoaded();
    }
  };

  const handleNewSubCategoryCreation = async () => {
    try {
      pageLoading();

      const createdSubCategory = await clientService.createSubCategory(
        clientId,
        inputValue.trim(),
        SUB_CATEGORY_TYPES_OBJECT.PRODUCT,
      );

      const updatedSubCategories = [...productsSubCategories, createdSubCategory];

      setProductsSubCategories(updatedSubCategories);

      onProductChange({
        ...product,
        category: createdSubCategory.name,
        categoryId: createdSubCategory.id,
      });

      showSuccessMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_SUCCESS'));
    } catch {
      onProductChange({
        ...product,
        subCategory: subCategoryNone.name,
        subCategoryId: subCategoryNone.id,
      });

      showErrorMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_ERROR'));
    } finally {
      setOpenModalAddNewItemDropdown(false);
      setInputValue('');
      pageLoaded();
    }
  };

  const handleSaveNewItemDropdown = async () => {
    switch (itemDropdownPropertyName) {
      case DYNAMIC_PROPERTIES.CATEGORY:
        await handleNewCategoryCreation();
        return;
      case DYNAMIC_PROPERTIES.SUB_CATEGORY:
        await handleNewSubCategoryCreation();
        return;
      default:
        return;
    }
  };

  const modalCreateNewCategoryParams = getNewCategorySubcategoryModalParams(
    inputValue,
    errorMessage,
    closeCleanUp,
    handleInputChange,
    itemDropdownPropertyName,
    handleSaveNewItemDropdown,
  );

  if (!steps.length) {
    return <div></div>;
  }

  return (
    <Container>
      {/* Render top bar with custom title */}
      {renderHeader(steps, selectedStepIndex, closeModal)}
      {/* Render content step from current the step */}
      {renderContent({
        ...props,
        currentStep,
        product,
        onProductChange,
        productsCategories,
        productsSubCategories,
        areProductsSync,
        hasMultipleBrands,
        brands,
        cashierProductsClient,
        cashierProducts,
        onCashierProductsChange,
        storesClient,
        storesProduct,
        onStoresProductChange,
        productionParameters,
        setProductionParameters,
        hasMultipleServices,
        selectedChoice,
        setSelectedChoice,
        selectedStepIndex,
        setOpenModalAddNewItemDropdown,
        setItemDropdownPropertyName,
      })}
      {/* Render bottom bar with buttons */}
      {renderBottom({
        ...props,
        steps,
        product,
        storesProduct,
        selectedStepIndex,
        productionParameters,
        onSelectedStepChange,
        history,
        mode,
        hasMultipleServices,
        closeModal,
        selectedChoice,
        setSelectedChoice,
        cashierProducts,
        currentStep,
        productsCategories,
        productsSubCategories,
      })}
      {openModalAddNewItemDropdown && (
        <GenericModalContainer>
          <GenericModal
            closeGenericModal={() => setOpenModalAddNewItemDropdown(false)}
            component={modalCreateNewCategoryParams.component}
            params={modalCreateNewCategoryParams}
          />
        </GenericModalContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  hasUserAccessToProductionFeature: isUserAllowedToAccessProduction(state.baseReducer.userRights),
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductModal);
