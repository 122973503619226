import { isEmpty } from 'lodash';
import React from 'react';

import { Tooltip } from '@commons/utils/styledLibraryComponents';
import ComponentLoader from '@commons/DeepsightComponents/ComponentLoader';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

import {
  Container,
  ContentContainer,
  LoadingContainer,
  StatsContainer,
  TitleContainer,
  TooltipIcon,
} from './styledComponents';
import { formatNumber } from '@commons/DisplayNumber';

export const STATS_DATA_TYPE = {
  CURRENCY: 'currency',
  PERCENT: 'percent',
};

const Metrics = (props) => {
  const {
    isLoading,
    className,
    customStyle,
    title,
    tooltipText,
    data,
    currency: { numberDecimals },
    color,
  } = props;

  const renderTooltipIcon = () => <TooltipIcon src={'/images/inpulse/info-dmgrey-small.svg'} />;

  const buildDisplayedValue = ({ value, type }) => {
    if (value == null) {
      return '-';
    }

    if (type === STATS_DATA_TYPE.PERCENT) {
      return `${formatNumber(value, 2)}%`;
    }

    return formatNumber(value, numberDecimals);
  };

  return (
    <Container className={className} style={customStyle}>
      {isLoading && (
        <LoadingContainer>
          <ComponentLoader height={16} width={16} />
        </LoadingContainer>
      )}
      {!isLoading && (
        <ContentContainer>
          <TitleContainer>
            <Text
              color={ENUM_COLORS.DARKER}
              font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {title}
            </Text>
            {!isEmpty(tooltipText) && (
              <Tooltip renderTooltipTrigger={renderTooltipIcon} text={tooltipText} displayBigger />
            )}
          </TitleContainer>
          <StatsContainer>
            <Text
              color={color}
              font={ENUM_FONTS.TITLE_HEIGHT_24_BOLD}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {buildDisplayedValue(data)}
            </Text>
          </StatsContainer>
        </ContentContainer>
      )}
    </Container>
  );
};

export default Metrics;
