import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  width: auto;

  display: flex;
  justify-content: start;
  flex-direction: column;
  column-gap: 16px;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: center;
  padding-top: 16px;
  margin-bottom: 56px;
`;

export const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
`;

export const ToggleContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  justify-content: space-between;
  width: 460px;
  padding: 0px 42px;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '16px')};
`;

export const RadioChoice = styled.div`
  width: 230px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  align-items: center;
`;
