import styled from 'styled-components';

export const Container = styled.div`
  padding: 16px;

  background-color: ${(props) => props.theme.colors.greys.lightest};
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.greys.light};
  min-height: 96px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
`;

export const ContentContainer = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TitleContainer = styled.div`
  height: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TooltipIcon = styled.img`
  margin-top: -4px;
`;

export const StatsContainer = styled.div`
  gap: 8px;
`;
