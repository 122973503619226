import PropTypes from 'prop-types';
import React from 'react';

import { StyledText } from './styledComponents';

import { getTheme } from '../utils/theme';

export const ENUM_COLORS = {
  BLACK: 'BLACK',
  BLUE: 'BLUE',
  DARK: 'DARK',
  DARKER: 'DARKER',
  DARKEST: 'DARKEST',
  HIGHLIGHTED_BLUE: 'HIGHLIGHTED_BLUE',
  INFO_BLUE: 'INFO_BLUE',
  INFO_DARK_GREEN: 'INFO_DARK_GREEN',
  INFO_GREEN: 'INFO_GREEN',
  INFO_LIGHT_ORANGE: 'INFO_LIGHT_ORANGE',
  INFO_LIGHT_RED: 'INFO_LIGHT_RED',
  INFO_ORANGE: 'INFO_ORANGE',
  INFO_RED: 'INFO_RED',
  IP_BLACK_1: 'IP_BLACK_1',
  LGREY: 'LGREY',
  DGREY: 'DGREY',
  LIGHT: 'LIGHT',
  LIGHTER: 'LIGHTER',
  LIGHTEST: 'LIGHTEST',
  LIGHT_MEDIUM: 'LIGHT_MEDIUM',
  LIGHTY: 'LIGHTY',
  MEDIUM: 'MEDIUM',
  PRIMARY: 'PRIMARY',
  PRIMARY_DARK: 'PRIMARY_DARK',
  PRIMARY_LIGHT: 'PRIMARY_LIGHT',
  PRIMARY_LIGHTER: 'PRIMARY_LIGHTER',
  PRIMARY_LIGHT_OPACITY: 'PRIMARY_LIGHT_OPACITY',
  SECONDARY: 'SECONDARY',
  SECONDARY_DARK: 'SECONDARY_DARK',
  SECONDARY_LIGHT: 'SECONDARY_LIGHT',
  IP_BLACK_4: 'IP_BLACK_4',
  IP_BLACK_5: 'IP_BLACK_5',
};

export const ENUM_FONTS = {
  H1: 'H1',
  H1_INTER: 'H1_INTER',
  H1_INTER_NON_BOL: 'H1_INTER_NON_BOL',
  H2: 'H2',
  H2_INTER: 'H2_INTER',
  H3: 'H3',
  H3_BOLD_70: 'H3_BOLD_70',
  H3_INTER: 'H3_INTER',
  H4: 'H4',
  H5: 'H5',
  H6: 'H6',
  KPI_SMALL_HEIGHT_23: 'KPISMALLHEIGHT23',
  KPI_BIG: 'KPI_BIG',
  KPI_SMALL: 'KPI_SMALL',
  TEXT_BIG: 'TEXT_BIG',
  TEXT_BIG_BOLD: 'TEXT_BIG_BOLD',
  TEXT_BIG_BOLD_HEIGHT_14: 'TEXT_BIG_BOLD_HEIGHT_14',
  TEXT_BIG_BOLD_HEIGHT_16: 'TEXT_BIG_BOLD_HEIGHT_16',
  TEXT_BIG_HEIGHT_14: 'TEXT_BIG_HEIGHT_14',
  TEXT_BIG_HEIGHT_16: 'TEXT_BIG_HEIGHT_16',
  TEXT_BIG_HEIGHT_20: 'TEXT_BIG_HEIGHT_20',
  TEXT_BIG_HEIGHT_24: 'TEXT_BIG_HEIGHT_24',
  TEXT_BIG_HEIGHT_24_BOLD: 'TEXT_BIG_HEIGHT_24_BOLD',
  TEXT_BIG_HEIGHT_26: 'TEXT_BIG_HEIGHT_26',
  TEXT_BIG_MICRO_BOLD: 'TEXT_BIG_MICRO_BOLD',
  TEXT_COMPONENT_LABEL: 'TEXT_COMPONENT_LABEL',
  TEXT_MICRO: 'TEXT_MICRO',
  TEXT_MICRO_HEIGHT_12: 'TEXT_MICRO_HEIGHT_12',
  TEXT_MICRO_HEIGHT_16: 'TEXT_MICRO_HEIGHT_16',
  TEXT_MICRO_HEIGHT_16_WEIGHT_700_BOLD: 'TEXT_MICRO_HEIGHT_16_WEIGHT_700_BOLD',
  TEXT_MICRO_WIDTH_8_HEIGHT_16_WEIGHT_700_BOLD: 'TEXT_MICRO_WIDTH_8_HEIGHT_16_WEIGHT_700_BOLD',
  TEXT_MICRO_WEIGHT_40: 'TEXT_MICRO_WEIGHT_40',
  TEXT_MIDDLE: 'TEXT_MIDDLE',
  TEXT_MIDDLE_BOLD: 'TEXT_MIDDLE_BOLD',
  TEXT_MIDDLE_NORMAL: 'TEXT_MIDDLE_NORMAL',
  TEXT_SMALL: 'TEXT_SMALL',
  TEXT_SMALL_BOLD: 'TEXT_SMALL_BOLD',
  TEXT_SMALL_HEIGHT_12: 'TEXT_SMALL_HEIGHT_12',
  TEXT_SMALL_HEIGHT_14: 'TEXT_SMALL_HEIGHT_14',
  TEXT_SMALL_HEIGHT_14_WEIGHT_60: 'TEXT_SMALL_HEIGHT_14_WEIGHT_60',
  TEXT_SMALL_HEIGHT_16: 'TEXT_SMALL_HEIGHT_16',
  TEXT_SMALL_HEIGHT_24: 'TEXT_SMALL_HEIGHT_24',
  TEXT_SMALL_WEIGHT_60: 'TEXT_SMALL_WEIGHT_60',
  TEXT_SMALL_HEIGHT_14_WEIGHT_600: 'TEXT_SMALL_HEIGHT_14_WEIGHT_600',
  TEXT_SMALL_HEIGHT_14_WEIGHT_700_BOLD: 'TEXT_SMALL_HEIGHT_14_WEIGHT_700_BOLD',
  TEXT_SMALL_WEIGHT_700_HEIGHT_16_BOLD: 'TEXT_SMALL_WEIGHT_700_HEIGHT_16_BOLD',
  TEXT_SMALL_WEIGHT_600_HEIGHT_16: 'TEXT_SMALL_WEIGHT_600_HEIGHT_16',
  TITLE: 'TITLE',
  TITLE_HEIGHT_24_BOLD: 'TITLE_HEIGHT_24_BOLD',
};

export const CUSTOM_STYLE_FOR_DROPDOWN_TEXT = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '304px',
};

const getThemeFonts = (theme) => ({
  H1: theme.fonts?.h1,
  H1_INTER: theme.fonts?.h1Inter,
  H1_INTER_NON_BOLD: theme.fonts.h1InterNonBold,
  H2: theme.fonts?.h2,
  H2_INTER: theme.fonts?.h2Inter,
  H3: theme.fonts?.h3,
  H3_BOLD_700: theme.fonts?.h3Bold700,
  H3_INTER: theme.fonts?.h3Inter,
  H4: theme.fonts?.h4,
  H5: theme.fonts?.h5,
  H6: theme.fonts?.h6,
  KPI_SMALL_HEIGHT_23: theme.fonts?.kpiSmallHeight23,
  KPI_BIG: theme.fonts?.kpiBig,
  KPI_SMALL: theme.fonts?.kpiSmall,
  TEXT_BIG: theme.fonts?.textBig,
  TEXT_BIG_BOLD: theme.fonts?.textBigBold,
  TEXT_BIG_BOLD_HEIGHT_14: theme.fonts?.textBigBoldHeight14,
  TEXT_BIG_BOLD_HEIGHT_16: theme.fonts?.textBigBoldHeight16,
  TEXT_BIG_HEIGHT_14: theme.fonts?.textBigHeight14,
  TEXT_BIG_HEIGHT_16: theme.fonts?.textBigHeight16,
  TEXT_BIG_HEIGHT_20: theme.fonts?.textBigHeight20,
  TEXT_BIG_HEIGHT_24: theme.fonts?.textBigHeight24,
  TEXT_BIG_HEIGHT_24_BOLD: theme.fonts?.textBigHeight24Bold,
  TEXT_BIG_HEIGHT_26: theme.fonts?.textBigHeight26,
  TEXT_BIG_MICRO_BOLD: theme.fonts?.textBigMicroBold,
  TEXT_COMPONENT_LABEL: theme.label?.font,
  TEXT_MICRO: theme.fonts?.textMicro,
  TEXT_MICRO_HEIGHT_12: theme.fonts?.textMicroHeight12,
  TEXT_MICRO_HEIGHT_16: theme.fonts?.textMicroHeight16,
  TEXT_MICRO_HEIGHT_16_WEIGHT_700_BOLD: theme.fonts?.textMicroHeight16Weight700Bold,
  TEXT_MICRO_WIDTH_8_HEIGHT_16_WEIGHT_700_BOLD: theme.fonts?.textMicroWidth8Height16Weight700Bold,
  TEXT_MICRO_WEIGHT_400: theme.fonts?.textMicroWeight400,
  TEXT_MIDDLE: theme.fonts?.textMiddle,
  TEXT_MIDDLE_BOLD: theme.fonts?.textMiddleBold,
  TEXT_MIDDLE_NORMAL: theme.fonts?.textMiddleNormal,
  TEXT_SMALL: theme.fonts?.textSmall,
  TEXT_SMALL_BOLD: theme.fonts?.textSmallBold,
  TEXT_SMALL_HEIGHT_12: theme.fonts?.textSmallHeight12,
  TEXT_SMALL_HEIGHT_14: theme.fonts?.textSmallHeight14,
  TEXT_SMALL_HEIGHT_14_WEIGHT_600: theme.fonts?.textSmallHeight14Weight600,
  TEXT_SMALL_HEIGHT_14_WEIGHT_700_BOLD: theme.fonts?.textSmallHeight14Weight700Bold,
  TEXT_SMALL_HEIGHT_16: theme.fonts?.textSmallHeight16,
  TEXT_SMALL_HEIGHT_24: theme.fonts?.textSmallHeight24,
  TEXT_SMALL_WEIGHT_600: theme.fonts?.textSmallWeight600,
  TEXT_SMALL_WEIGHT_700_HEIGHT_16_BOLD: theme.fonts?.textSmallWeight700Height16Bold,
  TEXT_SMALL_WEIGHT_600_HEIGHT_16: theme.fonts?.textSmallWeight600Height16,
  TITLE: theme.fonts?.title,
  TITLE_HEIGHT_24_BOLD: theme.fonts.titleHeight24Bold,
});

const getThemeColors = (theme) => ({
  BLACK: theme.colors?.black,
  BLUE: theme.colors?.greys?.blue,
  DARK: theme.colors?.greys?.dark,
  DARKER: theme.colors?.greys?.darker,
  DARKEST: theme.colors?.greys?.darkest,
  HIGHLIGHTED_BLUE: theme.colors?.highlightedBlue,
  INFO_BLUE: theme.colors?.infoBlue,
  INFO_DARK_GREEN: theme.colors?.infoDarkGreen,
  INFO_GREEN: theme.colors?.infoGreen,
  INFO_LIGHT_ORANGE: theme.colors?.infoLightOrange,
  INFO_LIGHT_RED: theme.colors?.infoLightRed,
  INFO_ORANGE: theme.colors?.infoOrange,
  INFO_RED: theme.colors?.infoRed,
  IP_BLACK_1: theme.colors.blacks.ipBlack1,
  IP_BLACK_4: theme.colors.blacks.ipBlack4,
  IP_BLACK_5: theme.colors.blacks.ipBlack5,
  LGREY: theme.colors?.lgrey,
  DGREY: theme.colors?.dgrey,
  LIGHT: theme.colors?.greys?.light,
  LIGHTER: theme.colors?.greys?.lighter,
  LIGHTEST: theme.colors?.greys?.lightest,
  LIGHT_MEDIUM: theme.colors?.greys?.lightMedium,
  LIGHTY: theme.colors?.greys?.lighty,
  MEDIUM: theme.colors?.greys?.medium,
  PRIMARY: theme.colors?.brand?.primary,
  PRIMARY_DARK: theme.colors?.brand?.primaryDark,
  PRIMARY_LIGHT: theme.colors?.brand?.primaryLight,
  PRIMARY_LIGHTER: theme.colors?.brand?.primaryLighter,
  PRIMARY_LIGHT_OPACITY: theme.colors?.brand?.primaryLightOpacity,
  SECONDARY: theme.colors?.brand?.secondary,
  SECONDARY_DARK: theme.colors?.brand?.secondaryDark,
  SECONDARY_LIGHT: theme.colors?.brand?.secondaryLight,
});

const Text = ({
  style,
  color,
  font,
  fontWeight,
  fontSize,
  fontLineHeight,
  children,
  disabled = false,
}) => {
  const theme = getTheme();

  const getColor = () => {
    const colors = getThemeColors(theme);

    if (disabled) {
      return colors.DARK;
    }

    if (colors[color]) {
      return colors[color];
    }

    if (color && color.includes('#')) {
      return color;
    }

    return colors.DARKEST;
  };

  const getFont = () => {
    const fonts = getThemeFonts(theme);

    return fonts[font] || `normal ${fontWeight} ${fontSize}px/${fontLineHeight}px inter`;
  };

  return (
    <StyledText
      color={getColor(color)}
      font={getFont(font, { fontWeight, fontSize, fontLineHeight })}
      style={style}
    >
      {children}
    </StyledText>
  );
};

Text.propTypes = {
  font: PropTypes.oneOf(Object.values(ENUM_FONTS)),
  color: PropTypes.oneOfType([PropTypes.oneOf(Object.values(ENUM_COLORS)), PropTypes.string]),
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  fontLineHeight: PropTypes.number,
  style: PropTypes.objectOf(PropTypes.string),
};

Text.defaultProps = {
  style: {},
  fontSize: 14,
  fontWeight: 500,
  fontLineHeight: 16,
  color: ENUM_COLORS.DARKEST,
};

export default Text;
