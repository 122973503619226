import * as yup from 'yup';
import i18next from 'i18next';

import { canEditStorageArea } from '@selectors/actions/storageAreaActions';

// COMMONS
import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

const STORAGE_AREA_INPUTS = {
  NAME: 'name',
  STORES: 'stores',
  STORE: 'store',
};

export const FIELDS_TO_CHECK = ['name', 'stores'];

export const STORAGE_AREA_INFOS_INPUTS = (props) => {
  const {
    // For edition
    storeId,
    storeName,
    // For creation
    isCreation,
    stores,
    // From redux
    authorizedActions,
  } = props || {};

  let items = stores;

  if (!isCreation) {
    items = !!storeId ? [{ id: storeId, name: storeName }] : [];
  }

  return [
    {
      type: INPUT_TYPES.MULTI_SELECT,
      ...(isCreation && {
        tooltipText: i18next.t('ADMIN.STORAGE_AREAS.STORES_CREATION_DROPDOWN_TOOLTIP_TEXT'),
      }),
      name: STORAGE_AREA_INPUTS.STORES,
      label: isCreation ? i18next.t('GENERAL.STORES') : i18next.t('GENERAL.STORE'),
      width: INPUT_WIDTH.LARGE,
      iconSrc: '/images/inpulse/pin-black-small.svg',
      isRequired: true,
      items,
      isDisabled: () => !isCreation,
      rule: yup.array(yup.object()).required(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
          field: i18next.t('GENERAL.STORES'),
        }),
      ),
    },
    {
      type: INPUT_TYPES.TEXT,
      name: STORAGE_AREA_INPUTS.NAME,
      width: INPUT_WIDTH.LARGE,
      isRequired: true,
      isDisabled: () => !isCreation && !canEditStorageArea(authorizedActions),
      label: `* ${i18next.t('GENERAL.NAME')}`,
      rule: yup.string().required(
        i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_REQUIRED_ERROR', {
          field: i18next.t('GENERAL.NAME'),
        }),
      ),
    },
  ];
};

export const STORAGE_AREA_FORM_INPUTS = (props) => [...STORAGE_AREA_INFOS_INPUTS(props)];
