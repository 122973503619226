import { connect } from 'react-redux';
import { get } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState, useMemo } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import {
  CATEGORY_TYPES_OBJECT,
  getPropertyNoneValue,
  SUB_CATEGORY_TYPES_OBJECT,
} from '@commons/constants/categoryTypes';
import normalizeStringValue from '@commons/utils/normalizeStringValue';

import { getClientInfo } from '@selectors/client';

import clientService from '@services/client';

import { DYNAMIC_PROPERTIES } from '@admin/commons/dynamicModalProperties';
import { getNewCategorySubcategoryModalParams } from '@admin/products/products/components/CreateProductModal/utils/modalConfigurations';

import { Container, FormContainer, Section, SectionInfo, Title } from './styledComponents';
import { fetchBrandsOfClient, fetchProductsCategoriesOfClient } from './common/services';
import {
  ProductBrandsInput,
  ProductCategoryInput,
  ProductDlcInput,
  ProductNameInput,
  ProductPriceInput,
  ProductDeliveryPriceInput,
  ProductSkuInput,
  ProductSubCategoryInput,
  ProductVatInput,
  ProductDeliveryVatInput,
  EmptyInput,
} from './common/inputs';
import utils from './utils';

/******************/
/* Render Methods */
/******************/

/**
 * Get the custom list of inputs to allow in the form according the client settings (hasMultipleBrands)
 *
 * @param {Boolean} hasMultipleBrands - Whether the client is configured with multiple brands
 * @param {Boolean} hasMultipleChannels - Whether the client is configured with multiple channels
 *
 * @returns {Inputs[]} The list of custom inputs to display
 */
export const getCustomListInputs = (hasMultipleBrands, hasMultipleChannels, currency) => {
  let productPriceRowInputs = [
    [
      {
        ...ProductPriceInput(currency),
        required: true,
      },
      { ...ProductVatInput(), required: true },
    ],
  ];

  if (hasMultipleChannels) {
    productPriceRowInputs = [
      [
        {
          ...ProductPriceInput(currency),
          required: true,
        },
        { ...ProductVatInput(), required: true },
        ProductDeliveryPriceInput(currency),
      ],
      [ProductDeliveryVatInput(), EmptyInput],
    ];
  }

  if (!hasMultipleBrands) {
    return [
      [
        { ...ProductNameInput(), required: true },
        ProductCategoryInput(),
        ProductSubCategoryInput(),
      ],
      [
        ProductSkuInput(),
        ProductDlcInput(),
        {
          ...ProductPriceInput(currency),
          required: true,
        },
      ],
      [
        { ...ProductVatInput(), required: true },
        hasMultipleChannels ? ProductDeliveryPriceInput(currency) : EmptyInput,
        hasMultipleChannels ? ProductDeliveryVatInput() : EmptyInput,
      ],
    ];
  }

  return [
    [{ ...ProductNameInput(), required: true }, ProductBrandsInput(), ProductCategoryInput()],
    [ProductSubCategoryInput(), ProductSkuInput(), ProductDlcInput()],
    ...productPriceRowInputs,
  ];
};

/**
 * Render the product form section with the different inputs that should be displayed
 *
 * @param {Props} props                                 - The props sent to method
 * @param {Product} props.product                       - The product's data that should be displayed
 * @param {Function} props.onProductChange              - Method used to set the local state of the product variable
 * @param {ProductCategory[]} props.productsCategories  - The list of possible product categories to assign to the product
 * @param {Boolean} props.isProductSynced               - Whether the product is sync with a cashier product
 * @param {Boolean} props.hasMultipleBrands             - Whether the client is configured with multiple brands
 * @param {Boolean} props.hasMultipleChannels             - Whether the client is configured with multiple channels
 * @param {Boolean} props.brands                        - The list of possible brands associated to assign to the product
 */
export function renderForm(props) {
  const { hasMultipleBrands, hasMultipleChannels, product, currency } = props;

  const productFieldsFormBySection = getCustomListInputs(
    hasMultipleBrands,
    hasMultipleChannels,
    currency,
  );

  return (
    <div>
      {productFieldsFormBySection.map((section, indexSection) => (
        <Section key={indexSection} small={section.length < 3}>
          {section.map((item, indexItem) => {
            const ComponentName = item.component;

            return (
              <SectionInfo key={indexItem}>
                <ComponentName
                  {...item.props(props)}
                  label={item.name}
                  value={get(product, `${item.keyProperty}`, '')}
                />
              </SectionInfo>
            );
          })}
        </Section>
      ))}
    </div>
  );
}

/*************************/
/* Main Component Method */
/*************************/

export function ProductInfoSection(props) {
  const {
    product,
    onProductChange,
    client: { clientId, hasMultipleBrands, hasMultipleChannels },
    isReadOnly,
    isProductSynced,
    currency,
    showErrorMessage,
    showSuccessMessage,
    pageLoading,
    pageLoaded,
    productsCategories,
    productsSubCategories,
    setProductsCategories,
    setProductsSubCategories,
  } = props;

  const [brands, setBrands] = useState([]);

  const [openModalAddNewItemDropdown, setOpenModalAddNewItemDropdown] = useState(false);
  const [itemDropdownPropertyName, setItemDropdownPropertyName] = useState('');

  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const [clientCurrency, setClientCurrency] = useState('');

  const categoryNone = useMemo(
    () => productsCategories.find(({ name }) => name === getPropertyNoneValue()),
    [productsCategories],
  );
  const subCategoryNone = useMemo(
    () => productsSubCategories.find(({ name }) => name === getPropertyNoneValue()),
    [productsSubCategories],
  );

  useEffect(() => {
    setClientCurrency(currency);

    // Make sure to display the correct price excluding VAT
    const priceHT = utils.getPriceExcludingVAT(product);
    const deliveryPriceHT = utils.getPriceExcludingVAT(product, true);
    onProductChange({ ...product, priceHT, deliveryPriceHT });

    if (hasMultipleBrands) {
      fetchBrandsOfClient(clientId, setBrands);
    }

    fetchProductsCategoriesOfClient(clientId, setProductsCategories, setProductsSubCategories);
  }, []);

  useEffect(() => {
    if (openModalAddNewItemDropdown) {
      const modalCreateNewCategoryParams = getNewCategorySubcategoryModalParams(
        inputValue,
        errorMessage,
        closeCleanUp,
        handleInputChange,
        itemDropdownPropertyName,
        handleSaveNewItemDropdown,
      );

      props.openGenericModal(modalCreateNewCategoryParams);
    }
  }, [inputValue, openModalAddNewItemDropdown]);

  const handleInputChange = (value) => {
    setInputValue(value);

    if (!value) {
      setErrorMessage(i18next.t('GENERAL.REQUIRED_FILED_ERROR_MESSAGE'));

      return;
    }

    const alreadyExists = productsCategories.some(
      ({ name }) => normalizeStringValue(name) === normalizeStringValue(value),
    );

    if (alreadyExists) {
      setErrorMessage(
        itemDropdownPropertyName === 'category'
          ? i18next.t('GENERAL.MODAL_CATEGORY_NAME_ALREADY_USED')
          : i18next.t('GENERAL.MODAL_SUBCATEGORY_NAME_ALREADY_USED'),
      );

      return;
    }

    setErrorMessage(null);
  };

  const closeCleanUp = () => {
    setErrorMessage(null);
    setInputValue('');

    setOpenModalAddNewItemDropdown(false);
  };

  const handleNewCategoryCreation = async () => {
    try {
      pageLoading();

      const createdCategory = await clientService.createCategory(
        clientId,
        inputValue.trim(),
        CATEGORY_TYPES_OBJECT.PRODUCT,
      );

      const updatedCategories = [...productsCategories, createdCategory];

      setProductsCategories(updatedCategories);

      onProductChange({
        ...product,
        category: createdCategory.name,
        categoryId: createdCategory.id,
      });

      showSuccessMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_SUCCESS'));
    } catch {
      onProductChange({
        ...product,
        category: categoryNone.name,
        categoryId: categoryNone.id,
      });

      showErrorMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_ERROR'));
    } finally {
      setOpenModalAddNewItemDropdown(false);
      setInputValue('');
      pageLoaded();
    }
  };

  const handleNewSubCategoryCreation = async () => {
    try {
      pageLoading();

      const createdSubCategory = await clientService.createSubCategory(
        clientId,
        inputValue.trim(),
        SUB_CATEGORY_TYPES_OBJECT.PRODUCT,
      );

      const updatedSubCategories = [...productsSubCategories, createdSubCategory];

      setProductsSubCategories(updatedSubCategories);

      onProductChange({
        ...product,
        category: createdSubCategory.name,
        categoryId: createdSubCategory.id,
      });

      showSuccessMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_SUCCESS'));
    } catch {
      onProductChange({
        ...product,
        subCategory: subCategoryNone.name,
        subCategoryId: subCategoryNone.id,
      });

      showErrorMessage(i18next.t('ADMIN.RECIPES.CATEGORY_CREATION_ERROR'));
    } finally {
      setOpenModalAddNewItemDropdown(false);
      setInputValue('');
      pageLoaded();
    }
  };

  const handleSaveNewItemDropdown = async () => {
    switch (itemDropdownPropertyName) {
      case DYNAMIC_PROPERTIES.CATEGORY:
        await handleNewCategoryCreation();
        return;
      case DYNAMIC_PROPERTIES.SUB_CATEGORY:
        await handleNewSubCategoryCreation();
        return;
      default:
        return;
    }
  };

  return (
    <Container>
      <Title>{i18next.t('GENERAL.INFORMATIONS')}</Title>
      <FormContainer>
        {renderForm({
          product,
          onProductChange,
          productsCategories,
          productsSubCategories,
          isProductSynced,
          hasMultipleBrands,
          hasMultipleChannels,
          brands,
          isReadOnly,
          setOpenModalAddNewItemDropdown,
          setItemDropdownPropertyName,
          currency,
          params: { clientCurrency },
        })}
      </FormContainer>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductInfoSection);
