import { connect } from 'react-redux';
import { keys, get, orderBy, isEmpty } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';
import React, { Fragment, useState, useEffect, useContext } from 'react';

import { openSmallModal } from '@actions/modal';
import { showConfirmationMessage } from '@actions/messageconfirmation';

import { Button, Dropdown, Franco } from '@commons/utils/styledLibraryComponents';
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import { getTheme } from '@commons/utils/theme';
import { LeftRightSplitter } from '@commons/LeftRightSplitter';
import { numberToFixed } from '@commons/utils/format';
import { SingleDatePicker } from '@commons/DatePickers/SingleDatePicker';
import DisplayNumber from '@commons/DisplayNumber';
import NavigationBreadCrumb from '@commons/Breadcrumb/NavigationBreadCrumb';

import { ForecastsContext } from '@context/ForecastsContext';

import { getClientInfo } from '@selectors/client';
import { getPrevHorizon } from '@selectors/featureProps';
import { getSalesPointStores } from '@selectors/stores';

import { productMix as productMixService } from '@services/productMix';
import { weatherday as weatherDayService } from '@services/weatherday';
import { weatherStation as weatherStationService } from '@services/weatherStation';
import storeService from '@services/store';

import getStatsFromData from '../../utils/getStatsFromData';

import { Container, Content, MixContainer, SettingContainer } from './styledComponents';
import EventsLabel from './components/EventsLabel';
import Mix from './components/Mix';
import ProductMixExportModal from './components/ProductMixExportModal';
import WeatherLabel from './components/WeatherLabel';

const DEFAULT_CATEGORY = 'none value';

export async function fetchWeatherByDay(store, currentDate, showErrorMessage) {
  const formattedDate = moment
    .tz(currentDate, store.timezone)
    .format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY);

  try {
    const weatherStation = await weatherStationService.getWeatherForecastStation(
      store.locationValue,
    );

    if (!weatherStation) {
      return [];
    }

    return weatherDayService.getWeatherForecast(
      weatherStation.id,
      store.timezone,
      formattedDate,
      formattedDate,
    );
  } catch (err) {
    showErrorMessage(i18next.t('FORECAST.PRODUCT_MIX.SUMMARY_WEATHER_FETCH_FAILURE'));

    return [];
  }
}

export function fetchEventsFromStore(storeId, currentDate, showErrorMessage) {
  try {
    return storeService.getEventsOfStore(storeId, currentDate);
  } catch (err) {
    showErrorMessage(i18next.t('FORECAST.PRODUCT_MIX.SUMMARY_EVENTS_FETCH_FAILURE'));

    return [];
  }
}
export async function fetchForecastsProductMix(storeId, date, useTtc, showMessage) {
  try {
    const { ca, turnoverExcludingTaxes, activity, productsByCategory } =
      await productMixService.getForecastsProductMix(storeId, date);

    const formattedProductMixData = keys(productsByCategory).reduce((result, category) => {
      const categoryName =
        category === DEFAULT_CATEGORY ? i18next.t('GENERAL.NONE_VALUE') : category;

      if (!result.hasOwnProperty(category)) {
        result[categoryName] = {};
      }

      const data = productsByCategory[category];

      const products = data.products.map((product) => ({
        id: product.id,
        name: product.name,
        category: categoryName,
        previousSales: get(product, 'stats.previousSales', []),
        ...getStatsFromData(product),
      }));

      result[categoryName] = {
        name: categoryName,
        products: orderBy(products, 'mix', 'desc'),
        previousSales: get(data, 'stats.previousSales', []),
        ...getStatsFromData(data),
      };

      return result;
    }, {});

    return {
      topline: isEmpty(productsByCategory) ? null : useTtc ? ca : turnoverExcludingTaxes,
      activity: activity || {},
      productsByCategory: formattedProductMixData,
    };
  } catch (err) {
    showMessage(i18next.t('FORECAST.PRODUCT_MIX.FETCH_LIST_FAILURE'), 'error');

    return {
      topline: 0,
      activity: {},
      productsByCategory: {},
    };
  }
}

export const ProductMix = (props) => {
  const {
    currency,
    match,
    stores,
    showMessage,
    prevHorizon,
    openModalExportInfo,
    client: { useTtc, forecastProperty, turnoverName },
  } = props;

  const path = get(match, 'path');

  const contextHandler = useContext(ForecastsContext);

  const [topline, setTopline] = useState(0);
  const [selectedStore, setSelectedStore] = useState(null);
  const [currentDate, setCurrentDate] = useState(moment());
  const [productMixData, setProductMixData] = useState([]);
  const [activityMetrics, setActivityMetrics] = useState({});
  const [francoButtons, setFrancoButtons] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isRetrievingProductMix, setIsRetrievingProductMix] = useState(false);

  // Auto select first user store on loading page
  useEffect(() => {
    if (!stores || !contextHandler) {
      return;
    }

    setIsLoading(true);

    let currentStore;

    if (!isEmpty(stores) && isEmpty(contextHandler.selectedStore)) {
      currentStore = stores[0];
      contextHandler.handleStoreSelection(stores[0]);
    } else {
      currentStore = contextHandler.selectedStore;
    }

    setCurrentDate(moment.tz(currentStore.timezone));
    setSelectedStore({ ...currentStore, storeId: currentStore.id, storeName: currentStore.name });
  }, [!!contextHandler && contextHandler.selectedStore]);

  // Fetch product mix forecasts whenever storeId or date changes
  useEffect(() => {
    if (!selectedStore || isRetrievingProductMix) {
      return;
    }

    setIsRetrievingProductMix(true);

    (async function loadData() {
      const result = await fetchForecastsProductMix(
        selectedStore.storeId || selectedStore.id,
        currentDate,
        useTtc,
        showMessage,
      );

      setTopline(result.topline);

      setActivityMetrics(result.activity);

      setProductMixData(result.productsByCategory);

      setIsRetrievingProductMix(false);
    })();

    setTopline(null);
  }, [selectedStore, currentDate]);

  useEffect(() => {
    if (!selectedStore || !currentDate) {
      return;
    }

    (async function loadData() {
      setIsLoading(true);
      const francoButtonSettings = [];

      const events = await fetchEventsFromStore(selectedStore.storeId, currentDate, showMessage);
      if (!!events.length) {
        francoButtonSettings.push({
          id: 'event',
          title: i18next.t('GENERAL.EVENTS'),
          icon: '/images/inpulse/beach-with-umbrella.svg',
          render: () => <EventsLabel events={events} />,
        });
      }

      if (selectedStore) {
        const weatherByDay = await fetchWeatherByDay(selectedStore, currentDate, showMessage);

        if (weatherByDay.length) {
          const weather = {
            ...weatherByDay[0],
            img: get(weatherByDay[0], 'lnkWeathericonWeatherdayrel.img'),
          };

          francoButtonSettings.push({
            id: 'weather',
            title: i18next.t('GENERAL.WEATHER'),
            icon: '/images/inpulse/sun-behind-cloud-small.svg',
            render: () => <WeatherLabel weather={weather} />,
          });
        }
      }

      setFrancoButtons(francoButtonSettings);
    })();

    setIsLoading(false);
  }, [selectedStore, currentDate]);

  const handleSelectStore = (selectedStore) => {
    setSelectedStore({
      ...selectedStore,
      storeId: selectedStore.id,
      storeName: selectedStore.name,
    });

    if (contextHandler.handleStoreSelection) {
      contextHandler.handleStoreSelection(selectedStore);
    }

    setTopline(0);
    setProductMixData([]);
  };

  const openExportModal = () => {
    openModalExportInfo({
      component: ProductMixExportModal,
      customStyle: {
        marginTop: '10%',
        overflow: 'initial',
        borderRadius: '5px',
      },
      useTtc,
      forecastProperty,
      storeId: selectedStore.id,
      maxDaysInFuture: prevHorizon.future,
      turnoverName,
      currentDate,
    });
  };

  if (isLoading || !selectedStore.storeId) {
    return <Fragment></Fragment>;
  }

  const filteredStores = stores.filter((store) => store.active);

  const theme = getTheme();

  const selectedStoreDrodpown = filteredStores.find((store) => store.id === selectedStore.storeId);

  return (
    <>
      <NavigationBreadCrumb featurePath={path} />
      <Container>
        <Content>
          <LeftRightSplitter
            left={
              <SettingContainer>
                <Dropdown
                  iconSrc={'/images/inpulse/pin-black-small.svg'}
                  isDisabled={isRetrievingProductMix}
                  items={filteredStores}
                  searchPlaceholder={i18next.t('GENERAL.SEARCH')}
                  selectedItem={selectedStoreDrodpown}
                  onSelectionChange={(selectedItem, _, isUnselected) => {
                    if (isUnselected) {
                      return;
                    }

                    handleSelectStore(selectedItem);
                  }}
                />
                <SingleDatePicker
                  date={currentDate}
                  disabled={isRetrievingProductMix}
                  maxFutureDate={moment.tz(selectedStore.timezone).add(prevHorizon.future, 'days')}
                  maxPastDate={moment.tz(selectedStore.timezone)}
                  timezone={selectedStore.timezone}
                  onDateChange={setCurrentDate}
                />
                <Franco
                  buttons={francoButtons}
                  color={theme.colors.white}
                  content={<DisplayNumber number={topline} displayCurrencyCode />}
                  infoTooltip={`${i18next.t(
                    useTtc
                      ? 'FORECAST.PRODUCT_MIX.TURNOVER_BOX_TOOLTIP_TEXT'
                      : 'FORECAST.PRODUCT_MIX.TURNOVER_EXCLUDING_TAXES_BOX_TOOLTIP_TEXT',
                  )}`}
                  title={`${i18next.t(
                    useTtc ? 'GENERAL.TURNOVER_TAX_INCLUDED' : 'GENERAL.TURNOVER_EX_TAX',
                  )} :`}
                  type="plain"
                  hasBorder
                />
                <Franco
                  background={theme.colors.greys.dark}
                  color={theme.colors.white}
                  content={
                    <DisplayNumber number={activityMetrics.forecastedMargin} displayCurrencyCode />
                  }
                  infoTooltip={`${i18next.t(
                    'FORECAST.PRODUCT_MIX.ACTIVITY_BOX_FORECAST_MARGIN_TOOLTIP_TEXT',
                    {
                      currency: currency.alphabeticCode,
                    },
                  )} \n ${i18next.t(
                    'FORECAST.PRODUCT_MIX.ACTIVITY_BOX_PERCENTAGE_MARGIN_TOOLTIP',
                  )}`}
                  labelContent={numberToFixed(activityMetrics.averageMargin, 2, '-', ' %')}
                  title={`${i18next.t('FORECAST.PRODUCT_MIX.ACTIVITY_BOX_FORECAST_MARGIN')} :`}
                  type="plain"
                  hasBorder
                />
              </SettingContainer>
            }
            right={
              <div>
                <Button
                  fontSize={14}
                  formatText={false}
                  handleClick={openExportModal}
                  icon={'/images/inpulse/file-download-white-small.svg'}
                  label={i18next.t('GENERAL.EXPORT')}
                />
              </div>
            }
          />

          <MixContainer>
            <Mix
              {...props}
              currentDate={currentDate}
              isLoading={isRetrievingProductMix}
              mixData={productMixData}
            />
          </MixContainer>
        </Content>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
  stores: getSalesPointStores(state.baseReducer.activeStores),
  prevHorizon: getPrevHorizon(state.baseReducer.userRights),
  weatherStation: state.baseReducer.weatherStation,
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showMessage: (message, type) => {
    dispatch(showConfirmationMessage(message, type));
  },
  openModalExportInfo: (params) => {
    dispatch(openSmallModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductMix);
