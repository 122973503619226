import { useState } from 'react';

import { ADMIN_FEATURE_PATHS, FORECAST_FEATURE_PATHS } from '../commons/utils/featurePaths';
import { CentralKitchenProductProvider } from './CentralKitchenProductContext';
import { CentralKitchenRecipeProvider } from './CentralKitchenRecipeContext';
import { ENUM_QUERY_PARAMS } from '../hooks/useListViewQueryParams';
import { ForecastsProvider } from './ForecastsContext';
import { IngredientProvider } from './IngredientContext';
import { ProductProvider } from './ProductContext';
import { RecipeProvider } from './RecipeContext';
import { StorageAreaProvider } from '@context/StorageAreaContext';
import { SupplierProductsProvider } from './SupplierProductsContext';
import DefaultContext from './DefaultContext';

const providers = {
  DashboardForecastsContainer: {
    path: FORECAST_FEATURE_PATHS.FORECAST_FORECAST,
    Component: ForecastsProvider,
  },
  ProductMix: { path: FORECAST_FEATURE_PATHS.FORECAST_FORECAST, Component: ForecastsProvider },
  SupplierProductsList: {
    path: ADMIN_FEATURE_PATHS.ADMIN_SUPPLIERS_SUPPLIER_PRODUCTS,
    Component: SupplierProductsProvider,
  },
  IngredientList: {
    path: ADMIN_FEATURE_PATHS.ADMIN_PRODUCTS_INGREDIENTS,
    Component: IngredientProvider,
  },
  RecipeList: {
    path: ADMIN_FEATURE_PATHS.ADMIN_PRODUCTS_RECIPES,
    Component: RecipeProvider,
  },
  CentralKitchenRecipeList: {
    path: ADMIN_FEATURE_PATHS.ADMIN_KITCHEN_PRODUCTS_RECIPES,
    Component: CentralKitchenRecipeProvider,
  },
  ProductList: {
    path: ADMIN_FEATURE_PATHS.ADMIN_PRODUCTS,
    Component: ProductProvider,
  },
  CentralKitchenProductList: {
    path: ADMIN_FEATURE_PATHS.ADMIN_KITCHEN_PRODUCTS_PRODUCTS,
    Component: CentralKitchenProductProvider,
  },
  StorageAreaList: {
    path: ADMIN_FEATURE_PATHS.ADMIN_INVENTORIES_STORAGE_AREAS,
    Component: StorageAreaProvider,
  },
};

export const getProviders = (path) =>
  Object.values(providers).find(({ path: providerPath }) => path.includes(providerPath)) || {
    Component: DefaultContext,
  };

export const PersistentFilterTypes = {
  LIST_VIEW: 'list-view',
  APPLIED: 'applied',
  ADVANCED: 'advanced',
};

export const getListViewPersistentFilters = (context) => [
  {
    contextParam: context.search,
    updateFunction: context.setSearch,
    queryParam: ENUM_QUERY_PARAMS.SEARCH,
    filterType: PersistentFilterTypes.LIST_VIEW,
  },
  {
    contextParam: context.orderBy,
    updateFunction: context.setOrderBy,
    queryParam: ENUM_QUERY_PARAMS.ORDER_BY,
    filterType: PersistentFilterTypes.LIST_VIEW,
  },
  {
    contextParam: context.orderType,
    updateFunction: context.setOrderType,
    queryParam: ENUM_QUERY_PARAMS.ORDER_TYPE,
    filterType: PersistentFilterTypes.LIST_VIEW,
  },
  {
    contextParam: context.maxPerPage,
    updateFunction: context.setMaxPerPage,
    queryParam: ENUM_QUERY_PARAMS.MAX_PER_PAGE,
    filterType: PersistentFilterTypes.LIST_VIEW,
  },
  {
    contextParam: context.currentPage,
    updateFunction: context.setCurrentPage,
    queryParam: ENUM_QUERY_PARAMS.CURRENT_PAGE,
    filterType: PersistentFilterTypes.LIST_VIEW,
  },
];

export const getListViewState = () => {
  const [search, setSearch] = useState('');
  const [orderBy, setOrderBy] = useState('name');
  const [orderType, setOrderType] = useState('asc');
  const [maxPerPage, setMaxPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);

  return {
    search,
    setSearch,
    orderBy,
    setOrderBy,
    orderType,
    setOrderType,
    maxPerPage,
    setMaxPerPage,
    currentPage,
    setCurrentPage,
  };
};
