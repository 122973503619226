import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button, Tooltip } from '@commons/utils/styledLibraryComponents';

import { getClientInfo } from '@selectors/client';

import { product as productService } from '@services/product';

import { Container } from './styledComponents';

/**
 * Assert that the general form of that page can be saved (product & associated recipes)
 *
 * @param {Object} product - The product to save
 * @param {Object} recipeEatInside - The recipe 'SP' deepsight channel associated to the product
 * @param {Object} recipeTakeAway - The recipe 'LIV' deepsight channel associated to the product
 *
 * @returns {boolean} Whether the form is valid
 */
export function isFormValid(product, composition, selectedCompoType) {
  const parsedShelfLife = parseFloat(product.shelfLife);

  if (
    product.price == null ||
    product.price === '' ||
    !product.name ||
    product.vatRate == null ||
    product.vatRate === '' ||
    (!!product.shelfLife && (parsedShelfLife <= 0 || !Number.isInteger(parsedShelfLife))) ||
    (selectedCompoType.id !== 'NONE' && isEmpty(composition))
  ) {
    return false;
  }

  return true;
}

/**
 * Handle the save of a given product
 *
 * @param {Object} product                          - The product's data
 * @param {Object} recipes                          - The list of associated recipes
 * @param {Object[]} productCashierProductMappings  - The list of associated product cashier product mappings
 * @param {Object} dispatchers                      - Dispatch methods to set loading state of the page while saving
 *
 * @returns {void}
 */
export async function saveProduct(product, productCashierProductMappings) {
  return productService
    .updateProduct(product, productCashierProductMappings)
    .then((result) => result)
    .catch(() => {
      throw new Error('La sauvegarde du produit a échoué');
    });
}

/**
 * Handle the save of the entire form (product & its recipes)
 *
 * @param {Props} props - The props linked to the component
 *
 * @returns {void}
 */
export async function handleSave({
  history,
  productId,
  product,
  client: { clientId },
  cashierProducts,
  showMessage,
  pageLoading,
  pageLoaded,
  productsCategories,
  productsSubCategories,
}) {
  pageLoading();

  if (!productId || productId === 'create') {
    product.clientId = clientId;
  }

  product.priceWithTaxes = product.price;

  product.deliveryPriceWithTaxes = product.deliveryPrice;

  product.shelfLife = product.shelfLife || null;

  const categoryNone = productsCategories.find(
    ({ name }) => name === i18next.t('GENERAL.NONE_VALUE'),
  );

  const subCategoryNone = productsSubCategories.find(
    ({ name }) => name === i18next.t('GENERAL.NONE_VALUE'),
  );

  product.categoryId = !product.categoryId ? categoryNone.id : product.categoryId;
  product.subCategoryId = !product.subCategoryId ? subCategoryNone.id : product.subCategoryId;

  const productCashierProductMappings = cashierProducts.map((cashierProduct) => ({
    productId: product.id,
    cashierProductId: cashierProduct.id,
    priceSync: cashierProduct.priceSync,
  }));

  try {
    await saveProduct(product, productCashierProductMappings);

    showMessage('Modifications enregistrées');
    history.goBack();
  } catch (error) {
    showMessage(error.message, 'error');
  } finally {
    pageLoaded();
  }
}

export const ProductDetailsBottomBar = (props) => {
  const { product, showMessage, setOpenEnableModal, composition, selectedCompoType } = props;

  const isEnableButtonDisabled =
    !!product.lnkEntityProductrel && !product.lnkEntityProductrel.active;

  if (!product.active) {
    if (isEnableButtonDisabled) {
      return (
        <Container>
          <Tooltip
            displayBigger={true}
            place={'left'}
            renderTooltipTrigger={() => (
              <Button
                buttonCustomStyle={{ margin: '8px 0 0 0' }}
                color={'inpulse-default'}
                handleClick={() => {}}
                icon={'/images/inpulse/power-white-small.svg'}
                isDisabled={true}
                label={i18next.t('GENERAL.ACTIVATE')}
              />
            )}
            text={i18next.t('ADMIN.PRODUCTS.ENABLED_DISABLE_TOOLTIP')}
          />
        </Container>
      );
    }

    return (
      <Button
        buttonCustomStyle={{ margin: 'auto' }}
        handleClick={() => setOpenEnableModal(true)}
        icon={'/images/inpulse/power-white-small.svg'}
        label={i18next.t('GENERAL.ACTIVATE')}
      />
    );
  }
  return (
    <Container>
      <Button
        buttonCustomStyle={{ margin: 'auto' }}
        handleClick={() =>
          isFormValid(product, composition, selectedCompoType)
            ? handleSave({ ...props, showMessage })
            : {}
        }
        icon={'/images/inpulse/save-white-small.svg'}
        isDisabled={!isFormValid(product, composition, selectedCompoType)}
        label={i18next.t('GENERAL.SAVE')}
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

export default connect(mapStateToProps)(ProductDetailsBottomBar);
