import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';
import moment from 'moment-timezone';

const FILE_EXTENSION_REGEX = /(?:\.([^.]+))?$/; // Regular expression to capture file extension

/**
 * Given an URL, trigger a download
 * @param url
 * @param fileName
 * @return {Promise<null>}
 */
export const downloadFileFromUrl = async (url, fileName = '') => {
  if (!url) {
    return null;
  }

  const extension = FILE_EXTENSION_REGEX.exec(url);

  if (!extension) {
    return;
  }

  const data = await fetch(url);

  const blob = await data.blob();

  downloadFile(blob, `${fileName}.${extension[1]}`);
};

export const getFileNameFromUrl = (
  url,
  { withExtension = false, shouldDecodeURI = false } = {},
) => {
  if (!url) {
    return null;
  }

  let fileName = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'));
  const extension = FILE_EXTENSION_REGEX.exec(url)[1];

  if (shouldDecodeURI) {
    fileName = decodeURIComponent(fileName);
  }

  return withExtension ? `${fileName}.${extension}` : fileName;
};

export const getFileExtension = (url) => {
  if (!url) {
    return null;
  }

  const extension = FILE_EXTENSION_REGEX.exec(url);

  if (!extension) {
    return '';
  }

  return extension[1];
};

/**
 * Truncate file name by splitting it in two and adding "..." in the middle,
 * so that the file name is exactly maxLength long without the extension
 *
 * @example truncateFileNameInTheMiddle('exampleWithVeryLongFileName.txt', 20) => 'example...meFileName.txt'
 * @param {string} fileName
 * @param {number} maxLength
 *
 * @return {string|null}
 */
export const truncateFileNameInTheMiddle = (fileName, maxLength = 20) => {
  if (!fileName) {
    return null;
  }

  const lastDotIndex = fileName.lastIndexOf('.');
  const extension = fileName.substring(lastDotIndex);
  const nameWithoutExtension = fileName.substring(0, lastDotIndex);

  // If name without extension is shorter than the limit, simply return the name
  if (nameWithoutExtension.length <= maxLength) {
    return fileName;
  }

  const charsToShow = maxLength - 3; // 3 for "..."
  const frontChars = Math.ceil(charsToShow / 2); // First half
  const backChars = Math.floor(charsToShow / 2); // Second half

  const truncatedName =
    nameWithoutExtension.substring(0, frontChars) +
    '...' +
    nameWithoutExtension.substring(nameWithoutExtension.length - backChars);

  return truncatedName + extension;
};

/**
 * Trigger a download on the browser side
 * @param {object} file - Blob / Buffer data
 * @param {string} fileName - Name of the file
 */
export const downloadFile = (file, fileName) => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(file);
  a.download = fileName;
  document.body.appendChild(a);

  // Trigger download
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(a.href);
};

/**
 * Encode file name from URL if needed
 *
 * @param {string | null} url
 *
 * @return {string | null}
 */
export const encodeFileNameInUrl = (url) => {
  if (!url) {
    return null;
  }

  const urlSegments = url.split('/');

  // Get file name from CDN url
  const fileName = urlSegments.pop();

  const charactersNeedingEncoding = /[^A-Za-z0-9\-_.~]/;

  const encodedFileName = charactersNeedingEncoding.test(fileName)
    ? encodeURIComponent(fileName)
    : fileName;

  urlSegments.push(encodedFileName);

  return urlSegments.join('/');
};

/**
 * Formats a date into a specific string format.
 * The format is as follows:
 * date -> 231224080955 (which stands for 23/12/2024 08:09:55)
 *
 * @param {Date} date - The date to format.
 * @returns {string} The formatted date string.
 */
const formatDateToConcatenatedString = (date) =>
  moment(date).format(DATE_DISPLAY_FORMATS.STICKED_DAY_MONTH_YEAR_HOUR_MINUTE_SECOND);

/**
 * Adds a date suffix to the given file name to ensure uniqueness.
 * The suffix is formatted as follows: date => date_231224080955 (which stands for 23/12/2024 08:09:55)
 *
 * @param {string} fileName - The original file name.
 * @returns {string} The file name with the date suffix added.
 */
export const addDateSuffixToFileName = (fileName, date) => {
  const dateSuffix = formatDateToConcatenatedString(date);
  const fileNameParts = fileName.split('.');
  const fileExtension = fileNameParts.pop();
  const baseFileName = fileNameParts.join('.');
  return `${baseFileName}_${dateSuffix}.${fileExtension}`;
};

export const removeConcatenatedDateSuffixFromFileName = (fileName) => {
  if (!fileName) {
    return '';
  }

  const fileNameParts = fileName.split('.');
  const fileExtension = fileNameParts.length > 1 ? fileNameParts.pop() : '';
  const baseFileName = fileNameParts.join('.');

  const updatedFileName = baseFileName.replace(/(_\d{12})$/, '');

  return fileExtension ? `${updatedFileName}.${fileExtension}` : updatedFileName;
};

export const FILE_TYPES = {
  images: {
    mime: {
      all: 'image/*',
      jpeg: 'image/jpeg', // Include .jpeg and .jpg files
      png: 'image/png',
      gif: 'image/gif',
      svg: 'image/svg+xml',
      webp: 'image/webp',
      bmp: 'image/bmp',
    },
    extensions: {
      jpeg: '.jpeg',
      jpg: '.jpg',
      png: '.png',
      gif: '.gif',
      svg: '.svg',
      webp: '.webp',
      bmp: '.bmp',
    },
  },
  videos: {
    mime: {
      all: 'video/*',
      mp4: 'video/mp4',
      ogg: 'video/ogg',
      webm: 'video/webm',
      mkv: 'video/x-matroska',
    },
    extensions: {
      mp4: '.mp4',
      ogg: '.ogv',
      webm: '.webm',
      mkv: '.mkv',
    },
  },
  audio: {
    mime: {
      all: 'audio/*',
      mp3: 'audio/mpeg',
      ogg: 'audio/ogg',
      wav: 'audio/wav',
      webm: 'audio/webm',
    },
    extensions: {
      mp3: '.mp3',
      ogg: '.ogg',
      wav: '.wav',
      webm: '.webm',
    },
  },
  documents: {
    mime: {
      pdf: 'application/pdf',
      doc: 'application/msword', // DOC
      docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // DOCX
      xls: 'application/vnd.ms-excel', // XLS
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // XLSX
      ppt: 'application/vnd.ms-powerpoint', // PPT
      pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', // PPTX
      rtf: 'application/rtf', // RTF
    },
    extensions: {
      pdf: '.pdf',
      doc: '.doc',
      docx: '.docx',
      xls: '.xls',
      xlsx: '.xlsx',
      ppt: '.ppt',
      pptx: '.pptx',
      rtf: '.rtf',
    },
  },
  text: {
    mime: {
      plain: 'text/plain',
      csv: 'text/csv',
      html: 'text/html',
    },
    extensions: {
      plain: '.txt',
      csv: '.csv',
      html: '.html',
    },
  },
};
