import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  padding: 88px 264px 0px 264px;

  display: flex;
  flex-direction: column;

  overflow: auto;

  align-items: center;
`;

export const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 10%);

  > div {
    z-index: ${(props) => (props.theme.colors ? undefined : 15)};
    box-shadow: none;
  }
`;

export const NavigationPage = styled.div`
  height: 100%;
  color: ${(props) => props.theme.colors?.greys.darker || '#6c778d'};

  ${(props) =>
    props.theme.fonts
      ? `font: ${props.theme.fonts.textBig};`
      : `font-size: 14px;
  line-height: 16px;
  font-weight: normal;`}
  text-align: center;
  display: flex;
  margin-right: 40px;
  align-items: center;

  ${(props) =>
    props.selected &&
    css`
      color: ${(props) => props.theme.colors?.greys.darkest || 'blue'};
      padding-top: 3px;
      border-bottom: ${(props) => `solid ${props.theme.colors.brand.primary || 'blue'}`};
    `};

  &:hover {
    color: ${(props) => props.theme.colors?.greys.darkest || 'blue'};
    cursor: pointer;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: end;
`;
