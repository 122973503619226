import i18next from 'i18next';

import { brand as brandService } from '@services/brand';
import { product as productService } from '@services/product';
import centralService from '@services/central';

/**********************/
/* Fetch Data Methods */
/**********************/

/**
 * Handle the fetch of product categories for the given clientId associated to the user
 *
 * @param {String} clientId                 - The client id on which retrieve the products categories
 * @param {Function} setProductsCategories  - Method used to set the local state of the productsCategories variable
 *
 * @returns {Object} - Object of two arrays corresponding to categories and subCategories of this client
 */
export const fetchProductsCategoriesOfClient = async (
  clientId,
  setProductsCategories,
  setProductsSubCategories,
  isKitchen = false,
) =>
  (isKitchen
    ? centralService.getCentralKitchenProductCategoriesByClientId(clientId)
    : productService.getProductsCategories(clientId)
  ).then((result) => {
    const formattedCategories = result.categories.map((category) => ({
      ...category,
      name: category.name || i18next.t('GENERAL.NONE_VALUE'),
    }));

    const formattedSubCategories = result.subCategories.map((subCategory, index) => ({
      ...subCategory,
      name: subCategory.name || i18next.t('GENERAL.NONE_VALUE'),
    }));

    setProductsCategories(formattedCategories);

    setProductsSubCategories(formattedSubCategories);

    return { categories: formattedCategories, subCategories: formattedSubCategories };
  });

/**
 * Render the product form section with the different inputs that should be displayed
 *
 * @param {String} clientId     - The client id on which retrieve the brands
 * @param {Function} setBrands  - Method used to set the local state of the brands variable
 */
export function fetchBrandsOfClient(clientId, setBrands) {
  return brandService.getBrandsOfClient(clientId).then((result) => setBrands(result));
}

export default {
  fetchProductsCategoriesOfClient,
  fetchBrandsOfClient,
};
