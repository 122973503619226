import i18next from 'i18next';

export const IMPORT_TYPES = {
  PRICE_IMPORT: 'price-import',
  SUPPLIER_PRODUCTS_IMPORT: 'supplier-products-import',
};

export const IMPORT_MODAL_STEPS = {
  SELECT_FILE: 'select-file',
  VALIDATE_FILE: 'validate-file',
  ERROR_FILE: 'error-file',
  VALIDATED_FILE: 'validated-file',
  WARNING: 'warning',
};

export const IMPORT_FILE_SHEETS_CONSTANTS = {
  CATEGORIES: {
    id: 'categories',
    name: 'Catégories',
    columns: {
      NAME: 'name',
    },
  },
  SUB_CATEGORIES: {
    id: 'subCategories',
    name: 'SousCatégories',
    columns: {
      NAME: 'name',
    },
  },
};

export const CAT_AND_SUB_CAT_TYPES = {
  CATEGORY: 'CATEGORY',
  SUB_CATEGORY: 'SUB_CATEGORY',
};

export const STORAGE_AREA_TYPE = {
  STORAGE_AREA: 'STORAGE_AREA',
};

export const INVENTORY_LIST_TEMPLATE_TYPE = {
  INVENTORY_LIST_TEMPLATE: 'INVENTORY_LIST_TEMPLATE',
};

export const NONE_GROUP = {
  id: 'none',
  name: i18next.t('GENERAL.SELECT_NONE_MASCULINE'),
};

export const AVAILABILITIES_TYPES = {
  AVAILABLE: 'available',
  DLC: 'hasDlc',
  STOCK: 'hasStock',
};
