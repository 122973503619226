import { get } from 'lodash';
import i18next from 'i18next';
import moment from 'moment-timezone';

import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

import { PROP_KEYS } from './sheets';

const DEFAULT_CATEGORY = 'none value';

const extractByStoreData = (store, groupsByStoreId, { productsByCategory }) => {
  const storeGroups = groupsByStoreId[store.id] || [];

  const formattedStoreGroups = storeGroups.join(', ');

  const storeData = [];

  Object.entries(productsByCategory).forEach(([category, { nestedData, stats }]) => {
    nestedData.forEach((productData) => {
      const categoryName =
        category === DEFAULT_CATEGORY ? i18next.t('GENERAL.NONE_VALUE') : category;

      storeData.push({
        [PROP_KEYS.STORE_NAME]: store.name,
        [PROP_KEYS.GROUPS]: formattedStoreGroups,
        [PROP_KEYS.PRODUCT_NAME]: productData.name,
        [PROP_KEYS.CATEGORY]: categoryName,
        [PROP_KEYS.SUB_CATEGORY]:
          get(productData, 'subCategory', null) || i18next.t('GENERAL.NONE_VALUE'),
        [PROP_KEYS.SOLD_QUANTITY]: get(productData, 'stats.unit', null),
        [PROP_KEYS.AVERAGE_PRICE]: get(productData, 'stats.priceExcludingTaxes', null),
        [PROP_KEYS.TURNOVER]: get(productData, 'stats.turnoverExcludingTaxes', null),
        [PROP_KEYS.CATEGORY_RATIO]: stats.mixExcludingTaxes || null,
        [PROP_KEYS.MIX_RATIO]: get(productData, 'stats.mixExcludingTaxes', null),
        [PROP_KEYS.FOOD_COST_PER_UNIT]: get(productData, 'stats.foodCostPerUnit', null),
        [PROP_KEYS.FOOD_COST_TOTAL]: get(productData, 'stats.foodCostTotal', null),
        [PROP_KEYS.MARGIN]: get(productData, 'stats.margin', null),
        [PROP_KEYS.MARGIN_RATIO]: get(productData, 'stats.marginRatio', null),
        [PROP_KEYS.INVENTORY_SHRINKAGE]: get(productData, 'stats.inventoryShrinkage', null),
        [PROP_KEYS.PRODUCT_LOSSES]: get(productData, 'stats.productLosses', null),
        [PROP_KEYS.INGREDIENT_LOSSES]: get(productData, 'stats.ingredientLosses', null),
        [PROP_KEYS.ADJUSTED_THEORETICAL_MARGIN]: get(
          productData,
          'stats.adjustedTheoreticalMargin',
          null,
        ),
      });
    });
  });

  return storeData;
};

const extractContextData = (store, context, { turnoverExcludingTaxes, margin }) => {
  const startDate = moment(context.startDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);
  const endDate = moment(context.endDate).format(DATE_DISPLAY_FORMATS.SLASHED_DAY_MONTH_YEAR);

  return {
    [PROP_KEYS.STORE_NAME]: store.name,
    [PROP_KEYS.START_DATE]: startDate,
    [PROP_KEYS.END_DATE]: endDate,
    [PROP_KEYS.TURNOVER]: turnoverExcludingTaxes,
    [PROP_KEYS.MARGIN]: margin.total,
    [PROP_KEYS.MARGIN_RATIO]: margin.ratioToTurnover,
  };
};

const getDetailedSheetData = () => [
  {
    [PROP_KEYS.SOLD_QUANTITY]: i18next.t('ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_SOLD_QTY'),
    [PROP_KEYS.AVERAGE_PRICE]: i18next.t(
      'ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_AVERAGE_PRICE',
    ),
    [PROP_KEYS.TURNOVER]: i18next.t(
      'ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_TURNOVER_EX_TAX',
    ),
    [PROP_KEYS.CATEGORY_RATIO]: i18next.t(
      'ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_CATEGORY_RATIO',
    ),
    [PROP_KEYS.MIX_RATIO]: i18next.t('ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_MIX_RATIO'),
    [PROP_KEYS.FOOD_COST_PER_UNIT]: i18next.t(
      'ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_FOOD_COST_UNIT',
    ),
    [PROP_KEYS.FOOD_COST_TOTAL]: i18next.t(
      'ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_FOOD_COST_TOTAL',
    ),
    [PROP_KEYS.MARGIN]: i18next.t('ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_MARGIN'),
    [PROP_KEYS.MARGIN_RATIO]: i18next.t(
      'ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_MARGIN_RATIO',
    ),
    [PROP_KEYS.INVENTORY_SHRINKAGE]: i18next.t(
      'ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_INVENTORY_SHRINKAGE',
    ),
    [PROP_KEYS.PRODUCT_LOSSES]: i18next.t(
      'ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_PRODUCT_LOSSES',
    ),
    [PROP_KEYS.INGREDIENT_LOSSES]: i18next.t(
      'ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_INGREDIENT_LOSSES',
    ),
    [PROP_KEYS.ADJUSTED_THEORETICAL_MARGIN]: i18next.t(
      'ANALYSIS.SALES.PAST_MIX_EXPORT_BY_STORE_COLUMN_ADJUSTED_THEORETICAL_MARGIN',
    ),
  },
];

export default {
  extractByStoreData,
  extractContextData,
  getDetailedSheetData,
};
