import i18next from 'i18next';

export const COMMON_COLUMNS = {
  NAME: () => ({
    name: i18next.t('ADMIN.RECIPES.EXPORT_COLUMN_NAME'),
    propertyKey: 'name',
  }),
  STATUS: () => ({
    name: i18next.t('GENERAL.STATUS'),
    propertyKey: 'status',
    transform: (value) => i18next.t(`${value ? 'GENERAL.ACTIVE' : 'GENERAL.INACTIVE'}`),
  }),
  CATEGORY: (isMainSheet) => ({
    name: i18next.t(
      isMainSheet
        ? 'ADMIN.RECIPES.EXPORT_COLUMN_CATEGORY'
        : 'ADMIN.RECIPES.COMPOSITION_EXPORT_RECIPE_CATEGORY_EXPORT',
    ),
    propertyKey: 'category',
  }),
  ASSOCIATED_PRODUCT: () => ({
    name: i18next.t('ADMIN.RECIPES.EXPORT_COLUMN_ASSOCIATED_PRODUCT'),
    propertyKey: 'associatedProductName',
  }),
  QUANTITY: (isMainSheet) => ({
    name: i18next.t(
      isMainSheet
        ? 'ADMIN.RECIPES.EXPORT_COLUMN_QUANTITY'
        : 'ADMIN.RECIPES.COMPOSITION_EXPORT_RECIPE_QUANTITY_EXPORT',
    ),
    propertyKey: 'quantity',
  }),
  UNIT: (isMainSheet) => ({
    name: i18next.t(
      isMainSheet
        ? 'ADMIN.RECIPES.EXPORT_COLUMN_UNIT'
        : 'ADMIN.RECIPES.COMPOSITION_EXPORT_RECIPE_UNIT_EXPORT',
    ),
    propertyKey: 'unit',
  }),
};

export const ALLERGENS_COLUMN = () => ({
  name: i18next.t('ADMIN.INGREDIENTS.LABEL_ALLERGENS'),
  propertyKey: 'allergensNames',
  transform: (items) => items.join(','),
});

export const BRANDS_COLUMN = () => ({
  name: i18next.t('GENERAL.BRAND_PLURAL'),
  propertyKey: 'brands',
  transform: (values) => {
    const brandNames = values.map(({ name }) => name);

    return brandNames.join(',');
  },
});

export const ON_SITE_COST_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COST_EX_TAX_ON_SITE'),
  propertyKey: 'onSiteCost',
  type: 'currency',
});

export const DELIVERY_COST_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COST_EX_TAX_DELIVERY'),
  propertyKey: 'deliveryCost',
  type: 'currency',
});

export const DEFAULT_COST_COLUMN = () => ({
  name: i18next.t('GENERAL.COST_EX_TAX'),
  propertyKey: 'defaultCost',
  type: 'currency',
});

// ----- Components Sheet Columns -----
export const COMPONENT_NAME_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_NAME_EXPORT'),
  propertyKey: 'componentName',
});

export const COMPONENT_TYPE_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_TYPE'),
  propertyKey: 'componentType',
});

export const COMPONENT_CATEGORY_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_CATEGORY_EXPORT'),
  propertyKey: 'componentCategory',
});

export const COMPONENT_QUANTITY_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_QUANTITY_EXPORT'),
  propertyKey: 'componentQuantity',
});

export const COMPONENT_UNIT_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_UNIT_EXPORT'),
  propertyKey: 'componentUnit',
});

export const COMPONENT_ALLERGENS_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_ALLERGENS'),
  propertyKey: 'componentAllergensNames',
  transform: (items) => items.join(','),
});

export const COMPONENT_USED_ON_SITE_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_USED_ON_SITE'),
  propertyKey: 'componentIsUsedOnSite',
  transform: (value) => i18next.t(`${value ? 'GENERAL.YES' : 'GENERAL.NO'}`).toUpperCase(),
});

export const COMPONENT_USED_FOR_DELIVERY_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_USED_FOR_DELIVERY'),
  propertyKey: 'componentIsUsedOnDelivery',
  transform: (value) => i18next.t(`${value ? 'GENERAL.YES' : 'GENERAL.NO'}`).toUpperCase(),
});

export const COMPONENT_DELIVERY_COST_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_COST_EX_TAX_DELIVERY'),
  propertyKey: 'componentDeliveryCost',
  type: 'currency',
});

export const COMPONENT_COST_COLUMN = (isInCentralMode, clientHasMultipleChannels) => ({
  name: i18next.t(
    isInCentralMode || !clientHasMultipleChannels
      ? 'ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_COST_EXPORT'
      : 'ADMIN.RECIPES.COMPOSITION_EXPORT_COMPONENT_COST_EX_TAX_ON_SITE',
  ),
  propertyKey: clientHasMultipleChannels ? 'componentOnSiteCost' : 'componentCost',
  type: 'currency',
});

export const RECIPE_DEFAULT_COST_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_RECIPE_COST_EXPORT'),
  propertyKey: 'defaultCost',
  type: 'currency',
});

export const INGREDIENT_NAME_COLUMN = () => ({
  name: i18next.t('GENERAL.INGREDIENT'),
  propertyKey: 'componentName',
});

export const INGREDIENT_CATEGORY_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_CATEGORY_EXPORT'),
  propertyKey: 'componentCategory',
});

export const INGREDIENT_UNIT_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_UNIT_EXPORT'),
  propertyKey: 'componentUnit',
});

export const INGREDIENT_QUANTITY_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_QUANTITY_EXPORT'),
  propertyKey: 'componentQuantity',
});

export const INGREDIENT_ALLERGENS_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_ALLERGENS'),
  propertyKey: 'componentAllergensNames',
  transform: (items) => items.join(','),
});

export const INGREDIENT_COST_COLUMN = (isInCentralMode, clientHasMultipleChannels) => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_COST_EXPORT'),
  propertyKey:
    isInCentralMode && clientHasMultipleChannels ? 'componentOnSiteCost' : 'componentCost',
  type: 'currency',
});

export const INGREDIENT_ON_SITE_QUANTITY_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_QUANTITY_ON_SITE_EXPORT'),
  propertyKey: 'ingredientOnSiteQuantity',
});

export const INGREDIENT_DELIVERY_QUANTITY_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_QUANTITY_ON_DELIVERY_EXPORT'),
  propertyKey: 'ingredientOnDeliveryQuantity',
});

export const INGREDIENT_ON_SITE_COST_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_COST_EX_TAX_ON_SITE'),
  propertyKey: 'componentOnSiteCost',
  type: 'currency',
});

export const INGREDIENT_DELIVERY_COST_COLUMN = () => ({
  name: i18next.t('ADMIN.RECIPES.COMPOSITION_EXPORT_INGREDIENT_COST_EX_TAX_DELIVERY'),
  propertyKey: 'componentDeliveryCost',
  type: 'currency',
});
