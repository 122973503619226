import { head } from 'lodash';
import i18next from 'i18next';

import { isUserAdmin, isUserDeepsight, isUserManager } from '@commons/utils/roles';
import sortActionsList from '@commons/utils/sortActionsList';

import { canDownloadProductTemplate, canImportProduct } from '@selectors/actions/productActions';

import {
  getManageCategoriesModalParams,
  getManageSubCategoriesModalParams,
  getAssignCategoriesModalParams,
  getAssignSubCategoriesModalParams,
} from '@commons/constants/categoryTypes';

import { getDeleteProductsModalParams, getDisableProductsModalParams } from './modalConfigurations';

/**
 * This function returns a boolean that indicates if the activation action of the product is disabled. (disabled if use only inactive elements)
 *
 * @param {[object]} products selected products
 * @returns boolean
 */
const isEnableActionDisabled = (products) =>
  products.some(({ lnkEntityProductrel }) => !!lnkEntityProductrel && !lnkEntityProductrel.active);

export const getActions = (props) => {
  const {
    filteringOnActive,
    renderButton,
    selectedItems,
    triggerExport,
    user,
    openGenericModal,
    handleProductsDeletion,
    disableProducts,
    getProductBatchCreationTemplate,
    getCentralKitchenProductBatchCreationTemplate,
    openProductsImportModal,
    handleEnableProduct,
    isInCentralKitchenOnlyPage,
    authorizedActions,
    openCategoriesBatchUpdateModal,
    openSubCategoriesBatchUpdateModal,
  } = props;

  const actions = [
    {
      id: 'Create',
      actionLabel: i18next.t('GENERAL.CREATE'),
      render: renderButton,
      fixed: true,
      handleAction: () => {},
    },
  ];

  if (selectedItems.length === 0) {
    actions.push(
      {
        id: 'action-update-category',
        actionLabel: i18next.t('GENERAL.UPDATE_CATEGORIES'),
        isHidden: () => isUserManager(user) || !filteringOnActive,
        handleAction: () =>
          openCategoriesBatchUpdateModal(false, [], getManageCategoriesModalParams()),
        actionIcon: () => '/images/inpulse/gear-ipblack-small.svg',
      },
      {
        id: 'action-update-sub-category',
        actionLabel: i18next.t('GENERAL.UPDATE_SUB_CATEGORIES'),
        isHidden: () => isUserManager(user) || !filteringOnActive,
        handleAction: () =>
          openSubCategoriesBatchUpdateModal(false, [], getManageSubCategoriesModalParams()),
        actionIcon: () => '/images/inpulse/gear-ipblack-small.svg',
      },
      {
        id: 'export-list',
        actionLabel: i18next.t('ORDERS.ORDERS.LIST_EXPORT'),
        handleAction: () => triggerExport(),
        actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      },
      {
        id: 'csm-download-template',
        actionLabel: i18next.t('ADMIN.PRODUCTS.DOWNLOAD_TEMPLATE_BATCH_CREATE_ACTION'),
        handleAction: getProductBatchCreationTemplate,
        actionIcon: () =>
          !canDownloadProductTemplate(authorizedActions)
            ? '/images/inpulse/file-download-lmgrey-small.svg'
            : '/images/inpulse/file-download-black-small.svg',
        isHidden: () =>
          !filteringOnActive ||
          isInCentralKitchenOnlyPage ||
          !canDownloadProductTemplate(authorizedActions),
        // TO-DO MS4-LOCAL-CATALOGS: Use this code to handle master catalog rights
        // isDisabled: () => !canDownloadProductTemplate(authorizedActions),
        // disabledTooltipText: () => i18next.t('GENERAL.ONLY_ADMIN_RIGHT'),
      },
      {
        id: 'csm-download-template-central',
        actionLabel: i18next.t(
          'ADMIN.PRODUCTS.DOWNLOAD_TEMPLATE_BATCH_CENTRAL_KITCHEN_CREATE_ACTION',
        ),
        handleAction: () => getCentralKitchenProductBatchCreationTemplate(),
        isHighlighted: () => true,
        actionIcon: () => '/images/inpulse/file-download-white-small.svg',
        isHidden: () => !isUserDeepsight(user) || !filteringOnActive || !isInCentralKitchenOnlyPage,
      },
      {
        id: 'csm-import-template',
        actionLabel: i18next.t('ADMIN.PRODUCTS.UPLOAD_TEMPLATE_BATCH_CREATE_ACTION'),
        handleAction: () => openProductsImportModal(),
        actionIcon: () =>
          !canImportProduct(authorizedActions)
            ? '/images/inpulse/file-upload-lmgrey-small.svg'
            : '/images/inpulse/file-upload-black-small.svg',
        isHidden: () =>
          !filteringOnActive || isInCentralKitchenOnlyPage || !canImportProduct(authorizedActions),
        // TO-DO MS4-LOCAL-CATALOGS: Use this code to handle master catalog rights
        // isDisabled: () => !canImportProduct(authorizedActions),
        // disabledTooltipText: () => i18next.t('GENERAL.ONLY_ADMIN_RIGHT'),
      },
      {
        id: 'csm-import-template-central',
        actionLabel: i18next.t(
          'ADMIN.PRODUCTS.UPLOAD_TEMPLATE_BATCH_CENTRAL_KITCHEN_CREATE_ACTION',
        ),
        handleAction: () => openProductsImportModal(),
        isHighlighted: () => true,
        actionIcon: () => '/images/inpulse/file-upload-white-small.svg',
        isHidden: () => !isUserDeepsight(user) || !filteringOnActive || !isInCentralKitchenOnlyPage,
      },
    );
  } else {
    const isSingleSelection = selectedItems.length === 1;

    actions.push(
      {
        id: 'assign-category',
        actionLabel: i18next.t('GENERAL.LIST_ACTION_ASSIGN_CATEGORY'),
        isHidden: () => isUserManager(user) || !filteringOnActive,
        handleAction: () =>
          openCategoriesBatchUpdateModal(true, selectedItems, getAssignCategoriesModalParams()),
        actionIcon: () => '/images/inpulse/link-black-small.svg',
      },
      {
        id: 'assign-sub-category',
        actionLabel: i18next.t('GENERAL.LIST_ACTION_ASSIGN_SUBCATEGORY'),
        isHidden: () => isUserManager(user) || !filteringOnActive,
        handleAction: () =>
          openSubCategoriesBatchUpdateModal(
            true,
            selectedItems,
            getAssignSubCategoriesModalParams(),
          ),
        actionIcon: () => '/images/inpulse/link-black-small.svg',
      },
      {
        id: 'disable-products',
        actionLabel: isSingleSelection
          ? i18next.t('ADMIN.PRODUCTS.DISABLE_PRODUCT')
          : i18next.t('ADMIN.PRODUCTS.DISABLE_PRODUCTS'),
        isHidden: () => !filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
        handleAction: (products) => {
          const params = getDisableProductsModalParams(
            products,
            disableProducts,
            isInCentralKitchenOnlyPage,
          );

          openGenericModal(params);
        },
        actionIcon: () => '/images/inpulse/power-black-small.svg',
        disabledTooltipText: () =>
          isSingleSelection
            ? i18next.t('ADMIN.PRODUCTS.DISABLE_PRODUCTS_DISABLED_TOOLTIP_SINGLE')
            : i18next.t('ADMIN.PRODUCTS.DISABLE_PRODUCTS_DISABLED_TOOLTIP_MULTIPLE'),
      },
      {
        id: 'enable-one-product',
        actionLabel: () => i18next.t('ADMIN.PRODUCTS.ENABLE_PRODUCT'),
        isDisabled: () => isEnableActionDisabled(selectedItems),
        disabledTooltipText: () => i18next.t('ADMIN.PRODUCTS.ENABLED_DISABLE_TOOLTIP'),
        actionIcon: () =>
          isEnableActionDisabled(selectedItems)
            ? '/images/inpulse/power-lmgrey-small.svg'
            : '/images/inpulse/power-black-small.svg',
        isHidden: () =>
          !isSingleSelection || filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
        handleAction: (products) => handleEnableProduct(head(products)),
      },
      {
        id: 'export-products',
        actionLabel: i18next.t('ADMIN.PRODUCTS.EXPORT_SELECTION'),
        handleAction: (items) => triggerExport(items),
        actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      },
      {
        id: 'csm-delete-products',
        actionLabel: isSingleSelection
          ? i18next.t('ADMIN.PRODUCTS.DELETE_PRODUCT')
          : i18next.t('ADMIN.PRODUCTS.DELETE_PRODUCTS'),
        handleAction: (products) => {
          const params = getDeleteProductsModalParams(
            products,
            handleProductsDeletion,
            isInCentralKitchenOnlyPage,
          );

          openGenericModal(params);
        },
        actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
        isHighlighted: () => true,
        isHidden: () => !isUserDeepsight(user) || filteringOnActive,
      },
    );
  }

  return sortActionsList(actions);
};

export const getRowActions = (props) => {
  const {
    filteringOnActive,
    user,
    openGenericModal,
    handleProductsDeletion,
    disableProducts,
    handleEnableProduct,
    isInCentralKitchenOnlyPage,
    openCategoriesBatchUpdateModal,
    openSubCategoriesBatchUpdateModal,
  } = props;

  const rowActions = [
    {
      id: 'assign-category',
      actionLabel: () => i18next.t('GENERAL.LIST_ACTION_ASSIGN_CATEGORY'),
      isHidden: () => isUserManager(user) || !filteringOnActive,
      handleAction: (product) =>
        openCategoriesBatchUpdateModal(true, [product], getAssignCategoriesModalParams()),
      actionIcon: () => '/images/inpulse/link-black-small.svg',
    },
    {
      id: 'assign-sub-category',
      actionLabel: () => i18next.t('GENERAL.LIST_ACTION_ASSIGN_SUBCATEGORY'),
      isHidden: () => isUserManager(user) || !filteringOnActive,
      handleAction: (product) =>
        openSubCategoriesBatchUpdateModal(true, [product], getAssignSubCategoriesModalParams()),
      actionIcon: () => '/images/inpulse/link-black-small.svg',
    },
    {
      id: 'disable-only-one',
      actionLabel: () => i18next.t('ADMIN.PRODUCTS.DISABLE_PRODUCT'),
      isHidden: () => !filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
      handleAction: (product) => {
        const params = getDisableProductsModalParams(
          [product],
          disableProducts,
          isInCentralKitchenOnlyPage,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/power-black-small.svg',
      disabledTooltipText: () =>
        i18next.t('ADMIN.PRODUCTS.DISABLE_PRODUCTS_DISABLED_TOOLTIP_SINGLE'),
    },
    {
      id: 'enable-one-product',
      actionLabel: () => i18next.t('ADMIN.PRODUCTS.ENABLE_PRODUCT'),
      isDisabled: (product) => isEnableActionDisabled([product]),
      disabledTooltipText: () => i18next.t('ADMIN.PRODUCTS.ENABLED_DISABLE_TOOLTIP'),
      actionIcon: (product) =>
        isEnableActionDisabled([product])
          ? '/images/inpulse/power-lmgrey-small.svg'
          : '/images/inpulse/power-black-small.svg',
      isHidden: () => filteringOnActive || (!isUserAdmin(user) && !isUserDeepsight(user)),
      handleAction: (product) => handleEnableProduct(product),
    },
    {
      id: 'delete-product',
      actionLabel: () => i18next.t('ADMIN.PRODUCTS.DELETE_PRODUCT'),
      handleAction: (product) => {
        const params = getDeleteProductsModalParams(
          [product],
          handleProductsDeletion,
          isInCentralKitchenOnlyPage,
        );

        openGenericModal(params);
      },
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      isHighlighted: () => true,
      isHidden: () => !isUserDeepsight(user) || filteringOnActive,
    },
  ];

  return rowActions;
};

export const getEmptyStateActions = (props) => {
  const {
    user,
    getCentralKitchenProductBatchCreationTemplate,
    openProductsImportModal,
    isInCentralKitchenOnlyPage,
    openCategoriesBatchUpdateModal,
    openSubCategoriesBatchUpdateModal,
  } = props;

  const actions = [
    {
      label: i18next.t('GENERAL.UPDATE_CATEGORIES'),
      isHidden: isUserManager(user),
      handleClick: () =>
        openCategoriesBatchUpdateModal(false, [], getManageCategoriesModalParams()),
      icon: '/images/inpulse/gear-ipblack-small.svg',
    },
    {
      label: i18next.t('GENERAL.UPDATE_SUB_CATEGORIES'),
      isHidden: isUserManager(user),
      handleClick: () =>
        openSubCategoriesBatchUpdateModal(false, [], getManageSubCategoriesModalParams()),
      icon: '/images/inpulse/gear-ipblack-small.svg',
    },
    {
      label: i18next.t('ADMIN.PRODUCTS.DOWNLOAD_TEMPLATE_BATCH_CENTRAL_KITCHEN_CREATE_ACTION'),
      handleClick: () => getCentralKitchenProductBatchCreationTemplate(),
      isHighlighted: () => true,
      icon: '/images/inpulse/file-download-white-small.svg',
      isHidden: !isUserDeepsight(user) || !isInCentralKitchenOnlyPage,
    },
    {
      label: i18next.t('ADMIN.PRODUCTS.UPLOAD_TEMPLATE_BATCH_CENTRAL_KITCHEN_CREATE_ACTION'),
      handleClick: () => openProductsImportModal(),
      isHighlighted: () => true,
      icon: '/images/inpulse/file-upload-white-small.svg',
      isHidden: !isUserDeepsight(user) || !isInCentralKitchenOnlyPage,
    },
  ];

  return sortActionsList(actions);
};
