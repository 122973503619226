import * as yup from 'yup';
import i18next from 'i18next';
import React from 'react';

import {
  FILE_TYPES,
  getFileNameFromUrl,
  removeConcatenatedDateSuffixFromFileName,
  truncateFileNameInTheMiddle,
} from '@commons/utils/fileUtils';
import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';
import { INVOICE_STATUS } from '@src/routes/invoice/invoiceControls/utils/constants';

const INPUTS = {
  FILENAME: 'fileName',
  DATE: 'date',
  NUMBER: 'number',
  TOTAL: 'total',
};

const ACTION_NAMES = {
  DOWNLOAD: 'download',
  UPLOAD: 'upload',
  REPLACE: 'replace',
  DELETE: 'delete',
};

export const FIELDS_TO_CHECK = [INPUTS.FILENAME, INPUTS.DATE, INPUTS.NUMBER, INPUTS.TOTAL];

const getInvoiceLinkDropdownActions = (
  { invoiceFileName, invoiceStatus },
  isReadOnly,
  uploadInvoice,
  downloadInvoice,
  deleteInvoice,
) => {
  const downloadAction = {
    name: ACTION_NAMES.DOWNLOAD,
    label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DOWNLOAD_FILE'),
    handleClick: downloadInvoice,
    icon: '/images/inpulse/file-download-black-small.svg',
  };

  const uploadAction = {
    name: ACTION_NAMES.UPLOAD,
    canSelectFile: true,
    acceptedFileTypes: [FILE_TYPES.documents.mime.pdf, FILE_TYPES.text.mime.csv].join(','),
    label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.UPLOAD_FILE'),
    handleClick: uploadInvoice,
    icon: '/images/inpulse/file-upload-black-small.svg',
  };

  const replaceAction = {
    name: ACTION_NAMES.REPLACE,
    canSelectFile: true,
    acceptedFileTypes: [FILE_TYPES.documents.mime.pdf, FILE_TYPES.text.mime.csv].join(','),
    label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.REPLACE_FILE'),
    handleClick: uploadInvoice,
    icon: '/images/inpulse/receipt-attachment.svg',
  };

  const deleteAction = {
    name: ACTION_NAMES.DELETE,
    label: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DELETE_FILE'),
    handleClick: deleteInvoice,
    icon: '/images/inpulse/trash-black-small.svg',
  };

  if (
    isReadOnly ||
    invoiceStatus === INVOICE_STATUS.PENDING ||
    invoiceStatus === INVOICE_STATUS.SUCCESS ||
    invoiceStatus === INVOICE_STATUS.FAILURE
  ) {
    return invoiceFileName ? [downloadAction] : [];
  }

  const actions = [downloadAction, replaceAction, deleteAction];

  return invoiceFileName ? actions : [uploadAction];
};

/**
 * @typedef {object} InvoiceControl
 * @property {string} link - Link to the invoice file
 * @property {string} status - Status of the invoice
 */

/**
 * @typedef {object} InvoiceControlInputConfiguration
 * @property {InvoiceControl} invoiceControl - Invoice control object
 * @property {object} currency - Currency object
 * @property {boolean} isReadOnly - Is the form read-only, handle disabled inputs
 * @property {string} fileBeingUploadedName - Name of the file being uploaded. If empty, use file name from invoice control link.
 * @property {Function} uploadInvoice - Callback when uploading a file
 * @property {Function} downloadInvoice - Callback when downloading the file
 * @property {Function} deleteInvoice - Callback when deleting the file
 */

/**
 * Get the inputs for the invoice control form
 * @param {InvoiceControlInputConfiguration} configuration - Configuration object
 *
 * @return {object[]}
 */
export const getInputs = ({
  invoiceControl = {},
  currency,
  isReadOnly,
  fileBeingUploadedName,
  uploadInvoice,
  downloadInvoice,
  deleteInvoice,
}) => {
  const fileName = fileBeingUploadedName || getFileNameFromUrl(invoiceControl.link, true);
  const truncatedFileName = truncateFileNameInTheMiddle(
    removeConcatenatedDateSuffixFromFileName(fileName),
  );

  const dropdownActions = getInvoiceLinkDropdownActions(
    {
      invoiceFileName: fileName,
      invoiceStatus: invoiceControl.status,
    },
    isReadOnly,
    uploadInvoice,
    downloadInvoice,
    deleteInvoice,
  );

  const errorParamsForTotalField = {
    field: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_TOTAL_FIELD', {
      currency: currency && currency.alphabeticCode,
    }),
    value: 0,
  };

  return [
    {
      name: INPUTS.NUMBER,
      type: INPUT_TYPES.TEXT,
      width: INPUT_WIDTH.LARGE,
      isDisabled: () => isReadOnly,
      label: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_NUMBER_FIELD'),
      isRequired: false,
    },
    {
      name: INPUTS.DATE,
      width: INPUT_WIDTH.LARGE,
      isDisabled: () => isReadOnly,
      label: `${i18next.t(
        'INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_DATE_FIELD',
      )} :`,
      type: INPUT_TYPES.SINGLE_DATEPICKER,
      showClearDate: false,
      rule: yup.date().nullable(),
    },
    {
      name: INPUTS.TOTAL,
      type: INPUT_TYPES.TEXT,
      width: INPUT_WIDTH.LARGE,
      isDisabled: () => isReadOnly,
      label: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_TOTAL_FIELD', {
        currency: currency && currency.alphabeticCode,
      }),
      rule: yup.lazy((value) =>
        value === ''
          ? yup.string()
          : yup
              .number()
              .nullable()
              .min(
                0,
                i18next.t(
                  'ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR',
                  errorParamsForTotalField,
                ),
              ),
      ),
      isRequired: false,
    },
    {
      name: INPUTS.FILENAME,
      type: INPUT_TYPES.TEXT_WITH_ACTION,
      button: {
        id: 1,
        text: <img src={'/images/inpulse/gear-white-small.svg'} />,
        isDisabled: isReadOnly,
      },
      width: INPUT_WIDTH.LARGE,
      label: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_LINK_FIELD'),
      isUnchangeable: true,
      isReadOnly: isReadOnly || invoiceControl.status === INVOICE_STATUS.FAILURE,
      value: truncatedFileName || '',
      tooltipText:
        !invoiceControl.status || invoiceControl.status !== INVOICE_STATUS.UNCONTROLLED
          ? i18next.t('ORDERS.ORDERS.FORM_INVOICE_FILE_TOOLTIP')
          : '',
      dropdownActions,
    },
  ];
};

export default {
  getInputs,
};
