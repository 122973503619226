import { connect } from 'react-redux';
import { difference, get, head, keyBy } from 'lodash';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { loading, loadingSuccess } from '@actions/loading';
import { openGenericModal, refreshGenericModal } from '@actions/modal';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

import { Button, ListView } from '@commons/utils/styledLibraryComponents';
import { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';
import {
  GENERIC_MODAL_CANCEL_BUTTON,
  GENERIC_MODAL_CONFIRM_BUTTON,
} from '@commons/Modals/GenericModal/genericModalActions';
import { getConfirmationModal } from '@commons/Modals/ConfirmationModal';
import { getPropertyNoneValue } from '@commons/constants/categoryTypes';
import { getTheme } from '@commons/utils/theme';
import { LISTVIEW_NO_TOP_PADDING } from '@commons/constants/listViewProps';
import EmptyState from '@commons/EmptyState';

import { canEditStorageArea } from '@selectors/actions/storageAreaActions';
import { getAuthorizedActions } from '@selectors/featureProps';
import { getClientInfo } from '@selectors/client';

import { storeSupplierProductMapping as storeSupplierProductMappingService } from '@services/storeSupplierProductMapping';
import storageAreaService from '@services/storageArea';

import DeepsightFiltersButton from '@admin/components/FilterButton';

import formatUtils from '@admin/inventories/utils/formatUtils';

import { getAssociateSupplierProductsModalConfig } from '../../utils/associateSupplierProductsModal/getAssociateSupplierProductsModalConfig';

import actionsUtils from '../utils/actionsUtils';
import columnsUtils from '../utils/columnsUtils';

import { Container } from './styledComponents';

/** MAPPINGS WITH SUPPLIER PRODUCTS */
const StorageAreaMappings = (props) => {
  const {
    authorizedActions,
    openGenericModal,
    refreshGenericModal,
    showSuccessMessage,
    showErrorMessage,
    client: { clientId, hasLocalCatalogs },
    isLoading,
    isCreation,
    existingStorageArea,
    storageAreaForm,
    formFields,
    pageLoading,
    pageLoaded,
    storeIds,
  } = props;

  const theme = getTheme();

  // States ListView
  const [columns] = useState(columnsUtils.getStorageAreaMappingsColumns(hasLocalCatalogs));
  const [actions, setActions] = useState([]);
  const [rowActions, setRowActions] = useState([]);
  const [displayEmptyState, setDisplayEmptyState] = useState(false);

  // Filters states
  const [filters, setFilters] = useState(null);
  const [applyFilters, setApplyFilters] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [categoriesToChoose, setCategoriesToChoose] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState({});
  const [subCategoriesToChoose, setSubCategoriesToChoose] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState({});

  const [mappings, setMappings] = useState([]);
  const [filteredMappings, setFilteredMappings] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  // Associations states
  const [availableSPForAssociation, setAvailableSPForAssociation] = useState([]);
  const [spToBeAdded, setSpToBeAdded] = useState([]);

  useEffect(() => {
    if (
      isLoading ||
      (isCreation && !storeIds.length) ||
      (existingStorageArea && !existingStorageArea.storeId)
    ) {
      return;
    }

    const existingMappings = get(existingStorageArea, 'supplierProductMappings', []);

    if (!existingMappings.length) {
      getSPAvailableForAssociation(isCreation ? storeIds : [existingStorageArea.storeId]);
      setDisplayEmptyState(true);
      return;
    }

    const formattedMappings = existingMappings.map(
      ({ supplierProductId, lnkSupplierProductStorageareamappingrel }) => {
        const {
          name,
          supplierId,
          supplierName,
          catalogId,
          catalogName,
          category,
          subCategory,
          price,
          isKitchen,
          active,
          id, // Needed to avoid console error "Encountered two children with the same key"
        } = lnkSupplierProductStorageareamappingrel;

        return {
          name,
          supplierId,
          supplierName,
          catalogId,
          catalogName,
          category: category || getPropertyNoneValue(),
          subCategory: subCategory || getPropertyNoneValue(),
          price,
          supplierProductId,
          isKitchen,
          active,
          id,
        };
      },
    );

    setMappings(formattedMappings);
    setFilteredMappings(formattedMappings);
  }, [isLoading, existingStorageArea]);

  useEffect(() => {
    if (formFields && formFields.supplierProductMappings) {
      setMappings(formFields.supplierProductMappings);
      setFilteredMappings(formFields.supplierProductMappings);
    }
  }, [formFields]);

  const refreshFilters = (currentMappings) => {
    const filtersData = currentMappings.reduce(
      (acc, { supplierId, supplierName, category, subCategory, catalogId, active }) => {
        if (!acc.filterSuppliers.some(({ id }) => id === supplierId)) {
          acc.filterSuppliers.push({
            id: supplierId,
            name: supplierName,
            active,
            catalogId,
          });
        }

        if (!acc.filterCategories.some(({ name }) => name === category)) {
          acc.filterCategories.push({ id: category, name: category });
        }

        if (!acc.filterSubCategories.some(({ name }) => name === subCategory)) {
          acc.filterSubCategories.push({ id: subCategory, name: subCategory });
        }

        return acc;
      },
      {
        filterSuppliers: [],
        filterCategories: [],
        filterSubCategories: [],
      },
    );

    setSuppliers(filtersData.filterSuppliers);
    setSelectedSuppliers(filtersData.filterSuppliers);

    setCategoriesToChoose(filtersData.filterCategories);
    setSelectedCategories(filtersData.filterCategories);

    setSubCategoriesToChoose(filtersData.filterSubCategories);
    setSelectedSubCategories(filtersData.filterSubCategories);
  };

  const handleAddAssociation = () => {
    if (!isCreation) {
      const spIdsToBeAdded = spToBeAdded.map(({ supplierProductId }) => supplierProductId);
      const mappingsSpIds = mappings.map(({ supplierProductId }) => supplierProductId);
      // Necessary fix since ListView keep ref of selected supplier products
      // If you simply re-open the modal and click add, the previously selected ones would be added again
      const updatedSPMappings = new Set([...mappingsSpIds, ...spIdsToBeAdded]);

      updateStorageAreaMappings(Array.from(updatedSPMappings));
      return;
    }

    // Same logic for creation
    const spMappingsKeyById = keyBy([...mappings, ...spToBeAdded], 'id');

    storageAreaForm.setValue('supplierProductMappings', Object.values(spMappingsKeyById));
  };

  const getSPAvailableForAssociation = async (storeIdsForFetch) => {
    pageLoading();

    try {
      const supplierProductToIgnore = isCreation
        ? storageAreaForm.getValues('supplierProductMappings')
        : mappings;

      const supplierProductIdsToIgnore = supplierProductToIgnore.map(
        ({ supplierProductId }) => supplierProductId,
      );

      const supplierProducts = await storeSupplierProductMappingService.getByStoreIds(
        storeIdsForFetch,
        supplierProductIdsToIgnore,
      );

      setAvailableSPForAssociation(supplierProducts);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  useEffect(() => {
    if (!isLoading && !mappings.length) {
      setDisplayEmptyState(true);
      return;
    }

    refreshFilters(mappings);
    setDisplayEmptyState(false);
  }, [mappings]);

  // Handle filters change
  useEffect(() => {
    // User has not applied the filters
    if (!applyFilters) {
      return;
    }

    const filteredData = mappings.filter(
      ({ supplierId, category, subCategory }) =>
        selectedSuppliers.some(({ id }) => id === supplierId) && // suppliers selector
        selectedCategories.some(({ name }) => category === name) && // categories selector
        selectedSubCategories.some(({ name }) => subCategory === name), // sub-categories selector
    );

    setFilteredMappings(filteredData);
  }, [applyFilters, formFields, selectedSuppliers]);

  useEffect(() => {
    setActions(
      actionsUtils.getStorageAreaMappingsGlobalActions({
        isLoading,
        selectedItems,
        openAssociationModal,
        handleRemoveAssociation,
        isRecipeAssociation: false,
        authorizedActions,
      }),
    );

    setRowActions(
      actionsUtils.getStorageAreaMappingsRowActions({
        handleRemoveAssociation,
        isRecipeAssociation: false,
        authorizedActions,
      }),
    );
  }, [filteredMappings, selectedItems, isLoading, availableSPForAssociation]);

  useEffect(() => {
    const params = getAssociateSupplierProductsModalConfig({
      hasLocalCatalogs,
      setSpToBeAdded,
      availableSPForAssociation,
      handleAddAssociation,
      title: i18next.t('ADMIN.STORAGE_AREAS.ADD_SUPPLIER_PRODUCTS_MODAL_TITLE'),
    });

    refreshGenericModal(params);
  }, [spToBeAdded, availableSPForAssociation]);

  useEffect(() => {
    if ((isCreation && !storeIds.length) || (existingStorageArea && !existingStorageArea.storeId)) {
      return;
    }
    // If mappings have been updated, it means that the user has added or removed a supplier product
    getSPAvailableForAssociation(isCreation ? storeIds : [existingStorageArea.storeId]);
  }, [mappings]);

  const renderNoSupplierProductMappings = () => (
    <EmptyState
      label={i18next.t('ADMIN.STORAGE_AREAS.EMPTY_STATE_MAPPINGS_LABEL')}
      labelColor={ENUM_COLORS.IP_BLACK_1}
      labelFont={ENUM_FONTS.H2_INTER}
      renderActionButton={() =>
        canEditStorageArea(authorizedActions) && (
          <Button
            color={'inpulse-default'}
            handleClick={openAssociationModal}
            icon={'/images/inpulse/add-white-small.svg'}
            label={i18next.t('GENERAL.ADD')}
          />
        )
      }
      subtitle={i18next.t('ADMIN.STORAGE_AREAS.EMPTY_STATE_MAPPINGS_SUBTITLE')}
      subtitleMargin={'8px'}
    />
  );

  const openAssociationModal = () => {
    const params = getAssociateSupplierProductsModalConfig({
      hasLocalCatalogs,
      setSpToBeAdded,
      availableSPForAssociation,
      handleAddAssociation,
      title: i18next.t('ADMIN.STORAGE_AREAS.ADD_SUPPLIER_PRODUCTS_MODAL_TITLE'),
    });

    openGenericModal(params);
  };

  const handleRemoveAssociation = (selectedItems) => {
    const sPIdsToBeRemoved = selectedItems.map(({ supplierProductId }) => supplierProductId);
    const mappingsSpIds = mappings.map(({ supplierProductId }) => supplierProductId);

    // Get storage area updated mappings SP ids list
    const updatedMappingsSPIds = difference(mappingsSpIds, sPIdsToBeRemoved);

    const firstSPMapping = head(selectedItems);

    const warningModalConfig = getConfirmationModal({
      title: i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_LIST_STORES_ACTION_REMOVE_SELECTION'),
      content: i18next.t('ADMIN.STORAGE_AREAS.REMOVE_SUPPLIER_PRODUCT_MAPPINGS_WARNING', {
        count: selectedItems.length,
        name: firstSPMapping.name,
      }),
      icon: '/images/inpulse/warning-white-small.svg',
      actions: [
        GENERIC_MODAL_CANCEL_BUTTON(),
        {
          ...GENERIC_MODAL_CONFIRM_BUTTON(),
          handleClick: () => updateStorageAreaMappings(updatedMappingsSPIds, true),
        },
      ],
    });

    openGenericModal(warningModalConfig);
  };

  const updateStorageAreaMappings = async (updatedMappingsSPIds, isDeletion = false) => {
    pageLoading();

    const updatedSpMappings = Object.values(
      isDeletion
        ? // Get mappings minus the ones that have been removed
          mappings.reduce((acc, mapping) => {
            const exist = updatedMappingsSPIds.includes(mapping.supplierProductId);
            if (exist) {
              acc[mapping.supplierProductId] = mapping;
              return acc;
            }
            return acc;
          }, {})
        : // Get mappings plus the ones that have been added
          keyBy([...mappings, ...spToBeAdded], 'id'),
    );

    if (!isCreation) {
      const existingMappingsEntityIds = existingStorageArea.recipeMappings.map(
        ({ entityId }) => entityId,
      );

      try {
        await storageAreaService.updateStorageArea(
          clientId,
          existingStorageArea.id,
          existingStorageArea.name,
          updatedMappingsSPIds,
          existingMappingsEntityIds,
        );

        setMappings(updatedSpMappings);
        setFilteredMappings(updatedSpMappings);

        refreshFilters(updatedSpMappings);

        showSuccessMessage(i18next.t('GENERAL.CHANGES_SUCCESSFULLY_SAVED'));
      } catch {
        showErrorMessage(i18next.t('GENERAL.SAVING_CHANGES_FAILED'));
      } finally {
        pageLoaded();
        return;
      }
    }

    pageLoaded();
    storageAreaForm.setValue('supplierProductMappings', updatedSpMappings);
  };

  return (
    <Container>
      {displayEmptyState ? (
        renderNoSupplierProductMappings()
      ) : (
        <ListView
          actions={actions}
          columns={columns}
          data={filteredMappings}
          defaultMaxPerPage={500}
          defaultOrderBy={'name'}
          defaultOrderType={'asc'}
          isLoading={isLoading}
          markerConfiguration={{
            isHidden: ({ isKitchen }) => !isKitchen,
            backgroundColor: theme.colors.brand.secondary,
            icon: { src: '/images/inpulse/central-black-small.svg' },
          }}
          maxPerPageOptions={[10, 20, 50, 100, 500, 1000]}
          minActionsInActionsDropdown={1}
          padding={LISTVIEW_NO_TOP_PADDING}
          placeholderShape={i18next.t('GENERAL.SEARCH')}
          renderEmptyState={() => <EmptyState />}
          renderFilterButton={() => (
            <DeepsightFiltersButton
              applyFilters={applyFilters}
              customMultipleDropDowns={formatUtils.computeMultipleDropdownsFilter({
                categoriesToChoose,
                selectedCategories,
                setSelectedCategories,
                subCategoriesToChoose,
                selectedSubCategories,
                setSelectedSubCategories,
              })}
              filters={filters}
              readOnly={isLoading}
              selectedSuppliers={selectedSuppliers}
              setApplyFilters={setApplyFilters}
              setFilters={setFilters}
              setSelectedSuppliers={setSelectedSuppliers}
              suppliers={suppliers}
              textFilterButton={i18next.t('GENERAL.LIST_VIEW_FILTER_BUTTON')}
            />
          )}
          rowActions={rowActions}
          setSelectedItems={setSelectedItems}
          hideAllPerPageOption
        />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  authorizedActions: getAuthorizedActions(
    state.baseReducer.userRights,
    '/admin/inventories/storage-areas/:id/details',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  openGenericModal: (params) => {
    dispatch(openGenericModal(params));
  },
  refreshGenericModal: (params) => {
    dispatch(refreshGenericModal(params));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageAreaMappings);
