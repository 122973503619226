import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import { Container, Title, Subtitle, Action, Icon, HeaderContainer } from './styledComponents';

export const GeneralEmptyStateListView = (props) => {
  const { title, subtitle, renderAction, icon, iconStyle, headerActions } = props;

  return (
    <>
      <Container>
        {headerActions && headerActions.length && (
          <HeaderContainer>
            <Button
              actions={headerActions}
              color={'inpulse-outline'}
              iconDropdown={'/images/inpulse/carret-black.svg'}
              iconOnLeft={false}
              label={i18next.t('ADMIN.RECIPES.ITEM_LIST_BUTTONS_ACTIONS')}
            />
          </HeaderContainer>
        )}
        <Icon src={icon} style={iconStyle} />
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {renderAction && <Action>{renderAction()}</Action>}
      </Container>
    </>
  );
};

export default GeneralEmptyStateListView;
