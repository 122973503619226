import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import {
  canCloneStorageAreaParams,
  canCreateStorageArea,
  canDeleteStorageArea,
  canEditStorageArea,
} from '@selectors/actions/storageAreaActions';

const getGlobalActions = ({
  authorizedActions,
  handleCopyParamModal,
  handleAddReferences,
  handleCreation,
  handleDeletion,
  handleExport,
  selectedStorageAreas,
  storageAreas,
}) => {
  const selectedItemsCount = selectedStorageAreas.length;

  const canCreate = canCreateStorageArea(authorizedActions);

  return [
    {
      id: 'create',
      actionLabel: i18next.t('GENERAL.CREATE'),
      fixed: canCreate,
      handleAction: handleCreation,
      isHidden: () => !canCreate,
      render: () => (
        <Button
          handleClick={handleCreation}
          icon={'/images/inpulse/add-white-small.svg'}
          label={i18next.t('GENERAL.CREATE')}
        />
      ),
    },
    {
      id: 'action-copy-param',
      actionLabel: i18next.t('ADMIN.STORAGE_AREAS.COPY_STORAGE_AREA_PARAMS_ACTION'),
      actionIcon: () => '/images/inpulse/gear-ipblack-small.svg',
      handleAction: handleCopyParamModal,
      isHidden: () => !selectedItemsCount || !canCloneStorageAreaParams(authorizedActions),
    },
    {
      id: 'action-add-references',
      actionLabel: i18next.t('ADMIN.STORAGE_AREAS.ADD_REFERENCES_STORAGE_AREA_PARAMS_ACTION'),
      actionIcon: () => '/images/inpulse/add-black-small.svg',
      handleAction: handleAddReferences,
      isHidden: () => !selectedItemsCount || !canEditStorageArea(authorizedActions),
    },
    {
      id: 'action-delete',
      actionLabel: i18next.t('ADMIN.STORAGE_AREAS.LABEL_DELETE_ACTION', {
        count: selectedItemsCount,
      }),
      actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
      handleAction: (items) => handleDeletion(items),
      isHidden: () => !selectedItemsCount || !canDeleteStorageArea(authorizedActions),
    },
    {
      id: 'export-list',
      actionLabel: i18next.t(
        selectedItemsCount ? 'GENERAL.EXPORT_SELECTION' : 'GENERAL.EXPORT_LIST',
      ),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () => handleExport(selectedItemsCount ? selectedStorageAreas : storageAreas),
    },
  ];
};

const getRowActions = (handleDeletion, authorizedActions) => [
  {
    id: 'action-delete',
    actionLabel: () =>
      i18next.t('ADMIN.STORAGE_AREAS.LABEL_DELETE_ACTION', {
        count: 1,
      }),
    actionIcon: () => '/images/inpulse/delete-ip-black-small.svg',
    handleAction: (item) => handleDeletion([item]),
    isHidden: () => !canDeleteStorageArea(authorizedActions),
  },
];

const getStorageAreaMappingsGlobalActions = ({
  isLoading,
  selectedItems,
  openAssociationModal,
  handleRemoveAssociation,
  isRecipeAssociation = false,
  authorizedActions,
}) => {
  const hasSelectedItems = !!selectedItems.length;

  const canEditSAMappings = canEditStorageArea(authorizedActions);

  const actionsList = [
    {
      fixed: canEditSAMappings,
      id: 'action-add',
      actionLabel: i18next.t('GENERAL.ADD'),
      isHidden: () => !canEditSAMappings,
      render: () => (
        <Button
          color={'inpulse-default'}
          handleClick={openAssociationModal}
          icon="/images/inpulse/add-white-small.svg"
          isDisabled={isLoading}
          label={i18next.t('GENERAL.ADD')}
        />
      ),
      handleAction: () => {},
    },
  ];

  if (hasSelectedItems && canEditSAMappings) {
    actionsList.push({
      id: 'action-delete',
      actionLabel: i18next.t(
        isRecipeAssociation
          ? 'ADMIN.STORAGE_AREAS.LABEL_REMOVE_RECIPE_ASSOCIATION'
          : 'ADMIN.STORAGE_AREAS.LABEL_REMOVE_ASSOCIATION',
        {
          count: selectedItems.length,
        },
      ),
      actionIcon: () => '/images/inpulse/remove-black-small.svg',
      handleAction: (items) => handleRemoveAssociation(items),
    });
  }

  return actionsList;
};

const getStorageAreaMappingsRowActions = ({
  handleRemoveAssociation,
  isRecipeAssociation = false,
  authorizedActions,
}) => [
  {
    id: 'delete',
    actionLabel: () =>
      i18next.t(
        isRecipeAssociation
          ? 'ADMIN.STORAGE_AREAS.LABEL_REMOVE_RECIPE_ASSOCIATION'
          : 'ADMIN.STORAGE_AREAS.LABEL_REMOVE_ASSOCIATION',
        {
          count: 1,
        },
      ),
    actionIcon: () => '/images/inpulse/remove-black-small.svg',
    isHidden: () => !canEditStorageArea(authorizedActions),
    handleAction: (item) => handleRemoveAssociation([item]),
  },
];

export default {
  getGlobalActions,
  getRowActions,
  getStorageAreaMappingsGlobalActions,
  getStorageAreaMappingsRowActions,
};
