import { connect } from 'react-redux';
import { get, keyBy } from 'lodash';
import { Paper, TableContainer } from '@mui/material';
import i18next from 'i18next';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Commons
import { Button, Dropdown } from '@commons/utils/styledLibraryComponents';
import { DeepsightComponentLoader, InpulseLabel } from '@commons/DeepsightComponents';
import { getClientStoreNameTranslation } from '@commons/utils/translations';
import { getTheme } from '@commons/utils/theme';
import { removeConcatenatedDateSuffixFromFileName } from '@commons/utils/fileUtils';
import EmptyState from '@commons/EmptyState';
import Text, { ENUM_COLORS, ENUM_FONTS } from '@commons/Text';

// Selectors
import { getClientInfo } from '@selectors/client';

// Design
import {
  CellContent,
  Container,
  DropdownContainer,
  FileSizeContainer,
  getStoreTableCellSX,
  LoaderContainer,
  StatusContainer,
  StoreNameContainer,
  tableCellStoreNameCustomStyle,
  TextContainer,
} from './styledComponents';

import { FILES_STATUS_CODES, GET_FILES_STATUS } from '../../utils/fileStatus';

/**
 * Render the store dropdown cell
 * @param {Object[]} userStores - All possible stores mapped to the user
 * @param {Object} store - Currently selected store
 * @param {InvoiceFile} file
 * @param {Function} handleStoreSelection - Callback after selecting a store
 * @return {Element}
 */
const renderStoresCells = (userStores, store, file, handleStoreSelection) => {
  const fileStatusCode = file.fileStatus;

  if (fileStatusCode === FILES_STATUS_CODES.DETECTING_STORE) {
    return (
      <LoaderContainer>
        <DeepsightComponentLoader height={16} width={16} />
      </LoaderContainer>
    );
  }

  const isStoreDropdownDisabled = [
    FILES_STATUS_CODES.SUCCESS,
    FILES_STATUS_CODES.WAITING,
    FILES_STATUS_CODES.ERROR,
  ].includes(fileStatusCode);

  return (
    <CellContent>
      <DropdownContainer isDisabled={isStoreDropdownDisabled}>
        <Dropdown
          height={'100%'}
          isDisabled={isStoreDropdownDisabled}
          items={userStores}
          // Returning ' ' with a space because if the placeholder is an empty string it will fallback on another string in the cmp
          placeholder={isStoreDropdownDisabled ? ' ' : i18next.t('GENERAL.DROPDOWN_PLACEHOLDER')}
          searchPlaceholder={i18next.t('GENERAL.SEARCH')}
          selectedItem={store}
          width={'100%'}
          isRequired
          onSelectionChange={({ id }) => handleStoreSelection(file.id, id)}
        />
      </DropdownContainer>
    </CellContent>
  );
};

export const InvoiceControlFilesList = (props) => {
  const {
    selectedFiles,
    removeFile,
    isImporting,
    client: { storeName },
    userStores,
    handleStoreSelection,
  } = props;

  const theme = getTheme();

  const clientStoreName = getClientStoreNameTranslation(storeName, false);

  const userStoresKeyById = keyBy(userStores, 'id');

  return (
    <Container>
      {!selectedFiles.length ? (
        <EmptyState
          backgroundColor={theme.colors.white}
          customStyle={{ borderRadius: 'inherit' }}
          label={i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.INVOICE_CONTROL_LIST_EMPTY_STATE')}
        />
      ) : (
        <TableContainer
          component={Paper}
          style={{ maxHeight: '100%', height: '100%', borderRadius: 'inherit' }}
        >
          <Table aria-label="sticky table" stickyHeader>
            <TableHead sx={{ height: 40 }}>
              <TableRow>
                <TableCell
                  sx={{
                    padding: '0 16px 0 16px',
                  }}
                >
                  <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.TEXT_SMALL}>
                    {i18next.t('FEATURE.INVOICE.INVOICES')} ({selectedFiles.length})
                  </Text>
                </TableCell>
                <TableCell
                  sx={{
                    borderRight: `1px solid ${theme.colors.greys.lighty}`,
                    borderLeft: `1px solid ${theme.colors.greys.lighty}`,
                    padding: '0 16px 0 16px',
                  }}
                >
                  <StoreNameContainer>
                    <Text color={ENUM_COLORS.INFO_RED} font={ENUM_FONTS.TEXT_SMALL}>
                      *
                    </Text>
                    <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.TEXT_SMALL}>
                      {clientStoreName}
                    </Text>
                  </StoreNameContainer>
                </TableCell>
                <TableCell
                  sx={{
                    padding: '0 16px 0 16px',
                  }}
                >
                  <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.TEXT_SMALL}>
                    {i18next.t('GENERAL.STATUS')}
                  </Text>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedFiles.map((file) => {
                const fileStatusCode = file.fileStatus;
                const currentFileStatus = GET_FILES_STATUS(clientStoreName)[fileStatusCode];

                const canDelete =
                  !isImporting &&
                  [
                    FILES_STATUS_CODES.FAILED_DETECTION,
                    FILES_STATUS_CODES.INSUFFICIENT_CREDITS,
                    FILES_STATUS_CODES.PENDING,
                    FILES_STATUS_CODES.ERROR,
                  ].includes(fileStatusCode);

                const storeId = get(file, 'invoiceControlList.storeId');
                const invoiceControlListId = get(file, 'invoiceControlList.id', null);

                const store = userStoresKeyById[storeId] || {};

                return (
                  <TableRow key={file.id} sx={{ height: 64 }}>
                    <TableCell component="th" scope="row" sx={{ width: 228 }}>
                      <TextContainer>
                        <Text font={ENUM_FONTS.TEXT_SMALL} style={tableCellStoreNameCustomStyle}>
                          {removeConcatenatedDateSuffixFromFileName(file.fileName)}
                        </Text>
                        <FileSizeContainer>
                          <Text color={ENUM_COLORS.DGREY} font={ENUM_FONTS.TEXT_SMALL}>
                            ({file.fileSize})
                          </Text>
                        </FileSizeContainer>
                      </TextContainer>
                    </TableCell>

                    <TableCell
                      component="tr"
                      scope="row"
                      sx={getStoreTableCellSX(
                        theme,
                        fileStatusCode === FILES_STATUS_CODES.FAILED_DETECTION,
                      )}
                    >
                      <CellContent>
                        {renderStoresCells(userStores, store, file, handleStoreSelection)}
                      </CellContent>
                    </TableCell>

                    <TableCell sx={{ width: 228 }}>
                      <StatusContainer>
                        <InpulseLabel
                          color={currentFileStatus.color}
                          text={currentFileStatus.name}
                        />
                        {canDelete && (
                          <Button
                            color={'inpulse-outline'}
                            customStyle={{ height: '24px' }}
                            handleClick={() => removeFile(file.id, invoiceControlListId)}
                            icon={'/images/inpulse/close-black-small.svg'}
                            noBorder
                          />
                        )}
                      </StatusContainer>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
  userStores: state.baseReducer.activeStores,
});

export default connect(mapStateToProps)(InvoiceControlFilesList);
