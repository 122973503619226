import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  overflow: scroll;

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  position: absolute;

  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  top: 24px;
  right: 24px;
`;

export const Title = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.h1Inter};

  margin-bottom: 16px;
`;

export const Subtitle = styled.div`
  color: ${(props) => props.theme.colors.greys.darkest};
  font: ${(props) => props.theme.fonts.textBigHeight16};
`;

export const Action = styled.div`
  margin-top: 24px;
`;

export const Icon = styled.img`
  width: 200px;
  height: 200px;
`;
