import './fonts.css';

import { LicenseInfo } from '@mui/x-license-pro';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';

import sentryService from './providers/sentry';

import * as serviceWorker from './serviceWorker';
import { logoutSuccess } from './actions/logout';
import axiosInstance from './core/http';
import configureStore from './store/configureStore';
import mixpanelService from './providers/mixpanel';

import { removeLocalStorageItem } from './commons/utils/localStorage';
import App from './routes/App';
import MuiTheme from './mui-theme';
import theme from './theme';

const preloadedState = {
  baseReducer: {
    isUserOffline: false,
    isConnectionSlow: false,
    shouldReloadApp: false,
    user: {},
    currency: {},
    userRights: [],
    events: [],
    featuresKeyByPath: {},
    weather: [],
    activeStores: [],
    stores: [],
    sales: [],
    forecasts: [],
    products: [],
    suppliers: [],
    supplierProducts: [],
    weatherStation: [],
    weeklyForecasts: [],
    weeklySales: [],
    rangeForecasts: [],
    rangeSales: [],
    pastForecasts: [],
    pastSales: [],
    pastEvents: [],
    pastWeather: [],
    eventSubCategories: [],
    diffChange: [],
    lastYearSales: [],
    suppliersCount: 0,
    supplierProductsCount: 0,
    storeProductMappings: [],
    storeSupplierProductMappings: [],
    storeSupplierProductMapping: {},
    lyEvents: [],
    lyWeather: [],
    store: {},
    brands: [],
    productionMetricsByDates: {},
    isSynchronizingCashierProducts: false,
    isSynchronizingCashierProductsSales: false,
    isCashierProductSalesIngestionPipelineRunning: false,
    isCentralView: false,
  },
  loadingReducer: {},
  modalReducer: {},
  confirmationMessageReducer: {
    message: '',
    messageBool: false,
    messageType: 'success',
  },
  orderConfirmationMessageReducer: {},
  switchButtonReducer: {
    supplierProductId: '',
  },
  form: {},
  stockFormReducer: {
    totByStorageAreaIdSPIdAndPackagingId: {},
    totByStorageAreaIdAndRecipeId: {},
  },
};

const store = configureStore(preloadedState);

axiosInstance.interceptors.request.use(
  (config) => {
    config.metadata = { startTime: new Date() };

    return config;
  },
  (error) => {
    error.config.metadata.endTime = new Date();
    error.duration = error.config.metadata.endTime - error.config.metadata.startTime;

    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    response.config.metadata.endTime = new Date();
    response.duration = response.config.metadata.endTime - response.config.metadata.startTime;

    return response;
  },
  (error) => {
    /**
     * Handle auto logout when token has expired
     *
     * NOTE: NOT PRETTY BUT WE ARE STILL TRYNG TO UNDERSTAND WHY RESPONSE
     * IS DIFFERENT FROM DEV TO PRODUCTION MODE
     */
    if (
      (_.get(error, 'response.data.error.status') === 401 ||
        _.get(error, 'response.data.error.statusCode') === 401 ||
        _.get(error, 'response.data.status') === 401 ||
        _.get(error, 'response.data.statusCode') === 401) &&
      (['Authorization Required', 'Unauthorized'].includes(
        _.get(error, 'response.data.error.message'),
      ) ||
        ['Authorization Required', 'Unauthorized'].includes(_.get(error, 'response.data.message')))
    ) {
      store.dispatch(logoutSuccess());

      // Make sure user is logged out
      removeLocalStorageItem('loopbackAccessToken');
      removeLocalStorageItem('userId');
      removeLocalStorageItem('isCentralMode');
    }

    return Promise.reject(error);
  },
);

// Init sentry instance
sentryService.init();

/**
 * As recommended by the MUI docs: https://mui.com/x/introduction/licensing/#how-to-install-the-key
 * The issue of leaving the license key hardcoded is a known subject, the MUI team has been
 * reflecting on it since 2021: https://github.com/mui/mui-x/issues/2565
 */
LicenseInfo.setLicenseKey(
  '95f97d661ff9c866a07f84ec43d305c9Tz04Njc1MSxFPTE3NDI0NTk1NzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={MuiTheme}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

/**
 * Connecting app to mix panel project
 */
if (process.env.REACT_APP_MIX_PANEL_PROJECT_ID) {
  const mixPanel = mixpanelService.getInstance();

  mixPanel.init(process.env.REACT_APP_MIX_PANEL_PROJECT_ID);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
// serviceWorker.register({
//   onUpdate: registration => {
//     const waitingServiceWorker = registration.waiting

//     if (waitingServiceWorker) {
//       waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
//     }
//   }
// });
