import { cloneDeep, get } from 'lodash';
import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useState } from 'react';

import { REGEX_IMAGE_FILE_EXTENSION } from '@commons/utils/regex';

import { Dropdown, DropdownRowMultipleLines } from '../utils/styledLibraryComponents';
import { getTheme } from '../utils/theme';
import { spUnitByIngredientUnit } from '../utils/units';
import { translatedUnitCondensed } from '../utils/translateUnit';

import { PAGINATED_DROPDOWN_TYPES_OF_DATA_TO_FETCH } from '../constants/dropdown';

import Text, { CUSTOM_STYLE_FOR_DROPDOWN_TEXT, ENUM_FONTS } from '../Text';

import { showErrorMessage } from '../../actions/messageconfirmation';

import { getClientInfo } from '../../selectors/client';

import {
  DropdownIcon,
  BackgroundImage,
  CentralKitchenIcon,
  DropdownItemContainer,
  ForegroundImage,
  ImageContainer,
  SubtitleContainer,
} from './styledComponents';
import { supplierProduct as supplierProductService } from '../../services/supplierProduct';

const FETCH_LIMIT = 50;

const DEFAULT_SP_PICTURE = '/images/inpulse/supplier-product-photo-placeholder.svg';
const MAPPED_SP_PICTURE = '/images/inpulse/link-white-supplier-product.svg';

const theme = getTheme();

const PaginatedSupplierProductsDropdown = (props) => {
  const {
    client: { clientId },
    ingredientUnit,
    showErrorMessage,
    supplierIds,
    selectedSupplierProduct,
    onSelectionChange,
    priceStartDate = null,
    spIdsToExclude = [],
    isRequired,
    customStyle,
    width,
    label = null,
    isDisabled,
    shouldDisplayCentralKitchenInfos = false,
    displayCustomBackground = false,
    displaySPConversions = false,
    filterByUserCatalog = true,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [skip, setSkip] = useState(0);
  const [displayShowMoreResult, setDisplayShowMoreResult] = useState(true);
  const [fetchedSupplierProducts, setFetchedSupplierProducts] = useState([]);

  const [previousDataToFetch, setPreviousDataToFetch] = useState('');

  const fetchSupplierProducts = async (dataToFetch) => {
    if (!supplierIds.length || dataToFetch == null) {
      return;
    }

    if (
      !fetchedSupplierProducts.length ||
      (previousDataToFetch !== dataToFetch &&
        dataToFetch !== PAGINATED_DROPDOWN_TYPES_OF_DATA_TO_FETCH.LOAD_MORE_DATA)
    ) {
      setIsLoading(true);
    }

    try {
      let currentAvailableSupplierProducts = cloneDeep(fetchedSupplierProducts);

      const doesSearchIncludesTypesOfDataToFetch = [
        PAGINATED_DROPDOWN_TYPES_OF_DATA_TO_FETCH.LOAD_MORE_DATA,
        PAGINATED_DROPDOWN_TYPES_OF_DATA_TO_FETCH.RESET_FETCH,
      ].includes(dataToFetch);

      let updatedSkip = 0;

      if (
        dataToFetch !== PAGINATED_DROPDOWN_TYPES_OF_DATA_TO_FETCH.RESET_FETCH && // If reset fetch we want a skip at 0
        (previousDataToFetch === dataToFetch || // If we have the same data (search of load more data we want to fetch more data)
          (previousDataToFetch === PAGINATED_DROPDOWN_TYPES_OF_DATA_TO_FETCH.RESET_FETCH && // If previous data === reset and current date is load more it means we stopped a search and want to load more data
            dataToFetch === PAGINATED_DROPDOWN_TYPES_OF_DATA_TO_FETCH.LOAD_MORE_DATA))
      ) {
        updatedSkip = skip + FETCH_LIMIT;
      }

      const { supplierProducts, totalCount } = await supplierProductService.getWithPagination({
        clientId,
        supplierIds: supplierIds,
        skip: updatedSkip,
        limit: FETCH_LIMIT,
        orderBy: 'name',
        orderType: 'asc',
        queryParams: '&active=true',
        withPackagings: true,
        spIdsToExclude,
        search: doesSearchIncludesTypesOfDataToFetch ? null : dataToFetch,
        priceStartDate,
        filterByUserCatalog,
      });

      if (dataToFetch !== PAGINATED_DROPDOWN_TYPES_OF_DATA_TO_FETCH.RESET_FETCH) {
        if (!doesSearchIncludesTypesOfDataToFetch && previousDataToFetch !== dataToFetch) {
          currentAvailableSupplierProducts = supplierProducts;
          setSkip(0);
        } else {
          currentAvailableSupplierProducts.push(...supplierProducts);
          setSkip(skip + FETCH_LIMIT);
        }
      }

      if (dataToFetch === PAGINATED_DROPDOWN_TYPES_OF_DATA_TO_FETCH.RESET_FETCH) {
        currentAvailableSupplierProducts = supplierProducts;
      }

      setDisplayShowMoreResult(currentAvailableSupplierProducts.length < totalCount);

      const newlyFetchedSupplierProducts = currentAvailableSupplierProducts.map(
        (supplierProduct) => {
          supplierProduct.supplierName = get(supplierProduct, 'supplier.name');

          if (displaySPConversions) {
            supplierProduct.tags = supplierProduct.conversions.reduce(
              (acc, { convertedUnit }) => {
                const formattedUnit = translatedUnitCondensed(convertedUnit);

                if (!acc.includes(formattedUnit)) {
                  acc.push(formattedUnit);
                }

                return acc;
              },
              [translatedUnitCondensed(supplierProduct.packagingUnit)],
            );
          }

          const ingredientUnitInSpUnit = spUnitByIngredientUnit.get(ingredientUnit);

          const spAndIngredientHaveDifferentUnit =
            supplierProduct.packagingUnit !== ingredientUnitInSpUnit;

          const spHaveConversions = supplierProduct.conversions.find(
            ({ convertedUnit }) => convertedUnit === ingredientUnitInSpUnit,
          );

          // If ingredientUnit is provided, check for units & conversions
          // Otherwise, set isDisabled to false
          const isDisabled =
            ingredientUnit && spAndIngredientHaveDifferentUnit && !spHaveConversions;

          supplierProduct.isDisabled = isDisabled;

          const compressedSPimg =
            !!supplierProduct.img &&
            supplierProduct.img.replace(REGEX_IMAGE_FILE_EXTENSION, '_compressed.$1');

          supplierProduct.renderValue = () => (
            <DropdownItemContainer>
              {displayCustomBackground ? (
                <ImageContainer>
                  <BackgroundImage
                    hasForegroundImage={!!supplierProduct.entityId}
                    src={compressedSPimg || DEFAULT_SP_PICTURE}
                    onError={({ currentTarget }) => {
                      // if the image is not found
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = DEFAULT_SP_PICTURE;
                    }}
                  />
                  {supplierProduct.entityId && <ForegroundImage src={MAPPED_SP_PICTURE} />}
                </ImageContainer>
              ) : (
                <DropdownIcon
                  src={compressedSPimg || DEFAULT_SP_PICTURE}
                  onError={({ currentTarget }) => {
                    // if the image is not found
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = DEFAULT_SP_PICTURE;
                  }}
                />
              )}

              <DropdownRowMultipleLines>
                <Text
                  color={isDisabled && theme.colors.greys.light}
                  font={ENUM_FONTS.TEXT_BIG_HEIGHT_16}
                  style={{ ...CUSTOM_STYLE_FOR_DROPDOWN_TEXT, width: '208px' }}
                >
                  {supplierProduct.name}
                </Text>
                <SubtitleContainer>
                  {supplierProduct.isKitchen && shouldDisplayCentralKitchenInfos && (
                    <CentralKitchenIcon>
                      <img src="/images/inpulse/central-black-extra-small.svg" />
                    </CentralKitchenIcon>
                  )}
                  <Text
                    color={isDisabled ? theme.colors.greys.light : theme.colors.greys.dark}
                    font={ENUM_FONTS.TEXT_SMALL_HEIGHT_16}
                    style={{ ...CUSTOM_STYLE_FOR_DROPDOWN_TEXT, width: '208px' }}
                  >
                    {supplierProduct.supplierName}
                  </Text>
                </SubtitleContainer>
              </DropdownRowMultipleLines>
            </DropdownItemContainer>
          );

          return supplierProduct;
        },
      );

      setFetchedSupplierProducts(newlyFetchedSupplierProducts);
      setPreviousDataToFetch(dataToFetch);
    } catch {
      showErrorMessage(i18next.t('ADMIN.SUPPLIER_PRODUCTS.FETCH_ERROR'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dropdown
      customStyle={customStyle}
      isDisabled={isDisabled}
      isRequired={isRequired}
      items={fetchedSupplierProducts}
      label={label}
      pagination={{
        isLoading,
        displayShowMoreResult,
        handleFetchMoreData: (search) => search && fetchSupplierProducts(search),
      }}
      placeholder={i18next.t('GENERAL.ADD_SUPPLIER_PRODUCT')}
      searchDebounceTimeInMs={500}
      selectedItem={selectedSupplierProduct}
      width={width}
      onSelectionChange={onSelectionChange}
      onTriggerDropdownList={(value) =>
        value && fetchSupplierProducts(PAGINATED_DROPDOWN_TYPES_OF_DATA_TO_FETCH.RESET_FETCH)
      }
    />
  );
};

const mapStateToProps = (state) => ({
  client: getClientInfo(state.baseReducer.user),
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaginatedSupplierProductsDropdown);
