import { sortBy } from 'lodash';
import i18next from 'i18next';

import utilsXLS from '@commons/utils/makeXLS';

const createMainSheet = (storageAreas, clientStoreName) => {
  const columns = [
    {
      propertyKey: 'name',
      name: i18next.t('GENERAL.NAME'),
    },
    {
      propertyKey: 'storeName',
      name: clientStoreName,
    },
    {
      propertyKey: 'associatedSPsCount',
      name: i18next.t('ADMIN.STORAGE_AREAS.ASSOCIATED_SUPPLIER_PRODUCTS_COUNT_COLUMN'),
    },
    {
      id: 'associatedEntitiesCount',
      name: i18next.t('ADMIN.STORAGE_AREAS.ASSOCIATED_ENTITIES_COUNT_COLUMN'),
      propertyKey: 'associatedSalesPointRecipeCount',
    },
  ];

  const sortedStorageAreas = sortBy(storageAreas, ({ name }) => name.toLowerCase());

  return utilsXLS.generateDefaultSheet(
    i18next.t('FEATURE.ADMIN.STORAGE_AREAS'),
    columns,
    sortedStorageAreas,
  );
};

const exportStorageAreas = (storageAreas, clientStoreName) => {
  const filename = i18next.t('ADMIN.STORAGE_AREAS.EXPORT_LIST_FILENAME');

  const mainSheet = createMainSheet(storageAreas, clientStoreName);

  utilsXLS.makeXLS(filename, [mainSheet]);
};

export default { exportStorageAreas };
