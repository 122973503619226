import { connect } from 'react-redux';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';

import { showErrorMessage } from '@actions/messageconfirmation';

import { Dropdown, ListView } from '@commons/utils/styledLibraryComponents';
import { getTheme } from '@commons/utils/theme';
import { LISTVIEW_NO_TOP_PADDING } from '@commons/constants/listViewProps';
import { Tags } from '@commons/Tags';
import EmptyState from '@commons/EmptyState';
import Text from '@commons/Text';

import useLocalStorage from '@hooks/useLocalStorage';

import centralService from '@services/central';
import recipeService from '@services/recipe';

import { getPropertyNoneValue } from '@commons/constants/categoryTypes';

import { Container, HeaderContainer } from './styledComponents';

const getColumns = (hasMultipleBrands) => {
  const columns = [{ id: 'name', name: i18next.t('GENERAL.NAME'), propertyKey: 'name' }];

  if (hasMultipleBrands) {
    columns.push({
      id: 'brands',
      name: i18next.t('GENERAL.BRAND_PLURAL'),
      propertyKey: 'brands',
      render: (brands) => <Tags items={brands.map(({ name }) => name)} />,
    });
  }

  columns.push({
    id: 'category',
    name: i18next.t('GENERAL.CATEGORY'),
    propertyKey: 'category',
    render: (category) => <Text>{category || i18next.t('GENERAL.NONE_VALUE')}</Text>,
  });

  return columns;
};

const RecipesMappingsModal = (props) => {
  const {
    clientId,
    hasMultipleBrands,
    showErrorMessage,
    setRecipesToBeAdded,
    storeIds = [],
    alreadyMappedRecipes = [],
  } = props;

  const theme = getTheme();

  const [isInCentralMode] = useLocalStorage('isCentralMode', false);

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [listViewColumns] = useState(getColumns(hasMultipleBrands));
  const [isLoadingRecipes, setIsLoadingRecipes] = useState(true);
  const [recipes, setRecipes] = useState([]);
  const [filteredRecipes, setFilteredRecipes] = useState([]);

  useEffect(() => {
    reloadRecipes();
  }, []);

  useEffect(() => {
    const selectedCategoryIdsSet = new Set(selectedCategories.map(({ id }) => id));

    const filteredRecipes = recipes.filter(({ categoryId }) =>
      selectedCategoryIdsSet.has(categoryId),
    );

    setFilteredRecipes(filteredRecipes);
  }, [selectedCategories]);

  /** FUNCTIONS */
  const reloadRecipes = async () => {
    setIsLoadingRecipes(true);

    try {
      const entityIdsToExclude = alreadyMappedRecipes.map(({ entityId, id }) => entityId || id);

      let recipes = [];

      /*
        We need this condition because this component is used by the inventoryListTemplates and storageAreas
        but they both have different ways to get the recipes. No storeIds means that we are in the inventoryListTemplates and
        if we have storeIds we are in the storageAreas.
      */
      if (!storeIds.length) {
        recipes = isInCentralMode
          ? await centralService.getKitchenRecipesOfClient(clientId)
          : await recipeService.getRecipesOfClient(clientId);
      } else {
        recipes = await recipeService.getRecipesByStoresTypes(storeIds);
      }

      const activeRecipes = recipes.filter(({ active }) => active);

      const { formattedRecipes, categoriesKeyByIds } = activeRecipes.reduce(
        (acc, { id, name, category, categoryId, isKitchen, brands }) => {
          const formattedCategory = category || getPropertyNoneValue();

          if (!acc.categoriesKeyByIds[categoryId]) {
            acc.categoriesKeyByIds[categoryId] = {
              id: categoryId,
              name: formattedCategory,
            };
          }

          if (!entityIdsToExclude.includes(id)) {
            acc.formattedRecipes.push({
              id,
              name,
              categoryId,
              category: formattedCategory,
              isKitchen,
              brands,
              isRowSelected: false,
            });
          }

          return acc;
        },
        { formattedRecipes: [], categoriesKeyByIds: {} },
      );

      setRecipes(formattedRecipes);
      setFilteredRecipes(formattedRecipes);

      const formattedCategories = Object.values(categoriesKeyByIds);
      setCategories(formattedCategories);
      setSelectedCategories(formattedCategories);
    } catch {
      showErrorMessage(i18next.t('ADMIN.RECIPES.COMPOSITION_FETCH_ERROR_PLURAL'));
    } finally {
      setIsLoadingRecipes(false);
    }
  };

  const handleSelection = (selectedItems) => {
    const formattedRecipes = selectedItems.map(({ id, name, category, isKitchen, brands }) => ({
      name,
      category,
      entityId: id,
      brands,
      isKitchen,
    }));

    setRecipesToBeAdded(formattedRecipes);
  };

  return (
    <Container>
      <ListView
        columns={listViewColumns}
        data={filteredRecipes}
        defaultMaxPerPage={500}
        isLoading={isLoadingRecipes}
        markerConfiguration={{
          isHidden: ({ isKitchen }) => !isKitchen,
          backgroundColor: theme.colors.brand.secondary,
          icon: { src: '/images/inpulse/central-black-small.svg' },
        }}
        maxPerPageOptions={[10, 20, 50, 100, 500, 1000]}
        padding={LISTVIEW_NO_TOP_PADDING}
        renderEmptyState={() => <EmptyState />}
        renderFilterButton={() => (
          <HeaderContainer>
            <Dropdown
              iconSrc={'/images/inpulse/category-ipblack-small.svg'}
              isDisabled={isLoadingRecipes}
              isUniqueSelection={false}
              items={categories}
              selectedItems={selectedCategories}
              isRequired
              onSelectionChange={setSelectedCategories}
            />
          </HeaderContainer>
        )}
        setSelectedItems={handleSelection}
        forceEnableSelection
      />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  user: state.baseReducer.user,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipesMappingsModal);
