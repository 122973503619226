import Proptypes from 'prop-types';
import React from 'react';

import styled from 'styled-components';

class Label extends React.PureComponent {
  // constructor(props) {
  //     super(props);
  // }

  getClassNameFromProps = () => {
    const { className, labelStyle } = this.props;
    let customClassName = 'deepsight-label';
    if (labelStyle.toString() !== '') customClassName = customClassName + ' ' + labelStyle;
    if (className.toString() !== '') customClassName = customClassName + ' ' + className;
    return customClassName;
  };

  getStyleFromProps = () => {
    const { style, width, height } = this.props;
    return {
      ...style,
      width: width + 'px',
      height: height + 'px',
    };
  };

  getCenterDivWidth = () => {
    const { width, leftTextIcon, rightTextIcon } = this.props;
    let centerWidth = width;
    if (leftTextIcon.toString() !== '') centerWidth -= 28;
    if (rightTextIcon.toString() !== '') centerWidth -= 28;
    return centerWidth;
  };

  render() {
    const { value, onClick } = this.props;
    return (
      <div className={this.props.className}>
        <div
          className={this.getClassNameFromProps()}
          style={this.getStyleFromProps()}
          onClick={onClick}
        >
          {value}
        </div>
      </div>
    );
  }
}

Label.propTypes = {
  value: Proptypes.string.isRequired,
  labelStyle: Proptypes.string,
  className: Proptypes.string,
  style: Proptypes.objectOf(Proptypes.string),
  width: Proptypes.number,
  height: Proptypes.number,
};

Label.defaultProps = {
  labelStyle: '',
  className: '',
  style: {},
  height: 18,
};

const styledLabel = styled(Label)`
  & .deepsight-label {
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    font-family: proximanovabold, serif;
    border-radius: 3px;
    padding: 2px 5px 2px 5px;
    background: #ffffff;
    box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-right: 10px;
    padding-left: 10px;
  }

  & .deepsight-label.green-1 {
    color: #2cd696;
  }

  & .deepsight-label.background-green {
    background-color: #2cd696;
    color: #ffffff;
  }

  & .deepsight-label.background-grey {
    background-color: #6b768c;
    color: #ffffff;
  }

  & .deepsight-label.blue {
    color: #0a3eff;
  }

  & .deepsight-label.grey {
    color: #6b768c;
  }

  & .deepsight-label.orange {
    color: #ff7e00;
  }

  & .deepsight-label.default {
    color: #6b768c;
  }

  & .deepsight-label.lightred {
    color: #f0a4a4;
  }

  & .deepsight-label.red {
    color: #e72929;
  }

  & .deepsight-label.red-2 {
    background-color: ${(props) => props.theme.colors?.red || '#EF252A'};
    color: #ffffff;
    font: ${(props) => props.theme.fonts?.textSmallHeight14Weight600 || ''};
    text-transform: capitalize !important;
  }

  & .deepsight-label.unavaliable {
    background-color: #fafafa;
  }

  & .deepsight-label.black {
    color: #000000;
  }

  & .deepsight-label.grey-2 {
    color: #c0c0c0;
  }

  & .deepsight-label.white {
    color: #ffffff;
  }

  & .deepsight-label.inpulse {
    height: 24px !important;
    width: 48px !important;

    padding: 0 !important;

    display: flex;
    align-items: center;
    justify-content: center;

    color: ${(props) => props.theme.colors.greys.lightest};
    font: ${(props) => props.theme.fonts?.textSmall} !important;
    text-transform: uppercase;
    text-align: center;

    border-radius: 4px;
    background: ${(props) => props.theme.colors.greys.darkest};
  }
`;

export default styledLabel;
