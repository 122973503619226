import { keyBy } from 'lodash';
import i18next from 'i18next';
import React from 'react';

import { Button } from '@commons/utils/styledLibraryComponents';

import {
  canCreateSupplierProductMapping,
  canEditSupplierProductMapping,
  canDeleteSupplierProductMapping,
} from '@selectors/actions/storeActions';
import { canUpdateAssociatedSupplier } from '@selectors/user';

export const ACTIONS = {
  HANDLE_AVAILABILITIES: 'handle-availabilities',
  ADD_STORAGE_AREAS: 'add-storage-areas',
  REMOVE_STORAGE_AREAS: 'remove-storage-areas',
};

const _renderAssociationButton = ({ handleAssociate }) => (
  <Button
    handleClick={handleAssociate}
    icon={'/images/inpulse/add-white-small.svg'}
    label={i18next.t('GENERAL.ASSOCIATE')}
  />
);

export const getListActions = ({
  selectedSupplierProducts,
  filteredSupplierProducts,
  suppliers,
  user,
  client,
  handleAssociate,
  handleDeletion,
  handleExport,
  openSspmManagementModal,
  openStorageAreasManagementModal,
  authorizedActions,
}) => {
  const hasSelectedItems = selectedSupplierProducts.length;

  const suppliersKeyById = keyBy(suppliers, 'id');

  const userHasRightToUpdateAllSelectedItems = selectedSupplierProducts.every(({ supplierId }) =>
    canUpdateAssociatedSupplier(user, suppliersKeyById[supplierId], client),
  );

  const actions = [
    {
      id: 'associate',
      fixed: canCreateSupplierProductMapping(authorizedActions),
      actionLabel: i18next.t('GENERAL.ASSOCIATE'),
      isHidden: () => !canCreateSupplierProductMapping(authorizedActions),
      render: () => _renderAssociationButton({ handleAssociate }),
    },
    {
      id: 'handle-availabilities',
      actionLabel: i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_MANAGE_AVAILABILITY'),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
      isHidden: () => !hasSelectedItems || !canEditSupplierProductMapping(authorizedActions),
      handleAction: () => openSspmManagementModal(selectedSupplierProducts),
    },
    {
      id: 'add-storage-areas',
      actionLabel: i18next.t('ADMIN.STORES.SSPM_ACTION_ADD_TO_SA'),
      actionIcon: () => '/images/inpulse/add-black-small.svg',
      isHidden: () => !hasSelectedItems || !canEditSupplierProductMapping(authorizedActions),
      handleAction: () =>
        openStorageAreasManagementModal({
          isDissociation: false,
          concernedSSPMs: selectedSupplierProducts,
        }),
    },
    {
      id: 'remove-storage-areas',
      actionLabel: i18next.t('ADMIN.STORES.SSPM_ACTION_REMOVE_FROM_SA'),
      actionIcon: () => '/images/inpulse/remove-black-small.svg',
      isHidden: () => !hasSelectedItems || !canEditSupplierProductMapping(authorizedActions),
      handleAction: () =>
        openStorageAreasManagementModal({
          isDissociation: true,
          concernedSSPMs: selectedSupplierProducts,
        }),
    },

    {
      id: 'delete',
      actionLabel: i18next.t('GENERAL.DELETE_ASSOCIATION'),
      actionIcon: () => '/images/inpulse/delete-black-small.svg',
      isHidden: () =>
        !hasSelectedItems ||
        !userHasRightToUpdateAllSelectedItems ||
        !canDeleteSupplierProductMapping(authorizedActions),
      handleAction: () => handleDeletion(selectedSupplierProducts),
    },
    {
      id: 'export',
      actionLabel: i18next.t(hasSelectedItems ? 'GENERAL.EXPORT_SELECTION' : 'GENERAL.EXPORT_LIST'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: () =>
        handleExport(hasSelectedItems ? selectedSupplierProducts : filteredSupplierProducts),
    },
  ];

  return actions;
};

export const getRowActions = ({
  suppliers,
  user,
  client,
  handleDeletion,
  handleExport,
  openSspmManagementModal,
  openStorageAreasManagementModal,
  authorizedActions,
}) => {
  const suppliersKeyById = keyBy(suppliers, 'id');

  const actions = [
    {
      id: 'handle-availabilities',
      actionLabel: () => i18next.t('ADMIN.SUPPLIER_PRODUCTS.ACTION_MANAGE_AVAILABILITY'),
      actionIcon: () => '/images/inpulse/pen-black-small.svg',
      isHidden: () => !canEditSupplierProductMapping(authorizedActions),
      handleAction: (currentSSPM) => openSspmManagementModal([currentSSPM]),
    },
    {
      id: 'add-storage-areas',
      actionLabel: () => i18next.t('ADMIN.STORES.SSPM_ACTION_ADD_TO_SA'),
      actionIcon: () => '/images/inpulse/add-black-small.svg',
      isHidden: () => !canEditSupplierProductMapping(authorizedActions),
      handleAction: (currentSSPM) =>
        openStorageAreasManagementModal({ isDissociation: false, concernedSSPMs: [currentSSPM] }),
    },
    {
      id: 'remove-storage-areas',
      actionLabel: () => i18next.t('ADMIN.STORES.SSPM_ACTION_REMOVE_FROM_SA'),
      actionIcon: () => '/images/inpulse/remove-black-small.svg',
      isHidden: () => !canEditSupplierProductMapping(authorizedActions),
      handleAction: (currentSSPM) =>
        openStorageAreasManagementModal({ isDissociation: true, concernedSSPMs: [currentSSPM] }),
    },

    {
      id: 'delete',
      actionLabel: () => i18next.t('GENERAL.DELETE_ASSOCIATION'),
      actionIcon: () => '/images/inpulse/delete-black-small.svg',
      isHidden: (currentSSPM) =>
        !canUpdateAssociatedSupplier(user, suppliersKeyById[currentSSPM.supplierId], client) ||
        !canDeleteSupplierProductMapping(authorizedActions),
      handleAction: (currentSSPM) => handleDeletion([currentSSPM]),
    },
    {
      id: 'export',
      actionLabel: () => i18next.t('GENERAL.EXPORT_SELECTION'),
      actionIcon: () => '/images/inpulse/file-download-black-small.svg',
      handleAction: (currentSSPM) => handleExport([currentSSPM]),
    },
  ];

  return actions;
};
